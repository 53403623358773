.notification-heading {
    margin: 50px 0px 25px 50px;
    width: 289px;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    right: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;

    

}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3a2a7d;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: rgb(67, 39, 180);
    -webkit-transition: .4s;
    transition: .4s;
    border-color: white;
}

input:checked+.slider::after {
    background-color: white;
}


input:checked+.slider {
    background-color: #080101;
    /* background-color: white; */
}



input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


.slider.round {
    border-radius: 30px;
    /* border-color: white; */
}

.slider.round:before {
    border-radius: 50%;
    /* border-color: white; */
}


.slider.round::after {
    border-radius: 50%;
    border-color: white;
    background-color: white;
}

/* =====notification end===== */


.input-container {
    display: flex;
    flex-direction: column;
}

.input-box-container {
    display: flex;
    align-items: center;
    position: relative;
}

.input_box {
    /* flex-grow: 1; */
    margin-left: 20px;
    margin: 0px 0px 0px 50px;
    width: 543px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 51px;
    background: #272727;
    margin-top: 15px;
    color: #a6a4a4;
    border: none;
}

.switch {
    position: absolute;
    top: 35%;
    right:-35px;

}


/*  CSS */

@media (max-width:500px){
    .notification-heading {
        width: 1000%;
        font-size: 50px;
    }
    .input_box {
        width: 80%;
        font-size: 32px;
        height: 100px;
        padding-left: 30px;
        margin-bottom: 15px;
        line-height: normal;
        align-items: center;
        display: flex;
    }
    .switch {
        position: absolute;
        top: 27%;
        right: -130px;
        width: 118px;
        height: 60px;
    }
    .slider:before {
        height: 53px;
        width: 53px;
    }
    input:checked+.slider:before {
        transform: translateX(61px);
    }
}