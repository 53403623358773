 .grid_image_container {
   /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  gap: 6px;
  justify-items: center;
  align-items: start;
  padding: 6px; */

   column-count: 4;
   column-gap: 10px;
   padding: 0;
   gap: 6px;
   align-items: flex-start;
   border-radius: 15px;
 }

 .publicprivateimage {
   width: fit-content;
   height: 34px;
   flex-shrink: 0;
   border-radius: 47px;
   background: #714BDC;
   color: var(--icon-color, #FFF);
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   padding: 3px 15px;
   display: flex;
   align-items: center;
   cursor: pointer;
 }

 .boards_post_image {

   width: 300px;
   max-height: 290px;
   object-fit: cover;

   border-radius: 15px;
   cursor: pointer;
   padding: 0px;
 }

 .add-newpost-card {
   max-width: 250px;
   background-color: #272727;
   max-height: 300px;
   /* display: flex; */
   border-radius: 20px;
   justify-content: center;

 }

 .add_post_open_popup {
   position: relative;
   width: 300px;
   height: 350px;
   /* gap: 10px; */
   margin-bottom: 6px;
   border-radius: 15px;
   background: #272727;
   color: #FFF;
   border: none;
   font-weight: 600;
   font-size: 18px;
   font-family: Inter;
   display: flex;
   justify-content: center;
   align-items: center;
 }


 .image_grid {
  z-index: auto;
   width: 100%;
   height: auto;
   /* This ensures the height of the item grows with the content */
   display: flex;
   justify-content: center;
   /* Center horizontally */
   align-items: center;
   /* Center vertically */
   position: relative;
   padding: 2px !important;
 }



 .hamburger_menu {
   position: absolute;
   top: 10px;
   right: 10px;
   background: none;
   border: none;
   cursor: pointer;
 }


 /* Add these styles to your CSS file */

 .hamburger-board-popup {
   /* position: relative; */
   top: 0%;
   left: 0%;
   /* right: 3; */
   /* width: 200px;  */
   max-height: 200px;
   /* Full height */
   background-color: #3A3A3A;
   display: flex;
   justify-content: center;
   color: #fff;
   align-items: center;
   z-index: 997;
   /* Ensure it's above other content */
   border-radius: 15px;
   align-items: center;
   cursor: pointer;




 }

 .popup-board-box {
   margin-left: -50px;
   padding: 20px;
   /* Padding inside the box */
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   /* Some shadow for depth */
   width: 250px;
   /* Width of the popup box */


   position: absolute;
   /* Absolute position to place it relative to its parent */
   top: 10%;
   /* Align with the top of the hamburger button */
   left: 15%;

   background-color: #272727;
   /* White background for the popup */
   border-radius: 15px;

   padding: 20px;
   /* Padding inside the box */

 }

 .popup-option {
   /* display: flex; */
   align-items: center;
   gap: 5px;
   margin: 15px 0;
   /* Adds space between each option */
 }

 .popup-option span {
   margin-left: 5px;
   /* Optionally adjust the space after the icon if needed */
 }



.note_title {
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}
 
.boards_note {
  width: 300px;
  max-height: 290px;
  padding: 15px !important;
  object-fit: cover;
  background: #272727;
  color: #FFF;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position:relative;
}

 .popup3 {

   top: 10px;
   left: 10px;
   /* display: flex; */
   align-items: center;
   justify-content: center;
   top: 10px;
   right: 10px;
   /* display: flex; */
   /* align-items: center; */
   /* justify-content: center; */

   position: absolute;
   padding: 5px 9px;
   top: 30px;
   right: 3;
   /* Adjust as needed */
   width: 200px;
   /* Adjust as needed */
   background-color: #333;
   /* Adjust as needed */
   color: white;
   border-radius: 8px;
   padding: 15px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   z-index: 10;
   /* Ensure it's above other items */
 }

 /* Triangle arrow pointing towards the hamburger icon */
 .popup3:before {
   content: "";
   position: absolute;



 }



 .popup3 ul {
   list-style: none;
   padding: 0;
   margin: 0;
 }

 .popup3 li {
   cursor: pointer;
   padding: 5px 0;
   display: flex;
   align-items: center;
 }

 .popup3 li:hover {
   background-color: #444;

 }

 .popup3 img {
   margin-right: 8px;
 }



 .image_popup_container {
   position: fixed;
   /* Fixed position */
   top: 0;
   left: 20%;
   right: 20%;
   bottom: 2;

   background: rgba(0, 0, 0, 0.7);
   /* Translucent background */
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10;
   /* Higher than other content */
 }

 .grid_image_container {
   padding-top: 15px;
 }


 /*  CSS */
 @media (max-width:1024px) {
   .grid_image_container {
     column-count: 2;
   }

   .boards_post_image {
     width: 100%;
   }

   .add_post_open_popup {
     width: 100%;
   }

   .boards {
     width: 47%;
   }

   .grid_image_container {
     padding-bottom: 50px;
     padding-top: 15px;
   }
 }

 @media (max-width:991px) {
   .add_post_open_popup {
     height: 500px;
     font-size: 26px;
   }

   .publicprivateimage {
     height: 70px;
     padding: 38px 15px;
     font-size: 41px;
     margin-top: 20px;
   }

   .public-bd-name-back {
     padding: 20px 0px;
   }

   .public-bd-name-back h1 {
     font-size: 54px;
   }

   .public-bd-name-back h1 img {
     font-size: 38px;
   }

   .back-btn-board-name {
     padding-top: 12px;
   }

   .back-btn-board-name h1 {
     font-size: 22px;
   }

   .boards_post_image {
     max-height: 500px;
   }
   .popup-option span {
    font-size: 29px;
  }

  .popup-option svg {
    font-size: 27px;
    padding-right: 6px;
  }
  .popup-board-box {
    width: 410px;
  }
  .hamburger_menu img{
    width:75%;
  }

 }

 @media (max-width:500px) {
   .profile-name {
     font-size: 55px;
   }

   .public-bd-name-back {
     padding: 20px 0px;
   }

   .public-bd-name-back h1 {
     font-size: 82px;
   }

   .public-bd-name-back h1 img {
width: 50px;
   }

   .add_post_open_popup {
     height: 500px;
     font-size: 43px;
   }

   .dropdown-item span {
     font-size: 40px;
   }

   .dropdown-item img {
     width: 40px;
     height: 40px;
     flex-shrink: 0;
   }

   .dropdown-content {
     padding-top: 44px;
     width: 514px;
   }

   .dropdown-content {
     left: 0px;
     top: -54px;
   }

   .boards_post_image {
     height: 500px;
     max-height: 500px;
   }

   .image_grid {
     height: 500px;
   }

   .hamburger_menu img {
     width: 102px;
   }

   .popup-board-box {
     width: 71%;
   }

   .popup-option span {
     font-size: 39px;
   }

   .popup-option svg {
     font-size: 38px;
     padding-right: 6px;
   }

   .popup-board-box {}
   
 }



 @media (max-width:375px){
  .popup-option span{

  }
 }