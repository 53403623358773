.cardmain {
    width: 850px;
    /* max-height: 605px; */
    border: 1px solid #3A3A3A !important;
    background: #272727 !important;
}

.maincard {
    padding: 0px;
}

.card-img-top {
    width: 100%;
    height: 380px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.card-text {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    /* line-height: 100%; */
    /* padding: 10px; */
}

.mainprofileimg {
    padding: 10px;
    color: #fff;

}

.iconcontainer {
    position: relative;
}

.icon-container {
    position: relative;
    display: flex;
}

.roundedImg {
    border-radius: 100%;
    height: 45px;
    width: 45px;
    border: none;
    background-color: #272727;
}

.NoComments {
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 20.8px */
}

.rowcomments {
    padding-left: 14.93px;
    margin: 1px 12px 2px 0px;
    color: #FFF
}

.popular_popup_closebutton {
    position: absolute;
    top: 1;
    right: 15px;
    margin-top: 10px;
    border: none;
    background-color: #b0b0b03a;
    border-radius: 50%;
    padding: 3px 9px;
    cursor: pointer;
    font-size: 16px;
    color: #FFF;
    z-index:998;
}

.TM_likeIcon {
    width: 26.93px;
    height: 23.809px;
}

.TM_Ellipse {
    width: 23.258px;
    height: 25.033px;
}

.TM_bucketlist {
    width: 24.13px;
    height: 24.13px;
}

.TM_ShareIcon {
    width: 19.585px;
    height: 25.033px;
    float: inline-end;
}

.comments {
    width: 820.485px;
    height: 45.291px;
    flex-shrink: 0;
    border-radius: 39px;
    background: #3D3D3D;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-right: 300px;
    color: white;
    padding: 10px 20px;
    margin-bottom: 15px;
}

.comments input[type="text"] {
    width: 603.472px;
    height: 55px;
    flex-shrink: 0;
    color: #FFFFFF;
    border: none;
    background: none;
    color: white;
    outline: none;
    padding-left: 20px;
}

.commemts_profile_logo{
    width: 25px;
    height: 25px;
    border-radius: 50%;
}


.reply-text {
    font-size: 14px;
    margin-left: 15px;
    font-family: Inter;
}

.comment_likes {
    color: #FFF;
    font-size: 12px;
}

.popupcard-img-top-container {
    border-radius: 1px solid rgb(255, 255, 255);
    margin-top: 15px;
}

.see-more-option{
    width: 92%;
    height :50px;
    border-radius: 100px;
    border: none;
    color: #e5e3ed;
     background-color: rgb(126, 38, 228);
     font-weight: 800;

}

.post_location {

    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 0.9em;
    cursor: pointer;

}

.NotePost {
    padding: 20px;
    color: #FFF;
    background: #3a3a3a3a;
    position: relative;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    height: 350px;
}

.popupcard-img-top-container-2{
    width: 100% !important;
}







/*  CSS */


@media (max-width:1440px) {
    .description-popup-content {
        width: 94% !important;
    }

    .description-popup-content h3 {
        font-size: 27px;
    }

    #staticBackdrop3 {
        padding-right: 0px !important;
    }

    .description-popup-content p {
        padding: 16px 0px 10px;
    }
}

@media (max-width:1200px) {
    .mainprofileimg .post_username {
        font-size: 27px;
        margin-top: 0px;
    }
    

    .card-text {
        padding: 10px !important;
        font-size: 18px;
    }

    .mainprofileimg .search-pro-name {
        font-size: 35px;
        margin-bottom: 12px;
        margin-top: 0px !important;
    }

    .popupcard-img-top-container {
        width: 100% !important;
    }

    .description-popup-content {
        width: 100% !important;
    }

    .description-popup-content h3 {
        font-size: 40px;
    }
    .description-popup-content p {
        padding: 16px 0px 10px;
        font-size: 38px;
    }
    .comments input[type="text"] {
        font-size: 21px;
    }
    .prof-box-names{

    }
    .prof-box-names h3{
        font-size: 33px;
    }
    .prof-box-names p{
        font-size: 18px;
        padding-top: 12px;
    }
}

@media (max-width:991px) and (min-width:500px) {
    .cardmain {
        width: 1332px;
    
    }
   
   

}

@media (max-width:991px) {
    .rowcomments {
        padding: 15px;
    }
    .card-img-top {
        width: 100%;
        height: 668px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
    }

    .card-text {
        padding-top: 12px;
        font-size: 21px;
    }

    .trip_board_tittle {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .filter_search_diaplay {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .mainprofileimg .search-pro-name {
        font-size: 35px;
        margin-bottom: 12px;
    }

    .search-card-hashtag {
        font-size: 25px !important;
    }

    .comments {
        font-size: 29px;
        height: 60px;
        width: 96%;
    }
    

    .search-card-txt-cmts {}

    .search-card-txt-cmts h3 {
        font-size: 50px;
        padding-bottom: 25px;
    }

    .search-card-txt-cmts p {
        font-size: 23px;
    }
    .mbl-disp-none{
        display: none;
    }
    .profile_Title{
        font-size: 42px;
        padding-bottom: 12px;
    }
    .prof-box-names h3 {
        font-size: 50px;
    }
    .prof-box-names p {
        font-size: 21px;
    }
    .rowcomments img{
        width: 45px;
    height: 45px;
    }
    .rowcomments span{
        font-size: 20px;
    }
   
    .rowcomments .countsize {
        font-size: 24px !important;
        margin-left: 18px !important;
    }
    .prof-location-pointer span{
        font-size: 27px;
    }
    .see-more-option {
        width: 1340px;
        height: 85px !important;
        border-radius: 100px;
        font-size: 51px;
        color: #e5e3ed;
        margin: 25px 0px 25px 0px;
        background-color: rgb(126, 38, 228);
    }

}



@media (max-width:500px) {
    .rowcomments {
        padding: 35px;
    }
    .rowcomments .countsize{
        font-size: 39px !important;
        margin-left: 30px !important;
    }

    .card-text {
        padding: 12px;
        font-size: 44px;
    }
    .card-text span{
        font-size: 44px !important;
    }
    .iconsize {
        width: 66px;
        height: 66px;
        margin-bottom: 30px;
    }

    .post_username,
    .post_time {
        font-size: 44px;
    }

    .mainprofileimg .post_username {
        font-size: 50px;
        margin-top: 0px;
    }

    .mainprofileimg .search-pro-name {
        font-size: 42px;
    }

    .comments {
        height: 95px;
    }

    .search-card-hashtag {
        font-size: 44px !important;
        padding: 14px;
    }

    .search-card-txt-cmts h3 {
        font-size: 61px;
        padding-bottom: 25px;
    }

    .search-card-txt-cmts p {
        font-size: 45px;
    }

    .search-card-txt-cmts {
        height: 600px !important;
    }
    .prof-box-names {
        height: 550px !important;
    }
    .prof-box-names h3 {
        font-size: 70px;
    }
    .prof-box-names p {
        font-size: 31px;
        padding-top: 18px;
    }
    .rowcomments span {
        font-size: 27px;
    }
    .rowcomments img {
        width: 60px;
        height: 60px;
    }
    .comments input[type="text"] {
        font-size: 43px;
    }
    .comments {
        margin-top: 50px;
    }

    .NotePost h3{
        font-weight: 700;
        font-size: 42px;
    }
    .NotePost p{
        font-size: 42px;
    }

    .comments {
        width: 100%;
        height: 133px;
        border-radius: 90px;

    }

    .comments input[type="text"] {
        height: 100%;
    }

    .NotePost {
        padding: 20px;
        color: #FFF;
        background: #3a3a3a3a;
        position: relative;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        height: 350px;
    }
    .reply-text {
        font-size: 44px;
        margin-left: 15px;
        font-family: Inter;

}
.see-more-option{
    width: 97%;
    height :140px !important;
    border-radius: 100px;
    font-size: 51px;
    color: #e5e3ed;
    background-color: rgb(126, 38, 228);
}


}






@media (min-width: 424.5px) and (max-width: 767px) {
    .cardmain {
        width: 99%;
    }

    .comments {
        width: 100%;
        height: 133px;
        border-radius: 90px;

    }
    .comment_likes {
        color: #FFF;
        font-size: 32px;
    }

  
    .comments input[type="text"] {
        width: 603.472px;
        height: 100%;
        flex-shrink: 0;
        color: #FFFFFF;
        border: none;
        background: none;
        color: white;
        outline: none;
        padding-left: 20px;
        font-size: 45px;
    }



    .mainprofileimg {
        padding: 25px;
        color: #fff;

    }

    .locationpointer {
        width: 58px;
        height: 58px;
    }
    .popuplocation-map-kd img{
        width: 58px;
        height: 58px;
    }


    .roundedImg {
        border-radius: 100%;
        height: 95px;
        width: 95px;
        border: none;
        background-color: #272727;
    }

    .post_username,
    .post_time {
        font-size: 38px;
    }

    .post_location {

        position: absolute;
        bottom: 8px;
        left: 8px;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 38px;
        cursor: pointer;

    }

    .card-img-top {
        width: 100%;
        height: 880px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
    }


    .card-body {
        height: auto;
    }

    .iconsize {
        width: 50px;
        height: 50px;
    }

    .countsize {
        font-size: 35px;

    }

    .description-text {
        font-size: 35px;
    }

    .card-text {
        color: #FFFFFF;
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;

        /* line-height: 100%; */
        /* padding: 10px; */
    }


    .commentpost {
        cursor: pointer;
        width: 65px;
        height: 65px;
    }
    .see-more-option{
        width: 100%;
        
       
    }

}

@media (min-width: 375px) and (max-width: 424px) {
    .cardmain {
        width: 98%
    }


    .comments {
        width: 100%;
        height: 95px;
        border-radius: 90px;

    }

    .popular_popup_closebutton {
        right: unset;
        margin-left: 1350px;
    }

    .comments input[type="text"] {
        width: 603.472px;
        height: 32px;
        flex-shrink: 0;
        color: #FFFFFF;
        border: none;
        background: none;
        color: white;
        outline: none;
        padding-left: 20px;
        font-size: 45px;
    }

    .comment_likes {
        color: #FFF;
        font-size: 32px;
    }

    .mainprofileimg {
        padding: 25px;
        color: #fff;

    }

    .locationpointer {
        width: 45px;
        height: 45px;
    }


    .roundedImg {
        border-radius: 100%;
        height: 95px;
        width: 95px;
        border: none;
        background-color: #272727;
    }

    .post_username,
    .post_time {
        font-size: 38px;
    }

    .post_location {       
        font-size: 2.9em !important;
    }

    .card-img-top {
        width: 100%;
        height: 880px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
    }


    .card-body {
        height: auto;
    }

    .iconsize {
        width: 50px;
        height: 50px;
    }

    .countsize {
        font-size: 35px;

    }

    .description-text {
        font-size: 45px;
    }

    .card-text {
        color: #FFFFFF;
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;

        /* line-height: 100%; */
        /* padding: 10px; */
    }


    .commentpost {
        cursor: pointer;
        width: 65px;
        height: 65px;
    }

}
@media (max-width:416px){

    .see-more-option{
        width: 98% !important;
        /* height: 105px !important; */
      
    }
}

@media (max-width:375.9px){
    .prof-box-names p {
        font-size: 40px;
    }
    .see-more-option{
        width: 98% !important;
        /* height: 105x !important; */
      
    }
}


@media (min-width: 320px) and (max-width: 374px) {
    .cardmain {
        width: 83%
    }

    .comments {
        width: 100%;
        height: 95px;
        border-radius: 90px;

    }

    .comments input[type="text"] {
        width: 603.472px;
        height: 32px;
        flex-shrink: 0;
        color: #FFFFFF;
        border: none;
        background: none;
        color: white;
        outline: none;
        padding-left: 20px;
        font-size: 45px;
    }
    .comment_likes {
        color: #FFF;
        font-size: 32px;
    }



    .mainprofileimg {
        padding: 25px;
        color: #fff;

    }

    .locationpointer {
        width: 45px;
        height: 45px;
    }


    .roundedImg {
        border-radius: 100%;
        height: 95px;
        width: 95px;
        border: none;
        background-color: #272727;
    }

    .post_username,
    .post_time {
        font-size: 38px;
    }

    .post_location {

        position: absolute;
        bottom: 8px;
        left: 8px;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 38px;
        cursor: pointer;

    }

    .see-more-option{
        width: 100% !important;
        /* height :100px !important; */
      
    }


    .card-img-top {
        width: 100%;
        height: 880px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
    }


    .card-body {
        height: auto;
    }

    .iconsize {
        width: 50px;
        height: 50px;
    }

    .countsize {
        font-size: 35px;

    }

    .description-text {
        font-size: 35px;
    }

    .card-text {
        color: #FFFFFF;
        font-family: Inter;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;

        /* line-height: 100%; */
        /* padding: 10px; */
    }


    .commentpost {
        cursor: pointer;
        width: 65px;
        height: 65px;
    }

}


