.setting {
    color: #FFF;
    font-size: 22px;
    font-weight: 600;
    /* Ensure there's some spacing at the top of the sidebar for the 'Settings' title */
    padding: 20px 0;
    text-align: left;
    padding-left: 20px;
   
}
 
.rightnav {
    background-color: #131313;
    border-left: 1px solid #3A3A3A;
    padding-left: 20px;
}
 
.profileinformation1 {
    color: #FFF;
    font-size: 16px;
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 30px; /* Adjusted to match the rounded edges */
    /* transition: background 0.3s; */
    width: 100%; /* To ensure the items take up the full width */
    margin: 8px 0; /* Spacing between items */
    position: relative; /* Needed for the pseudo-element of the active state */
    margin-left: 10px;
}
 
.profileinformation1:hover, .profileinformation1.active {
    background: #272727;
    width: 200px;
}
 
/* Added a pseudo-element to create the circle indicator for the active item */
.profileinformation1.active::before {
    content: '';
    position: absolute;
    left: -16px; /* Position the circle properly to the left */
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    /* background-color: #6C63FF;  */
    border-radius: 50%;
}
 
.settingcol {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    background-color: #000;
    height: 100vh;
    /* Adding width to prevent resizing */
    width: 250px; /* Adjust as per your design requirement */
    /* Aligning items to the start (left) and not stretching them */
    align-items: flex-start;
}
 
/* Remove default margin and padding from all elements */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
 
/* Ensure links have the appropriate style */
a {
    text-decoration: none;
    color: inherit; /* This will ensure <a> tags use the color of the parent */
}

/*  CSS */

@media (max-width:991px){
    .right-setting-block{
        display: block !important;
    }
    .right_nav{
        display: none;
        display: block !important;
    }
}


@media (max-width:500px){
    .stng-mble-d-block{
        display: block !important;
    }
    .stng-mble-d-flex{
        display: flex !important;
        width: 100%;
    }
    .settingcol {
        height: auto;
        width: 100%;
    }
    .setting {
        font-size: 50px;
    }
    .profileinformation1 {
        width: auto;
        font-size: 32px;
        letter-spacing: 2px;
    }
    .profileinformation1.active {
        background: #272727;
        width: auto;
    }
    .profile_heading {
        width: 1000%;
        font-size: 50px;
    }
    .right-setting-block .lablenames {
        width: 100%;
        font-size: 36px;
    }
    .right-setting-block .nameinput {
        width: 100%;
        height: 150px;
        margin-top: 38px;
        font-size: 51px;
        padding-left: 45px;
    }

}