
.home-body {
    background-color: #131313;
    font-family: Inter;
    display: flex;
  
}

.createbtn {
    background-color: rgb(126, 38, 228);
    height: 45px;
    border-radius: 2rem;
    width: 650px;
    text-align: center;
    padding-top: 5px;
    font-size: 20px;
    border: none;
}

.body-margin-container{
    padding-Right: 5px;
    border-Right: 1px solid #3A3A3A;   
    margin-left:0px;
    column-width: 1000px;
    
}



.mainboard {
    color: #FFF;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 12px;
    padding: 7px 7px 5px;
}

@media (min-width: 320px) and (max-width: 374px) {
    .body-margin-container{
        padding-Right: 5px;
        border-Right: 1px solid #3A3A3A;  
        margin-left:0px;
        column-width: 1500px;
       
    }
    .popular-and-trending{
        display: none !important;
    }
    .mainboard {
        color: #FFF;
        font-family: Inter;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 25px;
        margin-bottom: 25px;
    }
  
}

@media (min-width: 375px) and (max-width: 424px) {
    .body-margin-container{
        padding-Right: 5px;
        /* border-Right: 1px solid #3A3A3A;   */
        margin-left:0px;
        column-width: 1440px;
       
    }
    .popular-and-trending{
        display: none !important;
    }
    .mainboard {
        color: #FFF;
        font-family: Inter;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

    @media (min-width: 425px) and (max-width: 767px) {
        .body-margin-container{
            padding-Right: 5px;
            border-Right: 1px solid #3A3A3A;  
            margin-left:0px;
            column-width: 1500px;
           
        }
        .popular-and-trending{
            display: none !important;
        }
        .mainboard {
            color: #FFF;
            font-family: Inter;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
