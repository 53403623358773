.link-popup {
  border-radius: 15px;
  border: 1px solid #3a3a3a;
  background: #272727;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  width: 50vw;
  /* Adjusted width to 50% */
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  margin-top: 100px;
}

.popup-content-wrapper {
  width: 100%;
  overflow-y: auto;
  margin-top: 20px;
  /* max-height: 60vh; */
  padding-top: 20px;
  /* Top padding added */
}

.images-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-card {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 20px;
  align-items: start;
  background: #333;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.thumbnail {
  width: 200px;
  height: 200px;
  border-radius: 5px;
}

.image-details {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
}

/* textarea,
  input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }
   */
.save-button {
  background-color: #714BDC;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;


}

/* .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 24px;
  } */

.url-input {
  flex-grow: 1;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.feedback-message {
  color: white;
  margin-top: 10px;
  text-align: center;
}

.image-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}

.image-item {
  position: relative;
}

.saved-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-start;
  height: 150px;
  /* overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; */

}

.saved-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: content;
  border-radius: 5px;

}


.description-input-link {
  height: 90px;
  background-color: #272727;
  border-radius: 5px;
  color: #FFF;
  padding: 5px;
  width: 100px;
}

.hashtag-location-container {
  display: flex;
  gap: 10px;
}

.hashtag-input,
.location-input {
  flex: 1;
  padding: 5px;
  margin-top: 5px;
  background-color: #272727;
  border: none;
  border-radius: 15px;
  color: #FFF;
  font-family: Inter;
  width: 25px;
}

.hashtag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px;

}

.hashtag-item {
  background-color: #714BDC;
  color: #333;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px;
  border-radius: 15px;
  color: #FFF;
}


.image-card {
  display: flex;
  align-items: flex-start;
  background: #333;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
}

.image-item {
  margin-right: 20px;
  flex-basis: 200px;
  /* Add space between the image and the input fields */
}

.image-details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
  /* Adjust width to take remaining space */
}

.thumbnail {
  width: 200px;
  /* Fixed width for the image */
  height: 200px;
  /* Fixed height for the image */
  border-radius: 5px;
  object-fit: content;
}

/* Additional styles for proper alignment */
.hashtag-location-container,
.hashtag-list,
.image-details>textarea,
.image-details>.save-button {
  width: 100%;
  /* Ensure child elements occupy the full width of the image-details container */
}

.save-button {
  align-self: center;
  /* Center align save button within the image-details */
}

/* Adjustments for responsive design */
@media (max-width: 768px) {
  .image-card {
    flex-direction: column;
    align-items: center;
  }

  .image-item,
  .image-details {
    width: 100%;
  }

  .thumbnail {
    width: 100%;
    height: 800px;
    object-fit: cover;

}

  .save-button {
    align-self: stretch;
    /* Full width save button on smaller screens */
  }
}

/*  CSS */

@media (max-width:1200px) {
  .link-popup {
    max-height: 34%;
    width: 61%;
    top: 30%;
  }
}

@media (max-width:500px) {
  .url-input {
    margin-top: 20%;
    padding: 5px 5px 12px 30px;
    font-size: 51px;
    border-radius: 15px;
    height: 150px;
    width: 1200px;
  }

  .link-popup {
  
      /* max-height: 100%; */
      width: 99%;
      /* top: 22%; */
       /* left: 52%; */
  }
  .description-input-link{
    height: 154px;
    font-size: 51px;
  }

  .hashtag-input,
.location-input {

  padding: 45px;
  font-size: 51px;
}
.save-button {
 
  padding: 40px 20px;
  border-radius: 25px;
  font-size: 51px;


}
.image-checkbox {
 
 
  width: 100px;
  height: 57px;
}
.hashtag-item {
  font-size: 51px;
}
.link-popup {
  max-height: 88%;
  margin-top: 41%;
}

.saved-thumbnail {
  width: 150px;
  height: 150px;
  

}

}
@media(max-width:380px){
  .link-popup {
    max-height: 90%;
    margin-top: 45%;
  }

}
@media(max-width:321px){
  .link-popup {
    max-height: 87%;
    margin-top: 50%;
  }

}