.footer {
    height: 104px;
    flex-shrink: 0;
    border: 0.5px solid #3A3A3A;
    background: #131313;
    width: 100%; /* Ensure the footer stretches across the bottom */
    /* margin-top: 395px;  */
}

.footer .d-flex.mt-5 {
    width: 100%;
    max-width: 1520px;
    padding: 0 15px;
    margin: 0 auto;
}


.footertext {
    width: 65%;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding-left: 10px;

}

.Holdings {
    width: 35%;
    flex-shrink: 0;
    color: #FFF;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: auto;
    padding-right: 10px;
}

.anchertag {
    text-decoration: none;
    color: #ffffff;
}

















/*  CSS */
@media (max-width:1200px) and (min-width:991px){
    .footer {
        width: 100%;
    }

    .signup12-proc .footer{
        width: 1520px;
    }
    .main-pg-main-dashboard .footer{
        width: 1540px;
    }
}

@media (max-width:1024px){
    .footertext, .Holdings {
        font-size: 19px;
    }
}

@media (max-width:991px){
    .signup12-proc .footer{
        width: 1520px;
    }
    
}

@media (min-width: 425px) and (max-width: 767px) {
    .footer {
        height: 195px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        width: 1520px; 
        box-sizing: border-box; /* Includes padding and border in the width */
        margin-top: 200vh;
    }
    .signup12-proc .footer{
        margin-top: 0px !important;
    }

    .footer .d-flex.mt-5 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-left: -45px; */
        width: 100%;
        padding: 0;
        margin: 0; 
        margin-top: 395px;
    }

    .footertext, .Holdings {
        width: 100%;
        font-size: 42px; 
      
    }

    .Holdings {
        text-align: center;
        order: 2;
    }

    .Holdings + .year {
        order: 3;
        width: 100%;
        text-align: center;
        /* margin-top: -5px; */
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .footer {
        height: 195px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        width: 1520px; 
        box-sizing: border-box; /* Includes padding and border in the width */
        margin-top: 170vh;
    }



    .signup12-proc .footer {
        margin-top: 0px !important;
    }

    .footer .d-flex.mt-5 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-left: -45px; */
        width: 100%;
        padding: 0;
        margin: 0; 
        margin-top: 395px;
    }

    .footertext, .Holdings {
        width: 100%;
        font-size: 42px; 
      
    }

    .Holdings {
        text-align: center;
        order: 2;
    }

    .Holdings + .year {
        order: 3;
        width: 100%;
        text-align: center;
        /* margin-top: -5px; */
    }
}



@media (max-width:370px){
    .Holdings {
        margin-left: 0px !important;
    }
    .footertext{
        padding-bottom: 24px;
    }
    .footer {
        height: auto !important;
        margin-top: 115vh !important;
        width: 100% !important;
    }
}
@media (max-width:500px){
    .footertext, .Holdings {
        font-size: 36px;
    }
    .Holdings{
        margin-left: inherit;
    }
    .footer {
        /* margin-bottom: 150px !important; */
        padding: 20px 20px 40px !important;
    }
}





@media (min-width: 320px) and (max-width: 374px) {
    .footer {
        height: 195px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        width: 1520px; 
        box-sizing: border-box; /* Includes padding and border in the width */
        margin-top: 170vh;
    }

    .footer .d-flex.mt-5 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-left: -45px; */
        width: 100%;
        padding: 0;
        margin: 0; 
        margin-top: 395px;
    }

    .footertext, .Holdings {
        width: 100%;
        font-size: 42px; 
      
    }

    .Holdings {
        text-align: center;
        order: 2;
    }

    .Holdings + .year {
        order: 3;
        width: 100%;
        text-align: center;
        /* margin-top: -5px; */
    }
}


@media (max-width: 375px) {
    .signupprocess {
        width: 1280px !important;
    }
}


@media (max-width:370px){
    .signup12-proc .footer {
        margin-top: 0px !important;
    }

    
}


