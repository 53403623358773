.postscontainer {

  column-count: 4;
  column-gap: 10px;
  padding: 0;
  gap: 10px;
  align-items: flex-start;
  border-radius: 15px;
}

.addpostopen_popup {
  width: 310px;
  height: 215px;
  object-fit: cover;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 15px;
  color: #B2B2B2;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #272727;
  border: none;
}
.note_description{
font-size: 14px;  
padding-bottom: 25px;
/* height: 195px; */
overflow: hidden;
}

.PostMessahe_card {
  border-radius: 15px 0px 0px 15px;
  background: #714BDC;
  margin-left: 25px;
  width: 25px;
}

.welcome-postmessage {
  position: relative;
  width: 900px;
  height: 291px;
  flex-shrink: 0;
  border-radius: 0px 15px 15px 0px;
  background: #272727;
  color: #FFF;
  /* Assuming --icon-color is always white for simplicity */
  font-family: Inter;
  margin-left: 25px;
  overflow: hidden;
  /* Ensures nothing spills out of the border-radius */


}

.welcome-postmessage div {
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.welcome-postmessage div,
.welcome-postmessage p {
  padding-left: 25px;
  /* Adjust the left padding */
  margin: 45px;
  line-height: 25px;
}

/* .welcome-message p {
  width: 900px;
  padding-right: 45px;
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */



.postcard {

  position: relative;
  /* display: inline-block; */
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
  background: #272727;

  /* This prevents the items from being split across columns */
  break-inside: avoid;
  /* overflow: hidden; */
}

.postopenpopup {
  color: #B2B2B2;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #272727;
  border: none;
}

.innerContainer {
  margin: 20px 5px 0px 5px;
}

.postcard {
  width: 315px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #272727;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 20px;
  /* height: 200px; */
  max-height: 200px;
  /* Adjust the height as needed */
  /* overflow-y: auto; */
  /* max-height: 150px; */
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 #575555; */
  z-index: 0;
}
.bucklst-text-card{
  height: 270px;
  padding: inherit;
  font-family: Inter;
}

.bucklst-text-card p{
  font-size: 18px;
  font-weight: 800;

}

.scrollable-text {
  overflow-y: scroll;
  max-height: 150px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrollable-text::-webkit-scrollbar {
  width: 8px;
}

.scrollable-text::-webkit-scrollbar-track {
  background-color: #adaaaa;
}

.scrollable-text::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollable-text::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}




.addnewpostcard {
  position: relative;
  margin-bottom: 1px;
  border-radius: 15px;
  /* background: #272727; */
  display: flex;
  justify-content: center;
  align-items: center;
}


.card-image {
  width: calc(100% + 80px);
  width: 322px;
  max-height: 290px;
  object-fit: cover;
  margin: -20px;
  border-radius: 15px;
  cursor: pointer;
}


.loading {
  color: #fff
}

.hamburger-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  z-index: 1000;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px;
}

.textpopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

/* .dropdown-content {
  position: absolute;
  background-color: #3A3A3A;
  width: 180px;
  height: 133px;
  flex-shrink: 0;
  border: 1px solid #ccc;
  z-index: 1;
  padding: 5px;
  left: 27rem;

  border-radius: 5px;
  color: white;
} */
.image-preview-container {
  /* Place this next to the add button or wherever you want it in your layout */
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* Add some spacing */
}

.image-preview {
  width: auto;
  max-height: 100px;
  /* Adjust the size as needed */
  object-fit: contain;
  margin-right: 10px;
  /* Space between image and remove button */
}

.dropdown-content {
  position: flexed;
  margin-left: 95px;
  width: 200px;
  /* background-color: #3A3A3A; */
  border-radius: 5px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional: adds a shadow for better visibility */
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  /* padding: 15px; */
}

.dropdown-item:hover {
  border-radius: 4px;
  background: #272727;
  width: 151px;

}

.dropdown-item img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.dropdown-item span {
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.6rem;
  margin-left: 15px;
}




/* .hamburger-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1000;
}
*/
/* .popup-container.popup-above {
  bottom: 10px;
  top: auto;
  transform: translateX(-50%);
} */


.hamburger-popup {
  position: absolute;
  /* This should be absolute, not fixed */
  top: 10px;
  /* Adjusted to appear right below the hamburger button */
  right: 10px;
  /* Adjusted to stick to the right */
  width: auto;
  /* Auto width based on content */
  height: auto;
  /* Auto height based on content */
  background-color: #272727;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  /* Make sure this is high enough to be above other content but not too high */
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hamburger-popup {
  position: fixed;
  /* Positioning relative to the viewport */
  top: 50%;
  left: 50%;
  width: 200px;
  /* Full width */
  height: 200px;
  /* Full height */
  background-color: #3A3A3A;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
  border-radius: 15px;
  align-items: center;
  cursor: pointer;




}

.popup-box {

  padding: 20px;
  /* Padding inside the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Some shadow for depth */
  width: 240px;
  /* Width of the popup box */


  position: relative;
  /* Absolute position to place it relative to its parent */
  top: 0;
  /* Align with the top of the hamburger button */
  right: 0;
  /* Align with the right of the hamburger button */
  background-color: #272727;
  /* White background for the popup */
  border-radius: 15px;

  padding: 20px;
  /* Padding inside the box */

}

.close-popup {
  position: absolute;
  top: 10px;
  right: 17px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.hamburger-btn {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  padding: 10px;
}

/* Styling the paragraphs to resemble buttons */
.popup-box p {
  margin: 10px 0;
  padding: 10px;

  border-radius: 4px;
  /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth background-color change */
}

.popup-box p:hover {
  background-color: #3A3A3A;
  /* Change background on hover */
}

.popup-box img {
  margin-right: 8px;
  /* Space between the image and text */
}


.close-popup {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0px;
  border: none;
  background-color: #3A3A3A;
  border-radius: 50%;
  padding: 3px 9px;
  cursor: pointer;
  font-size: 16px;
  color: #FFF
}



.close-popup:hover {
  color: #ccc;
}

.popup {
  width: 579px;
  height: 166px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #3A3A3A;
  background: #272727;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  z-index: 100;
  top: 200%;
  left: 52%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;


}


@media (min-width: 2560px) {
  .dropdown-content {
    position: absolute;
    top: 100%;
    /* Aligns the top of the dropdown right below the button */
    left: 39%;
    /* Aligns the dropdown to the left edge of the addnewpostcard container */
    width: 200px;
    /* Adjust as needed */
    background-color: #3A3A3A;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 809px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: adds a shadow for better visibility */
  }
}




.add-board-overlay {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  position: fixed;
  /* Positioning relative to the viewport */
  top: 50%;
  left: 50%;
  max-width: 350px;
  height: 200px;
  background-color: #3A3A3A;
  /* display: flex;      */
  justify-content: center;
  color: #fff;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
}

.add-board-popup {
  /* background-color:  #3A3A3A; */
  border-radius: 8px;
  padding: 20px;

  width: 300px;
  cursor: pointer;




  scrollbar-width: 1px;
}

/* .close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
} */

.add-board-action {

  cursor: pointer;
  background-color: #807676;
  padding: 5px 9px;
  margin-bottom: 15px;
  /* margin-top: 15px; */
  width: fit-content;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-board-action img {
  margin-right: 8px;
  cursor: pointer;


}

.board-item {
  cursor: pointer;
  color: #fff;
  background-color: #969292;
  padding: 8px 15px;
  margin-bottom: 15px;
  border-radius: 25px;
}

.board-item:hover {

  border-radius: 25px;





}

/* .board-item:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  cursor: pointer;
  padding:5px 9px;
} */
/*  
.board-item span[role="img"] {
  margin-left: 5px;
} */

/* Add additional styling as needed */



.create-board-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-board-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  /* Adjust to your preference */
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* Additional styles for the X icon */
}

.new-board-name-input,
.upload-cover-image-btn,
.cancel-btn,
.create-btn {
  /* Add your CSS styles here */
  width: 100%;
  /* Adjust width as needed */
  margin-bottom: 10px;
  /* Spacing between elements */
}

/* Additional styles for buttons and input */

.board-list {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/*  CSS */
@media (max-width:1024px) {
  .postscontainer {
    column-count: 2;
  }

  .postcard {
    overflow-y: hidden;
    z-index: 0;
  }



  .landing-pg-bgs .row {
    margin-right: 0px !important;
  }
}

@media (max-width:1200px) and (min-width:991px) {
  .dropdown-content {
    margin-left: -111px;
  }
}

@media (max-width:991px) {
  .post_username {
    font-size: 22px;
    margin-top: 0px !important;
  }

  .close-popup {
    top: 15px;
    right: 22px;
    padding: 7px 15px;
    font-size: 24px;
  }

  .dropdown-content {
    padding-top: 28px;
    width: 300px;
  }

  .board-popup-menu {
    margin-top: 378px !important;
  }

  .card-image {
    max-height: 500px;
  }

  .addpostopen_popup {
    height: 500px;
  }

  .dropdown-item span {
    font-size: 21px;
  }

  .dropdown-item img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  .hamburger-btn svg {
    font-size: 35px;
  }

  .popup-body .option-item span {
    font-size: 28px;
  }

  .add-board-popup {
    width: 95%;
  }

  .add-board-overlay {
    top: 21%;
    left: 22%;
    width: 60%;
    max-width: none;
    height: 60%;
  }

  .add-board-action img {
    width: 27px;
  }

  .add-board-action span {
    font-size: 29px;
    ;
  }

  .board-item {
    font-size: 27px;
  }
}

.mble-dsp-none{
  display: block;
}
.mble-dsp-block{
  display: none;
}


@media (max-width:500px) {
  .mble-dsp-none{
    display: none;
  }

  .note_description{
    font-size: 35px;  
  
    }

  .mble-dsp-block{
    display: block;
  }
  .addpostopen_popup {
    font-size: 30px;
  }
  .addnewpostcard{
      width: 100% !important;
      height: 190px !important;
      margin-bottom: 20px;
  }
  .addnewpostcard .addpostopen_popup {
    width: 100% !important;
  }

  .card-image {
    max-height: 500px;
  }

  .postcard {
    max-height: 500px !important;
  }

  .addpostopen_popup {
    max-height: 190px !important;
    font-size: 55px !important;
  }

  .hamburger-btn svg {
    font-size: 50px;
  }

  .popup-body .option-item span {
    font-size: 35px;
  }

  .popup-body {
    width: 320px !important;
  }
  .scrollable-text p{
    font-size: 45px;
  }
  .add-board-overlay {
    top: auto;
    left: 0px;
    width: 100%;
    max-width: none;
    height: 155vh;
    bottom: 10px !important;
}
.board-item {
  cursor: pointer;
  color: #fff;
  background-color: #969292;
  padding: 36px 68px;
  margin-bottom: 15px;
  font-size: 51px !important;
  border-radius: 100px;

}

.add-board-action {
 
  padding: 33px 100px;
  margin-bottom: 15px;

  width: fit-content;
  border-radius: 100px;
  font-size: 51px !important;
 
}
.add-board-action span {
  font-size: 51px;
}
.add-board-action img {
  width: 70px;
}

}