.publicTripBoards {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  cursor: pointer;
}

.publicTripBoards .container {
  position: relative;
  margin-top: 0;
  padding: 0 30px 0 0;
}

.publicTripBoards .rightnav {
  border: none
}


.trip_board_tittle {
  width: 100%;
  color: #fff;
  margin-bottom: 15px;
  margin-top: -26px;
}

.Filter_searchbtn {
  width: 100%;
  height: 44px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  border: 2px solid white;
  background-color: black;
  margin-bottom: 20px;
  color: white;
  padding: 20px;
}

.Filter_searchbtn input[type="text"] {
  border: none;
  background: none;
  color: white;
  outline: none;
  width: 450px;
  padding-left: 20px;
}


.Popular_boards_box {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  display: flex;
}


.profile-info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.profile-pic {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.profile-pic img {
  width: 100%;
  height: 100%;
}

.username {
  color: white;
  font-size: 16px;
}

.board-image {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

/* .board-image:after{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8), transparent 22%, transparent 82%, rgba(0,0,0,0.8));
  z-index: 0;
}
  */
.board-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}



.like-icon,
.bucket-icon {
  /* margin: 15px; */
  width: 26.93px;
  height: 25.809px;
  flex-shrink: 0;
}

.count {
  font-size: 14px;
  color: white;
}

.public-board-name {
  color: #272727;
  font-size: .8rem;
  font-family: Inter;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 30px;
  margin-right: 10px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.675);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.location {
  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  position: absolute;
  margin-top: 120px;
  padding: 4px 10px;
  border-radius: 15px;
  background: rgba(50, 50, 50, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.location img {
  height: 10px;
  width: auto;
}

.default_profileimage {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.overlay-board {
  position: absolute;
  bottom: 10px;
  right: 30px;
  display: flex;
  align-items: flex-end;
}

.board-info {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.popup-content1 {
  position: relative;
  padding: 40px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 200px;
  /* Adjust based on your content needs */
  max-width: 90%;
  /* Prevents the popup from being too wide on large screens */
  text-align: center;
}

.close-btn1 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  /* Color can be adjusted to fit the theme */
}

.popup-content1 button:hover {
  color: #666;
  /* Slight color change on hover for visual feedback */
}


@media (min-width: 320px) and (max-width: 374px) {
  .trip_board_tittle {
    font-family: Inter;
    font-size: 86px;
  }

  .rightnav {
    display: none !important;
  }

  .body-margin-container {
    padding-Right: 5px;
    border-Right: 1px solid #3A3A3A;
    margin-left: 0px;
    column-width: 1280px;

  }
}

/*  CSS */
@media (max-width:1200px) {
  .username {
    font-size: 20px;
  }

  .public-board-name {
    font-size: 15px;
  }

  .like-icon,
  .bucket-icon {
    margin: 15px;
    width: 19.93px;
    height: 25.809px;
    flex-shrink: 0;
  }
  .icon-container {
    display: inline;
  }
}

@media (max-width:991px) {
  .username {
    color: white;
    font-size: 30px;
  }

  .public-board-name {
    font-size: 21px;
    padding: 7px 10px;
  }

  .location {
    font-size: 20px;
    padding: 10px 10px;
  }

  .trip_board_tittle {
    font-size: 42px;
  }

  .Filter_searchbtn {
    height: 51px;
    font-size: 28px;
  }
}

@media (max-width:500px) {
  .username {
    color: white;
    font-size: 55px;
  }

  .Filter_searchbtn {
    height: 94px;
    font-size: 47px;
  }

  .trip_board_tittle {
    font-size: 52px;
    margin-bottom: 30px;
  }
  .search-pro-img-tp-landmark{
    font-size: 32px;
  }
  .searchfull-bdy{
    width: 100% !important;
  }

  .profile-pic {
    width: 65px;
    height: 65px;
  }

  .public-board-name {
    font-size: 44px;
    padding: 7px 10px;
  }

  .like-icon,
  .bucket-icon {
    margin: 15px;
    width: 71px;
    height: 71px;
    flex-shrink: 0;
  }

  .count {
    font-size: 54px;
    color: white;
  }
  
}