.board-public-popup-overlay {
  position: fixed;
  top: 65%;
  left: 55.8%;
  transform: translate(-60%, -60%);
  background-color: rgb(86, 86, 87);
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  z-index: 1000;
  border-radius: 15px;
  width: 900px;
  height: 600px
    /* Ensure it's on top of everything */
}



.landscape-image {
  width: 473px;
  height: 570px;
  object-fit: cover;
  margin-left: 0px;
  bottom: 0;
  right: 0;
  padding: 10px;
  border-radius: 25px;

}

.board-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centered content */
  z-index: 10000;
}

.user-name {
  font-weight: bold;
  margin-bottom: 8px;
  /* Space below the user name */
}

.trip-title {
  font-size: 24px;
  /* Larger font size */
  margin-bottom: 8px;
  /* Space below the trip title */
}

.location {
  font-size: 16px;
  /* Adjusted font size */
  margin-bottom: 16px;
  /* Space below the location */
}

.hashtags-input {
  width: 103px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 64px;
  border: 1px solid #3A3A3A;
  /* background: #131313; */
  color: #fff;
  padding: 5px 9px;
}

.make-public-button {
  align-self: center;
  margin-top: auto;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 370px;
  height: 43px;
  flex-shrink: 0;
  bottom: 0;
  border-radius: 55px;
  background: #714BDC;
}

.make-public-button.active {
  background-color: #bf5af2;
  /* Change color when active */
}


.board-popup-name {
  width: 374px;
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.board-popup-loaction {
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 301px;
  height: 25px;
  flex-shrink: 0;
}


.tag-board-input-container {
  display: flex;
  flex-direction: column;
  /* This ensures that the children are laid out in a column, one below the other */
  width: 370px;
  height: 150px;
  background-color: #424141;
  overflow-y: auto;
  /* 'auto' will only show scrollbar when needed */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /* Consider making this visible to users for usability */
  border-radius: 15px;
  padding: 3px 15px;
  box-sizing: border-box;
  margin-bottom: 5px;

}


/*  CSS */

@media (max-width:1200px) and (min-width:991px) {
  .board-public-popup-overlay {
    width: 91%;
  }
  .make-public-button-tp{
    margin-top:  255px !important;
  }

  .board-popup-loaction {
    font-size: 22px;
    padding: 10px 0px;
    color: #fff;
    height: 50px;
  }

  .board-popup-loaction img {
    margin-right: 20px;
  }

  .hash_tag_input {
    font-size: 15px;
    width: 155px;
    height: 30px;
  }

  .board-name {
    font-size: 18px;
  }

}

@media (max-width:1024px) {
  .board-public-popup-overlay {
    height: auto;
    top: 60%;
    padding: 20px;
  }

}

@media (max-width:991px) {
  .board-public-popup-overlay {
    height: auto;
    top: 37%;
    left: 60%;
    width: 91%;
    padding: 20px;
  }

  .landscape-image {
    width: 100%;
  }

  .bpp-tp-hd .ms-1 {
    font-size: 35px;
  }

  .bpp-tp-hd img {
    width: 64px;
    height: 64px;
    border-radius: 100%;
  }

  .board-popup-name {
    padding-bottom: 12px;
    font-size: 44px;
  }

  .board-popup-loaction {
    font-size: 24px;
    padding-bottom: 20px;
    height: auto;
  }

  .board-popup-loaction img {
    width: 23px;
    margin-right: 12px;
  }

  .make-public-button {
    width: auto;
    height: auto;
    font-size: 29px;
  }
  .make-public-button-tp{
    margin-top: 250px !important; 
  }


}



@media (max-width:500px) {
  .board-public-popup-overlay {
    height: auto;
    top: 59.2%;
    left: 60%;
    width: 97%;
    padding: 20px;
    display: block !important;
  }
  .landscape-image {
    height: 1400px !important;
    padding-top: 87px;
  }
  .bpp-tp-hd .ms-1 {
    font-size: 53px;
}
.bpp-tp-hd img {
  width: 97px !important;
  height: 97px !important;
  border-radius: 100%;
}   
 .board-popup-name {
  font-size: 74px;
}
.board-popup-loaction img {
  width: 34px;
}
.tag-board-input-container {
  width: 100%;
  height: 77%;
}
.tag {
  height: auto;
  font-size: 61px;
  padding: 4px 31px;
  border-radius: 60px;
  margin-top: 22px;
  letter-spacing: 4px;
}
.remove-tag-button {
  padding-left: 27px;
}
.bktlst-tag-board-main{
  height: 117vh;
}
.make-public-button-tp {
  margin-top: 33px !important;
}
.make-public-button {
  font-size: 51px;
}
.hash_tag_input {
  font-size: 30px !important;
}
.board-popup-loaction {
  font-size: 41px;
}

  
}