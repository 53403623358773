/* TopDiscoveries.css */
.imagewidthContainer {
  width: 256px;
  /* height: 381px; */
  flex-shrink: 0;
  display: flex;
  /* border-radius: 15px; */

}

.imagewidthContainer img {
  margin-left: 15px;
  width: 268px;
}


@media (min-width: 320px) and (max-width: 374px) {

  /* TopDiscoveries.css */
  .imagewidthContainer {
    width: 356px;
    height: 651px;
    flex-shrink: 0;
    display: flex;
    /* border-radius: 15px; */

  }


}

/*  CSS */
@media (max-width:991px){
  .imagewidthContainer img {
    width: 360px;
    margin-left: 15px;
}

}
@media (max-width:374px) {
  .imagewidthContainer img {
    margin-left: 15px;
    width: 389px !important;
  }
}
