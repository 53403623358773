.no-posts-message h2 {
    color: #ffffff; /* White text color */
    text-align: center;
    /* margin: 55px; */
  }
  
  .no-posts-message p {
    color: #cccccc; /* Light gray text color */
    text-align: center;
    font-size: 16px;
  }
  
  .no-posts-message{
    margin: 155px;
  }

  .popupcard-img-top-like-container   {
    border-radius: 1px solid rgb(255, 255, 255);
    margin-top: 15px;
}



@media (max-width:500px){
  .popupcard-img-top-like-container {
    margin-top: 5px !important;
  }

  .popup_post_Location{
    width: 45px;
    height: 45px;
  }
}