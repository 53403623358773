@media (max-width:500px){
    .modal-open{
        padding-right: 0px !important;
    }
}

.fixedNav {
    position: fixed;
    background-color: #131313;
    width: 100%;
    height: 122px;
    border-bottom: 1px solid #3A3A3A;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.outlet {
    width: 100%;
    max-width: 1520px;
    margin: 155px auto 0px auto;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
}

.outlet .homebody {
    width: calc(100% - 60px);
    padding: 5px 20px;
}

.outlet .homebody .row {
    margin: 0 !important;
}

.outlet .homebody .col-12 {
    padding: 0 !important;
}

.outlet_body {
    margin-left: 100px;
}

.footermargin {
    margin-top: 500px;
}

.sidenavFixed.sidenav {
    width: 60px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    position: fixed;
    top: 137;
    left: 2;

    width: [Width of the sidenav];
    /* Set the width of the side nav */
    height: 100vh;
    /* 100% of the viewport height */
    z-index: 100;
    overflow-y: auto;
}

.sidenav-icon.active {
    background-color: #714BDC;
    border-radius: 100%;
    z-index: -1;
  
    
  }

.sidenav-icon.active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /* background-color: #714BDC; */
    z-index: -1;
}

.logout-option {
    color: #ffffff;
    top: 100%;
    position: absolute;
    background-color: rgb(126, 38, 228);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    z-index: 1000;
    font-family: Inter;
    font-weight: 600;
    border: none;
}

.profile-container {
    margin-right: 25px;
    /* background-color: #3A3A3A; */
    border-radius: 50%;
}

.profile_image {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    object-fit: cover;
    border: none;
}

.topnav_color {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 15px;
}

.searchbtn {
    width: 596px;
    height: 44px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    margin-right: 300px;
    border: 2px solid white;
    background-color: black;
    color: white;
    padding: 10px 20px;
}

.searchbtn input[type="text"] {
    border: none;
    background: none;
    color: white;
    outline: none;
    width: 450px;
    padding-left: 20px;
}

.search-icon {
    width: 20px;
    height: 20px;
}

.profileimg {
    border-radius: 50%;
    width: 43px;
    height: 43px;
    padding: 10px;
    color: #000000;
    border-radius: 53px;
    background-color: #FFF;
    border: 1px solid #FFF;
    justify-content: center;
    text-align: center;
    font-weight: 800;
    margin-right: 20px;
    cursor: pointer;
}

.sidenav {
    width: 110px;
    height: auto;
    padding-top: 40px;
}

.sidenav-icon {
    position: relative;
    display: inline-block;
    padding: 15px;
    margin-bottom: 15px;
    ;
}

.sidenav-icon img {
    width: 100%;
}

.sidenav-icon::before {
    /* content: ''; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.sidenav-icon:hover {
    background-color: #714BDC;
    border-radius: 55px;

}

@media (min-width: 2560px) {
    .topnav_color {
        background-color: #000000;
        justify-content: space-between;
        height: 102px;
        width: 2560px;
        padding: 30px;
        border-bottom: 1px solid #3A3A3A;
    }
}


.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
    flex: 1;
    /* This allows the content to expand and push the footer downward */
}

.tm-logo-mobile {
    display: none;
}

@media (min-width: 320px) and (max-width: 374px) {

    .sidenavFixed.sidenav {
        bottom: 0px;
        flex-direction: row;
        width: 100%;
        background-color: #131313;
        height: 150px;
        align-items: center;
        justify-items: center;
        justify-content: space-around;
    }

    .outlet_body {
        margin-left: 9px;
    }

    .tm-logo {
        display: none;
    }

    .tm-logo-mobile {
        display: block;
        /* Show the mobile logo */
        width: 120px;
        height: 120px;
    }

    .profile_image {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: none;
        /* background-color: #000000; */

    }

    .topnav_color {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .fixedNav {
        position: fixed;
        background-color: #131313;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #3A3A3A;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        padding: 25px;

    }

    .searchbtn {
        width: 50%;
        height: 95px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        margin-right: 00px;
        border: 2px solid white;
        background-color: #131313;
        color: white;
        padding: 10px 20px;
    }

    .searchbtn input[type="text"] {
        border: none;
        background: none;
        color: white;
        outline: none;
        width: 450px;
        font-size: 45px;
        /* padding-left: 20px; */
    }

    .search-icon {
        width: 40px;
        height: 40px;
    }

    .logout-option {
        color: #ffffff;
        top: 100%;
        position: absolute;
        background-color: rgb(126, 38, 228);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        padding: 8px 16px;
        cursor: pointer;
        z-index: 1000;
        font-family: Inter;
        font-weight: 600;
        border: none;
        font-size: 45px;

    }



    .sidenav-icon {
        position: relative;
        display: inline-block;
        padding: 20px;
        margin-bottom: 15px;
        ;
    }

    .sidenav-icon img {
        width: 100%;
        width: 80px;
        height: 80px;
    }

    .sidenav-icon::before {
        /* content: ''; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .sidenav-icon:hover {
        background-color: #714BDC;
        border-radius: 55px;

    }

    


}

@media (min-width: 375px) and (max-width: 424px) {

    .sidenavFixed.sidenav {
        bottom: 0px;
        flex-direction: row;
        width: 100%;
        background-color: #131313;
        height: 150px;
        align-items: center;
        justify-items: center;
        justify-content: space-around;
    }

    .outlet_body {
        margin-left: 30px;
    }

    .tm-logo {
        display: none;
    }

    .tm-logo-mobile {
        display: block;
        /* Show the mobile logo */
        width: 120px;
        height: 120px;
    }

    .profile_image {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: none;
        /* background-color: #000000; */

    }

    .topnav_color {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .fixedNav {
        position: fixed;
        background-color: #131313;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #3A3A3A;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        padding: 25px;

    }

    .searchbtn {
        width: 50%;
        height: 95px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        margin-right: 00px;
        border: 2px solid white;
        background-color: black;
        color: white;
        padding: 10px 20px;
    }

    .searchbtn input[type="text"] {
        border: none;
        background: none;
        color: white;
        outline: none;
        width: 450px;
        font-size: 45px;
        /* padding-left: 20px; */
    }

    .search-icon {
        width: 40px;
        height: 40px;
    }

    .logout-option {
        color: #ffffff;
        top: 100%;
        position: absolute;
        background-color: rgb(126, 38, 228);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        padding: 8px 16px;
        cursor: pointer;
        z-index: 1000;
        font-family: Inter;
        font-weight: 600;
        border: none;
        font-size: 45px;

    }



    .sidenav-icon {
        position: relative;
        display: inline-block;
        padding: 20px;
        margin-bottom: 15px;
        ;
    }

    .sidenav-icon img {
        width: 100%;
        width: 80px;
        height: 80px;
    }

    .sidenav-icon::before {
        /* content: ''; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .sidenav-icon:hover {
        background-color: #714BDC;
        border-radius: 55px;

    }

}
@media (max-width:391px){

    .sidenavFixed.sidenav {
        bottom: 0px;
        flex-direction: row;
        width: 100%;
        background-color: #131313;
        height: 150px;
        align-items: center;
        justify-items: center;
        justify-content: space-around;
    }

    .outlet_body {
        margin-left: 9px;
    }

    .tm-logo {
        display: none;
    }

    .tm-logo-mobile {
        display: block;
        /* Show the mobile logo */
        width: 120px;
        height: 120px;
    }

    .profile_image {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: none;
        /* background-color: #000000; */

    }

    .topnav_color {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .fixedNav {
        position: fixed;
        background-color: #131313;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #3A3A3A;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        padding: 25px;

    }

    .searchbtn {
        width: 50%;
        height: 95px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        margin-right: 00px;
        border: 2px solid white;
        background-color: #131313;
        color: white;
        padding: 10px 20px;
    }

    .searchbtn input[type="text"] {
        border: none;
        background: none;
        color: white;
        outline: none;
        width: 450px;
        font-size: 45px;
        /* padding-left: 20px; */
    }

    .search-icon {
        width: 40px;
        height: 40px;
    }

    .logout-option {
        color: #ffffff;
        top: 100%;
        position: absolute;
        background-color: rgb(126, 38, 228);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        padding: 8px 16px;
        cursor: pointer;
        z-index: 1000;
        font-family: Inter;
        font-weight: 600;
        border: none;
        font-size: 45px;

    }



    .sidenav-icon {
        position: relative;
        display: inline-block;
        padding: 20px;
        margin-bottom: 15px;
        ;
    }

    .sidenav-icon img {
        width: 100%;
        width: 80px;
        height: 80px;
    }

    .sidenav-icon::before {
        /* content: ''; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .sidenav-icon:hover {
        background-color: #714BDC;
        border-radius: 55px;
    }

    

}

@media (min-width: 425px) and (max-width: 767px) {

    .sidenavFixed.sidenav {
        bottom: 0px;
        flex-direction: row;
        width: 100%;
        background-color: #131313;
        height: 150px;
        align-items: center;
        justify-items: center;
        justify-content: space-around;
        left: 0px;
    }

    .outlet_body {
        margin-left: 30px;
    }

    .tm-logo {
        display: none;
    }

    .tm-logo-mobile {
        display: block;
        /* Show the mobile logo */
        width: 120px;
        height: 120px;
    }

    .profile_image {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: none;
        /* background-color: #000000; */

    }

    .topnav_color {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .fixedNav {
        position: fixed;
        background-color: #131313;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #3A3A3A;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        padding: 25px;

    }

    .searchbtn {
        width: 50%;
        height: 95px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        margin-right: 00px;
        border: 2px solid white;
        background-color: black;
        color: white;
        padding: 10px 20px;
    }

    .searchbtn input[type="text"] {
        border: none;
        background: none;
        color: white;
        outline: none;
        width: 450px;
        font-size: 45px;
        /* padding-left: 20px; */
    }

    .search-icon {
        width: 30px;
        height: 30px;
    }

    .logout-option {
        color: #ffffff;
        top: 100%;
        position: absolute;
        background-color: rgb(126, 38, 228);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        padding: 8px 16px;
        cursor: pointer;
        z-index: 1000;
        font-family: Inter;
        font-weight: 600;
        border: none;
        font-size: 45px;

    }



    .sidenav-icon {
        position: relative;
        display: inline-block;
        padding: 20px;
        margin-bottom: 15px;
        ;
    }

    .sidenav-icon img {
        width: 100%;
        width: 80px;
        height: 80px;
    }

    .sidenav-icon::before {
        /* content: ''; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .sidenav-icon:hover {
        background-color: #714BDC;
        border-radius: 55px;

    }

    .desk-show {
        display: block;
    }

    .desk-hide {
        display: none;
    }

}

/*  CSS */


@media (max-width:1200px) {
    .outlet .homebody {
        width: calc(100% - 60px);
        padding: 5px 0px;
    }
}

@media (max-width:1024px) {
    #root {
        width: 1520px;
        height: 100%;
    }

    .outlet .homebody {
        width: calc(100% - 60px);
        padding: 5px 0px;
    }

    .outlet {
        display: inline !important;
    }



    .searchbtn {
        margin-right: 0px;
    }

    .home-body {
        width: 100% !important;
        display: inline !important;
    }

    .site-container {
        min-height: auto;
    }

    .body-margin-container {
        border: none;
        border-right: none !important;
    }

    .Holdings {
        width: auto;
    }

    .createbtn {
        width: 97% !important;
    }

    .searchbtn input[type="text"],
    .searchbtn {
        width: auto;
    }

    .searchbtn {
        width: auto;
    }

    .desk-hide {
        display: block;
    }

    .desk-show {
        display: none;
    }

}

@media (max-width:991px) {
    .landing-pg-bgs {
        width: 1540px;
    }

    .popular-and-trending {
        display: none;
    }

    .searchbtn input[type="text"] {
        font-size: 25px;
    }

    .searchbtn {
        height: 64px;
    }
    .PopularTrips-container {
        grid-template-columns: repeat(3, 432px);
    }
}


@media (max-width:768px) {

    .searchbtn input[type="text"],
    .searchbtn {
        width: auto;
    }

   

    .cardmain {
        width: 652px;
    }

    .comments input[type="text"] {
        width: 94% !important;
    }

    .topnav_color {
        justify-content: space-between;
        align-items: center;
        width: 100% !important;
        max-width: none;
    }

    .fixedNav {
        /* width: 768px; */
    }

    .landing-pg-bgs .landing-d-gride {
        margin-right: 0px !important;
        display: grid !important;
    }

    .outlet {
        display: inline !important;
    }

    .see-more-button span {
        font-size: 24px;
    }
}

@media (max-width:765px) and (min-width:373px) {
    .landing-pg-bgs {
        width: 1540px;
    }

 

}


@media (max-width:500px) {
    .see-more-button span {
        font-size: 45px !important;
    }

    .landing-pg-bgs {
        width: 1540px !important;
    }
    .searchbtn {
        height: 84px;
    }

    .searchbtn input[type="text"] {
        font-size: 44px;
    }

    .fixedNav {
        height: 210px;
    }

    .outlet_body {
        margin-top: 20px;
    }

    .outlet {
        margin: 216px auto 0px auto;
    }
    .PopularTrips-container {
        grid-template-columns: repeat(3, 473px);
    }
}


@media (max-width:425.9px) {
    .sidenavFixed.sidenav {
        bottom: 0px !important;
    }
    .landing-pg-bgs {
        /* width: 1710px !important; */
    }

}




@media (max-width:375px) {
    .outlet .homebody {
        width: 1240px !important;
        padding: 0px 0px !important;
    }

    .home-body {
        width: 1240px !important;
        display: flex !important;
    }

    .landing-pg-bgs {
        /* width: 100% !important; */
    }
}

