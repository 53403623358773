@media (min-width: 320px) and (max-width: 374px) {
 
 
.body-margin-container{
    padding-Right: 5px;
    border-Right: 1px solid #3A3A3A;  
    margin-left:0px;
    column-width: 1500px;
   
}
.popular-and-trending{
    display: none !important;
}
 
#root {
    width: 1280px
}

.popular_and_trending{
    display: none !important;

}
 
}
 
@media (min-width: 375px) and (max-width: 424px) {
    .body-margin-container{
        padding-Right: 5px;
        border-Right: 1px solid #3A3A3A;  
        margin-left:0px;
        column-width: 1500px;
       
    }
    .popular-and-trending{
        display: none !important;
    }
   
    #root {
        width: 100px
    }
}


 


/*  CSS */

@media (max-width:1200px) and (min-width:991px){
    .pro-user-name{
        font-size: 32px;
    }
}