.box {
    height: 791px;
    width: 100%;
    background: linear-gradient(180deg, rgba(39, 39, 39, 1) 0%, rgba(39, 39, 39, 0.85) 6%, rgba(39, 39, 39, 0.75) 9%, rgba(39, 39, 39, 0.55) 13%, rgba(39, 39, 39, 0.25) 19%, rgba(39, 39, 39, 0.125) 22%, rgba(39, 39, 39, 0) 25%),linear-gradient(284deg, #D2C2FF 14.7%, rgba(210, 194, 255, 0.00) 87.24%), #FFF; 
}

.box .rectangle {
    width: 1520px;
    padding: 125px;
    height: 791px;
    display: flex;
    left: 0;
    top: 0;
    margin: 0 auto;
}


/* .layout1a{
    width: 550px;
    height: 850px;
    margin-top: 355px;
}
.layout1b{
    width: 550px;
    height: 850px;
    margin-top: 45px;
} */

.explore {
    display: flex;
    text-align: center;
    font-family: Inter;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
}

.explorecard {

    width: 617px;
    height: 675px;
    flex-shrink: 0;
    border-radius: 15px;
    text-align: center;
    font-family: Inter;


    margin-left: 110px;
    background: linear-gradient(180deg, #FF6C6C 0%, #FFB992 100%), #FF6C6C;
    box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
}

.explorecard div {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 544px; */
    height: 54px;
    flex-shrink: 0;
    margin-top: 70px;
}

.explorecard p {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 445px;
    margin-left: 95px;
    margin-top: 5px;
    height: 77px;
    flex-shrink: 0;
}

.explorecard img {
    margin-top: 35px;
    margin-left: 20px;
}



.Videoshow {

    width: 617px;
    height: 675px;
   
    flex-shrink: 0;
    border-radius: 15px;
    /* display: flex; */
    margin-left: 110px;
    border-radius: 15px;
    background: linear-gradient(180deg, #4EB2EA 0%, #D0E3FF 100%), #4EB2EA;

}

.Videoshow div {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 544px; */
    height: 54px;
    flex-shrink: 0;
    margin-top: 45px;
   
}

.Videoshow p {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 445px;
    margin-top: 25px;
    margin-left: 75px;
}

.Videoshow img {
    margin-top: 28px;
    text-align: center;
    margin-left: 35px;

}

.AIItineraries {

    width: 617px;
    height: 675px;
    flex-shrink: 0;
    margin-left: 55px;
    border-radius: 15px;
    background: linear-gradient(180deg, #5EBB72 0%, #B7FFC2 100%);
    box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
}

.AIItineraries div {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 54px;
    flex-shrink: 0;
    margin-top: 45px;
}

.AIItineraries p {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 77px;
    width: 471px;
    margin-left: 75px;
    margin-top: 25px;
    flex-shrink: 0;
}

.AIItineraries img {
    margin: 40px;

}

.createcard {
    width: 617px;
    height: 675px;
    flex-shrink: 0;
    margin-left: 55px;
    border-radius: 15px;
    background: linear-gradient(180deg, #714BDC 0%, #CE9EFF 100%), #714BDC;
    box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
}

.createcard div {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 544px; */
    height: 54px;
    flex-shrink: 0;
    margin-top: 68px;
}

.createcard p {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 445px;
    margin-left: 95px;
    margin-top: 5px;
    height: 77px;
    flex-shrink: 0;
}

.createcard img {
    margin-top: 45px;
}


.rectangle div {
    position: relative;
}

.text-overlay {
    position: absolute;
   
    top: 72px;
    left: 8%;
    color: #272727;
    width: 351px;
    height: 171px;
    flex-shrink: 0;

    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.text-overlay button {
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 49px;
    margin-top: 30px;
    border: none;
    background: #272727;
    width: 197px;
    height: 55px;
    flex-shrink: 0;
}



.explorecard .image-with-text {
    position: relative;
    display: inline-block;
}

.explorecard .image-with-text img {
    width: 100%;
    display: block;
}

.explorecard .overlay-text {
    position: absolute;
    top: 95px;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 544px;
    height: 54px;
    flex-shrink: 0;
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.explorecard .overlay-text span {
    position: absolute;
    top: 95px;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 544px;
    height: 54px;
    flex-shrink: 0;
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}



.help-container {
    background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
    border-radius: 15px;
    width: 1300px; 
    margin: auto;  
    text-align: center;
    font-family: Inter;
    color: #FFF;
    padding: 20px;
    /* justify-content: center;  
    align-items: center;  */
 }

.help-card p{
    flex: 1;  
    padding: 20px; 
    /* margin: 0 20px;  */
    box-sizing: border-box;   
    font-size: 28px; 
    width: 100%;

}

.help-card h2, .help-card p {
    margin: 10px 0;  
}


@media (min-width: 320px) and (max-width: 374px) {
    .explore {
        flex-direction: column;  /* Changes layout to single column */
        align-items: center;    /* Centers the cards in the container */
    }
    
    .explorecard, .Videoshow, .AIItineraries, .createcard {
        margin-left: 0;         
        margin-bottom: 20px;    
        width: 100%;
    }
    .box {
        height: 2700px;
        width: 100%;
        background: linear-gradient(180deg, rgba(39, 39, 39, 1) 0%, rgba(39, 39, 39, 0.85) 6%, rgba(39, 39, 39, 0.75) 9%, rgba(39, 39, 39, 0.55) 13%, rgba(39, 39, 39, 0.25) 19%, rgba(39, 39, 39, 0.125) 22%, rgba(39, 39, 39, 0) 25%),linear-gradient(284deg, #D2C2FF 14.7%, rgba(210, 194, 255, 0.00) 87.24%), #FFF; 
    }

     .rectangle {
        width: 1520px;
        padding: 125px;
          /* height: 1091px; */
        flex-direction: column;
        left: 0;
        right: 0;
        top: 0;
        margin-right: 45px;
        align-items: center;
        height: auto;
        
        /* margin: 0 auto; */
    }

    .layout1a{
        width: 1250px;
        height: 1250px;
        margin-top: 155px;
    }
    .layout1b{
        width: 1250px;
       
        height: 1250px;
        margin-top: 45px;
    }
    .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 700px; 
        margin: auto;  
        text-align: center;
        font-family: Inter;
        color: #FFF;
        padding: 20px;
        justify-content: center;  
        align-items: center; 
        margin-bottom: 45px;
     }
    
    .help-card p{
        flex: 1;  
        padding: 20px; 
        /* margin: 0 20px;  */
    
        box-sizing: border-box;  
       
    
    }
    
    .help-card h2, .help-card p {
        margin: 10px 0;  
    }

    .help-image{
        width:600px;
        height:250px;
    }


    .explore {
        display: flex;
        /* text-align: center; */
        font-family: Inter;
        width: 100%;
        /* max-width: 20px; */
        margin: 0 auto;
        
    }
    
    .explorecard {
        /* left: 0; */
        width: 1200px;
        height: 1375px;
        flex-shrink: 0;
        border-radius: 15px;
        /* text-align: center; */
        font-family: Inter;
        /* left: 0; */
        margin-left: -55px;
        /* margin-right: 45px; */
        margin-bottom: 95px;
    
    
        /* margin-left: 110px; */
        background: linear-gradient(180deg, #FF6C6C 0%, #FFB992 100%), #FF6C6C;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .explorecard div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        margin-top: 70px;
        padding: 25px;
    }
    
    .explorecard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        /* width: 5; */
        font-size: 50px;

        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 675px;
        /* left:3; */
        margin-left: 250px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .explorecard img {
        margin-top: 195px;
        width: 1000px;
        height: 800px;
        /* margin-left: 20px; */
    }

    .explorecard .image-with-text {
        position: relative;
        display: inline-block;
        /* Or 'block' depending on your layout */
    }
    
    .explorecard .image-with-text img {
        width: 100%;
        /* This ensures the image covers the container */
        display: block;
        /* To remove any space below the image */
    }
    
    .explorecard .overlay-text {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 50px;

        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .explorecard .overlay-text span {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .createcard {

        width: 1200px;
        height: 1375px;
        margin-bottom: 55px;

        flex-shrink: 0;
        border-radius: 15px;
        /* text-align: center; */
        font-family: Inter;
        /* left: 0; */
        margin-left: -55px;
        /* margin-right: 45px; */
       
        border-radius: 15px;
        background: linear-gradient(180deg, #714BDC 0%, #CE9EFF 100%), #714BDC;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .createcard div {
        /* color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
        height: 54px;
        flex-shrink: 0;
        margin-top: 68px; */

        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        padding: 20px;
        margin-top: 70px;
    }
    
    .createcard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 50px;

        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 150px;
        margin-top: 155px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .createcard img {
        margin-top: 185px;
        width: 1200px;
    }

    .Videoshow {

        width: 1200px;
        height: 1375px;
        flex-shrink: 0;
        margin-bottom: 75px;

        border-radius: 15px;
        /* text-align: center; */
        font-family: Inter;
        /* left: 0; */
        margin-left: -35px;
        margin-right: 45px;
        border-radius: 15px;
        background: linear-gradient(180deg, #4EB2EA 0%, #D0E3FF 100%), #4EB2EA;
    
    }
    
    .Videoshow div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
       
    }
    
    .Videoshow p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    justify-content: center;
    align-items: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        /* margin-left: 130px; */
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .Videoshow img {
        /* margin-top: 28px;
        text-align: center;
        margin-left: 35px; */

        margin-top: 155px;
        width: 1200px;
    
    }

    .AIItineraries {

        width: 1200px;
        height: 1375px;
        flex-shrink: 0;
        margin-bottom: 55px;

        border-radius: 15px;
        /* text-align: center; */
        font-family: Inter;
        /* left: 0; */
        margin-left: -95px;
        /* margin-right: 45px; */
        border-radius: 15px;

        background: linear-gradient(180deg, #5EBB72 0%, #B7FFC2 100%);
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .AIItineraries div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
    }
    
    .AIItineraries p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 140px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .AIItineraries img {
        margin: 40px;
        margin-left: 65px;
        margin-top: 205px;
        width: 1200px;
    
    }

    .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 1200px;
        height: 905px;
        flex-shrink: 0;
        border-radius: 15px;
        font-family: Inter;        
        border-radius: 15px;
        text-align: center;
        font-family: Inter;
        color: #FFF;
        padding: 20px;
        justify-content: center;  
        /* margin-left: -1px; */
        margin-left: 105px;
        align-items: center; 
     }
    
    .help-card p{
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 100px;
        margin-top: 25px;
        height: 77px;
        flex-shrink: 0;
    
        box-sizing: border-box;  
       
    
    }
    
    .help-card h2 {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        /* height: 94px; */
        flex-shrink: 0;
        margin-top: 45px;
        margin: auto 22%;
        padding: 145px 0px 0px;
    }

    .help-image{
        margin: 40px;
        /* margin-left: 45px; */
        margin-top: 185px;
        width: 900px;
        height: 400px;
    }


}

@media (min-width: 375px) and (max-width: 424px) {

  
    .explore {
        flex-direction: column;  /* Changes layout to single column */
        align-items: center;    /* Centers the cards in the container */
    }
    
    .explorecard, .Videoshow, .AIItineraries, .createcard {
        margin-left: 0;         
        margin-bottom: 20px;    
        width: 90%;
    }
    .box {
        height: 2700px;
        width: 100%;
        background: linear-gradient(180deg, rgba(39, 39, 39, 1) 0%, rgba(39, 39, 39, 0.85) 6%, rgba(39, 39, 39, 0.75) 9%, rgba(39, 39, 39, 0.55) 13%, rgba(39, 39, 39, 0.25) 19%, rgba(39, 39, 39, 0.125) 22%, rgba(39, 39, 39, 0) 25%),linear-gradient(284deg, #D2C2FF 14.7%, rgba(210, 194, 255, 0.00) 87.24%), #FFF; 
    }

    .rectangle {
        width: 1520px;
        padding: 125px;
          /* height: 1091px; */
        flex-direction: column;
        left: 0;
        right: 0;
        top: 0;
        margin-right: 45px;
        align-items: center;
        height: auto;
        
        /* margin: 0 auto; */
    }

    .layout1a{
        width: 1250px;
        height: 1250px;
        margin-top: 155px;
    }
    .layout1b{
        width: 1250px;
       
        height: 1250px;
        margin-top: 45px;
    }
    

    .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 700px; 
        margin: auto;  
        text-align: center;
        font-family: Inter;
        color: #FFF;
        padding: 20px;
        justify-content: center;  
        align-items: center; 
     }
    
    .help-card p{
      
        margin-top: 154px !important;
    
    }
    
    .help-card h2, .help-card p {
        margin: 10px 0;  
    }

    .help-image{
        width:600px;
        height:250px;
    }


    .explore {
        display: flex;
        text-align: center;
        font-family: Inter;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        /* margin-bottom: 95px; */
    }
    
    .explorecard {
    
        width: 1400px;
        height: 1275px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;
        margin-bottom: 85px;
     /* margin-left: 50px; */
    
        /* margin-left: 110px; */
        background: linear-gradient(180deg, #FF6C6C 0%, #FFB992 100%), #FF6C6C;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .explorecard div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        margin-top: 70px;
    }
    
    .explorecard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        /* width: 5; */
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 875px;
        /* left:3; */
        margin-left: 260px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .explorecard img {
        margin-top: 185px;
        width: 1300px;
        height: 700px;
        margin-left: 20px;
    }

    .explorecard .image-with-text {
        position: relative;
        display: inline-block;
        /* Or 'block' depending on your layout */
    }
    
    .explorecard .image-with-text img {
        width: 100%;
        /* This ensures the image covers the container */
        display: block;
        /* To remove any space below the image */
    }
    
    .explorecard .overlay-text {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .explorecard .overlay-text span {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .createcard {

        width: 1400px;
        height: 1405px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;
        margin-bottom: 45px;
        /* margin-left: -50px; */
        border-radius: 15px;
        background: linear-gradient(180deg, #714BDC 0%, #CE9EFF 100%), #714BDC;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .createcard div {
        /* color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
        height: 54px;
        flex-shrink: 0;
        margin-top: 68px; */

        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        margin-top: 70px;
    }
    
    .createcard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .createcard img {
        margin-top: 155px;
        width: 1400px;
    }

    .Videoshow {

        width: 1400px;
        height: 1500px;
        flex-shrink: 0;
        /* margin-left: -50px; */
        border-radius: 15px;
        text-align: center;
        font-family: Inter;       
        margin-bottom: 55px;
        flex-shrink: 0;
        border-radius: 15px;
        background: linear-gradient(180deg, #4EB2EA 0%, #D0E3FF 100%), #4EB2EA;
    
    }
    
    .Videoshow div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
       
    }
    
    .Videoshow p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .Videoshow img {
        /* margin-top: 28px;
        text-align: center;
        margin-left: 35px; */

        margin-top: 155px;
        width: 1400px;
    
    }

    .AIItineraries {

        width: 1400px;
        height: 1450px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;  
        /* margin-left: -50px;   */
        margin-top: 25px;   
        margin-bottom: 55px;
        flex-shrink: 0;
        border-radius: 15px;

        background: linear-gradient(180deg, #5EBB72 0%, #B7FFC2 100%);
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .AIItineraries div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
    }
    
    .AIItineraries p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .AIItineraries img {
        margin: 40px;
margin-left: 65px;
        margin-top: 145px;
        width: 1400px;
    
    }

    .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 1400px; 
        height: 1000px;
        margin: auto;  
        text-align: center;
        font-family: Inter;
        /* margin-left: 0px; */
        color: #FFF;
        padding: 20px;
        justify-content: center;  
        align-items: center; 
     }
    
    .help-card p{
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 1105px;
        margin-left: 130px;
        margin-top: 25px;
        height: 77px;
        flex-shrink: 0;
    
        box-sizing: border-box;  
       
    
    }
    
    .help-card h2 {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin: auto 22%;
        padding: 145px 0px 0px;
    }

    .help-image{
        margin: 40px;
        margin-left: 65px;
        margin-top: 145px;
        width: 1200px;
        height: 500px;
    }

    .explorecard p {
        width: 95%;
        margin-left: 0px;
    }


}
@media (min-width:575.25px) and (max-width:767.95px){
    .explorecard{
        margin-left: 0px !important;
    }
    .createcard {
        margin-left: 0px !important;
    }
}

@media (max-width:470px){
    .help-container {
        height: auto;
    }
    .explorecard{
        height: 1275px !important;
        margin-left: 0px !important;
    }
    .createcard {
        height: 1400px !important;
        margin-left: 0px !important;
    }
    .help-card h2 {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
        flex-shrink: 0;
        margin-top: 45px;
        margin: auto 22%;
        padding: 85px 0px 0px;
    }
    .help-card p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        flex-shrink: 0;
        box-sizing: border-box;
        height: auto;
        padding: 55px 65px 0px;
    }
    .help-image {
        margin: 0px !important;
        margin-left: 0px !important;
        margin-top: 145px !important;
        width: 100% !important;
        height: auto !important;
        padding: 61px 20px !important;
    }
}
@media (max-width:320px){
    .help-container {
        height: auto;
    }
}

@media (max-width: 767px) {
    .explore {
        flex-direction: column;  /* Changes layout to single column */
        align-items: center;    /* Centers the cards in the container */
    }
    
    .explorecard, .Videoshow, .AIItineraries, .createcard {
                 
        margin-bottom: 20px;    
        width: 90%;
    }
    .box {
        height: 2700px !important;
        width: 100%;
        background: linear-gradient(180deg, rgba(39, 39, 39, 1) 0%, rgba(39, 39, 39, 0.85) 6%, rgba(39, 39, 39, 0.75) 9%, rgba(39, 39, 39, 0.55) 13%, rgba(39, 39, 39, 0.25) 19%, rgba(39, 39, 39, 0.125) 22%, rgba(39, 39, 39, 0) 25%),linear-gradient(284deg, #D2C2FF 14.7%, rgba(210, 194, 255, 0.00) 87.24%), #FFF; 
    }

    .rectangle {
        width: 1520px;
        padding: 125px;
          /* height: 1091px; */
        flex-direction: column;
        left: 0;
        right: 0;
        top: 0;
        margin-right: 45px;
        align-items: center;
        height: auto;
        
        /* margin: 0 auto; */
    }

    .layout1a{
        width: 1250px !important;
        height: 1250px;
        margin-top: 155px;
    }
    .layout1b{
        width: 1250px !important;
        height: 1250px;
        margin-top: 45px;
    }

    

    .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 700px; 
        margin: auto;  
        text-align: center;
        font-family: Inter;
        color: #FFF;
        padding: 20px;
        justify-content: center;  
        align-items: center; 
     }
    
    .help-card p{
        flex: 1;  
        padding: 20px; 
        /* margin: 0 20px;  */
    
        box-sizing: border-box;  
       
    
    }
    
     .help-card p {
        margin: 10px 0;  
    }

    .help-image{
        width:600px;
        height:250px;
    }


    .explore {
        display: flex;
        text-align: center;
        font-family: Inter;
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        /* margin-bottom: 95px; */
    }
    
    .explorecard {
    
        width: 1400px;
        height: 1275px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;
        margin-bottom: 85px;
    
    
        /* margin-left: 110px; */
        background: linear-gradient(180deg, #FF6C6C 0%, #FFB992 100%), #FF6C6C;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .explorecard div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        margin-top: 70px;
    }
    
    .explorecard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        /* width: 5; */
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 875px;
        /* left:3; */
        margin-left: 260px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .explorecard img {
        margin-top: 185px;
        width: 1300px;
        height: 700px;
        margin-left: 20px;
    }

    .explorecard .image-with-text {
        position: relative;
        display: inline-block;
        /* Or 'block' depending on your layout */
    }
    
    .explorecard .image-with-text img {
        width: 100%;
        /* This ensures the image covers the container */
        display: block;
        /* To remove any space below the image */
    }
    
    .explorecard .overlay-text {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .explorecard .overlay-text span {
        position: absolute;
        top: 95px;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 544px;
        height: 54px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .createcard {

        width: 1400px;
        height: 1405px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;
        margin-bottom: 45px;
        border-radius: 15px;
        background: linear-gradient(180deg, #714BDC 0%, #CE9EFF 100%), #714BDC;
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .createcard div {
        /* color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
        height: 54px;
        flex-shrink: 0;
        margin-top: 68px; */

        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 44px; */
        height: 54px;
        flex-shrink: 0;
        margin-top: 70px;
    }
    
    .createcard p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .createcard img {
        margin-top: 155px;
        width: 1400px;
    }

    .Videoshow {

        width: 1400px;
        height: 1500px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;       
        margin-bottom: 55px;
        flex-shrink: 0;
        border-radius: 15px;
        background: linear-gradient(180deg, #4EB2EA 0%, #D0E3FF 100%), #4EB2EA;
    
    }
    
    .Videoshow div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
       
    }
    
    .Videoshow p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .Videoshow img {
        /* margin-top: 28px;
        text-align: center;
        margin-left: 35px; */

        margin-top: 155px;
        width: 1400px;
    
    }

    .AIItineraries {

        width: 1400px;
        height: 1450px;
        flex-shrink: 0;
        border-radius: 15px;
        text-align: center;
        font-family: Inter;    
        margin-top: 25px;   
        margin-bottom: 55px;
        flex-shrink: 0;
        border-radius: 15px;

        background: linear-gradient(180deg, #5EBB72 0%, #B7FFC2 100%);
        box-shadow: 0px 22px 49px 0px rgba(0, 0, 0, 0.10), 0px 202px 121px 0px rgba(0, 0, 0, 0.05), 0px 359px 144px 0px rgba(0, 0, 0, 0.01), 0px 562px 157px 0px rgba(0, 0, 0, 0.00);
    }
    
    .AIItineraries div {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* width: 544px; */
        height: 94px;
        flex-shrink: 0;
        margin-top: 45px;
    }
    
    .AIItineraries p {
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 945px;
        margin-left: 200px;
        margin-top: 95px;
        height: 77px;
        flex-shrink: 0;
    }
    
    .AIItineraries img {
        margin: 40px;
margin-left: 65px;
        margin-top: 145px;
        width: 1400px;
    
    }

     .help-container {
        background: linear-gradient(to bottom, #FFB932 0%, #FFD98E 100%);
        border-radius: 15px;
        width: 94%;
        margin: auto;
        text-align: center;
        font-family: Inter;
        color: #FFF;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }
    
    .help-card p{
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
    
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 1105px;
        margin-left: 130px;
        margin-top: 25px;
        height: 77px;
        flex-shrink: 0;
    
        box-sizing: border-box;  
       
    
    }
    
   

    .help-image{
        margin: 40px;
        margin-left: 65px;
        margin-top: 145px;
        width: 1200px;
        height: 500px;
    }


}


@media (min-width: 992px) and (max-width: 2500px) {

}

@media(min-width:576px)and (max-width:767px){
    .help-card h2 {      
        font-size: 56px;
        font-style: normal;
        font-weight: 600;      
        padding: 145px 0px 0px;
    }

}

/*  CSS */
@media (max-width:1200px) and (min-width:991px){
    .box {
        width: 1540px;
    }
    
    .explore {
        display: flex;
        text-align: center;
        font-family: Inter;
        width: 100%;
        max-width: 1520px;
        margin: 20px 50px;
    }
    
    .explorecard img, .createcard img {
        margin-top: 35px;
        padding: 20px 20px 35px;
        width: 100%;
    }
    
    .explorecard p,.createcard p {
        width: 100%;
        margin: 0;
        padding: 0px 12px;
    }
    .help-container {
        width: 1300px;
        margin: auto 10%;
    }
    

}

@media (max-width:991px) and (min-width:768px){
    .box {
        height: 791px;
    }

    .layout1a, layout1b {
        width: auto;
    }
    .explorecard, .createcard {
        height: 740px !important;
    }
    
}


