.bucketlist_boards {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
   /* margin-left: 15px; */
}

.boards {
    width: 310px;
    height: 350px;
    flex-shrink: 0;
    position: relative;
    margin: 5px;
}

.boards.newBoard {
    border-radius: 15px;
    background: #272727;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.addNewboard{
    color: #B2B2B2;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #272727;
    border: none;
}


.boards.newBoard button {
    margin: 0;
}

.image_board {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 15px;
}

.board-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    font-size: .8rem;
    border-radius: 50px;
    background: rgba(50, 50, 50, 1);
    backdrop-filter: blur(2px);
    padding: 2px 10px;
    display: inline-block;
    width: fit-content;
    cursor: pointer;
}

.more-options {
    position: absolute;
    top: 10px;
    /* Adjust as necessary */
    right: 10px;
    cursor: pointer;
    /* Adjust as necessary */
}

.bordercard{
    border-radius: 15px 0px 0px 15px;
    background: #714BDC;
    margin-left: 25px;
    width: 25px;
}
.welcome-message {
    position: relative; /* Needed for absolute positioning of children */
    width: 900px;
    height: 291px;
    flex-shrink: 0;
    border-radius: 0px 15px 15px 0px;

    background: #272727;
    color: #FFF; /* Assuming --icon-color is always white for simplicity */
    font-family: Inter;
    margin-left: 25px;
    overflow: hidden; /* Ensures nothing spills out of the border-radius */
    
   
}



.welcome-message .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #FFF;
    font-size: 22px; /* Adjust as per design */
}

.welcome-message div{
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.welcome-message div, .welcome-message p {
    /* padding-left: 25px;  */
    margin:35px
}

.welcome-message p {
    width: 900px;
    /* Adjusted width as per design */
    padding-right: 45px;
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.moreOptionsWrapper {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.more-options + .moreOptionsWrapper.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

/*  CSS */
 
@media (max-width:991px){
    .board-name {
        font-size: 20px;
        padding: 12px 10px ;
    }
    .more-options img{
        width: 66px;
    } 
}

@media (max-width:500px){
    .addNewboard {
        font-size: 33px;
    }
    .more-options img {
        width: 96px;
    }
    .newBoard .addNewboard {
        font-size: 55px !important;
    }
    .newBoard{
        width: 96% !important;
        height: 190px !important;
    }
    .boards {
        margin: 10px;
        height: 546px;
    }
    .board-name {
        font-size: 44px;
      }
   
      
}