.popularContaintpopup {
    margin-top: 70px;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #3A3A3A;
    background: #272727;
}


.iconcontainer {
    position: relative;
    /* This will allow absolute positioning within */
}

.popupmainprofileimg {
    padding: 10px;
    color: white;

}


.popupcard-img-top {
    /* width: 530px;
    height: 440.955px;  */
    width: 100% !important;
    height: 520px !important;
    object-fit: cover;

    border: 1px solid #9b9898;
    border-radius: 5px;
    align-items: center;
    display: block;
    margin: 0 auto;

}

.profilelogoimage {
    border-radius: 100%;
    height: 45px;
    width: 45px;
    border: none;
    margin: 5px;


}
.replies{
    padding-left: 15px;
}
.reply-text{
    padding-left: 25px;
}
.reply-input-container {
    display: flex;
    align-items: center;
  }
 
  .reply-input {
   
    flex-grow: 1;
    padding: 0px 0px 0px 20px;
    color: #FFF;
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 25px;
    background-color: #272727;
  }
 
  .reply-buttons button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 25px;
    cursor: pointer;
  }
 
  .reply-buttons button.reply-button.cancel {
    background-color: #6c757d;
    margin-left: 8px;
  }

.popuprowcomments {
    padding-left: 26.93px;
    margin: 22px 12px 6px 0px;
    color: white;
}




/* right side Comments CSS Started */

.popupprofile {
    height: 60px;

}

.popupDescription {
    /* height: 70px; */
    overflow: hidden;

    border-bottom: 1px solid #3A3A3A;
    margin-bottom: 5px;

    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    font-family: Inter;


}


.placeholder {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    /* White background */
    border-radius: 50%;
    /* Circular shape */
}





.popupcomments {
    width:97% !important;
    height: 45.291px;
    flex-shrink: 0;
    border-radius: 2rem;
    background: #3D3D3D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 10px 20px;
    position: absolute;
    /* top:15px; */
    position: relative;
    bottom: 0;

    /* Adjust distance from the bottom inside the card */

}

.popupcomments input[type="text"] {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    color: #FFFFFF;
    border: none;
    background: none;
    color: white;
    outline: none;
    padding-left: 20px;
    font-family: Inter;

    flex-grow: 1;
    /* Input should grow to fill the space not taken by the button */
    /* margin-right: -40px; */

}

.modelContent {
    background: none !important;
    border: none !important;
}


.model_w1000 {
    --bs-modal-width: 950px !important
}

.popupcommentscontaint {
    height: 420px;
    width: 100% !important;
    margin-top: auto;
    padding-left: 15px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

}

.comment-submit {
    margin-left: 285px;
    background-color: #3A3A3A;
    position: absolute;
    /* Position it over the input field */
    right: 20px;
    /* Adjust to match the desired position */
    height: 30.602px;
    /* Match the input field's height */
    width: 24px;
    /* Width of the button */
    border: none;
    /* Remove border if not needed */
    background-color: transparent;
    /* Make the button transparent */
    cursor: pointer;
    padding: 0;
    outline: none;


}

.comment-submit img {
    width: 100%;
    /* Make the arrow icon take the full width of the button */
    height: auto;
    /* Keep the aspect ratio of the icon */
}



.commentProfileImg {
    margin: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
}

.commentrow {
    height: auto;
    width: 345px;
    background-color: rgb(66, 66, 66);
    margin-bottom: 10px;
    text-align: center;
    border-radius: 15px;
    margin-bottom: 2px;
    /* margin-left: 0.5px; */
    /* padding-left: 3px; */
}

.commentusername {
    width: 219px;
    min-height: 21px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 5px 0px 5px 0px;
    overflow: hidden;
}

.usercomment {
    /* width: 219px; */
    height: 21px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5px 0px 5px 0px;
    overflow: hidden;

}



.replycomment {
    color: #B2B2B2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5px 0px 5px 0px;
}


.comment_timeing {
    float: right;
    width: 150px;
}

.comment_Like {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
}


.postfilter-tag {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: -300px;
}

.postfilter-text {
    font-weight: bold;
    color: white;
}

.postfilter-query-container {
    background-color: #4f0edb;
    /* Background color for the container */
    border-radius: 20px;
    /* Rounded corners for the container */
    display: inline-flex;
    align-items: center;
    padding-right: 5px;
    /* Padding on the right of the container */
}

.postfilter-query {
    padding: 5px 30px;
    /* Padding inside the query span */
    border-radius: 20px 0 0 20px;
    /* Rounded corners on the left side */
    color: white;
    font-weight: bold;
}

.postclear-filter-button {
    border: none;
    background-color: #4f0edb;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px;
    /* Padding inside the button */
    border-radius: 0 20px 20px 0;
    /* Rounded corners on the right side */
    margin-left: -1px;
    /* Negative margin to overlap the borders */
}

.commentpost {
    cursor: pointer;
}

/*  CSS  */
@media (max-width:1440px){
    #staticBackdrop{
        padding-right: 0px !important;
    }
    .popularContaintpopup {
        margin-top: 43px;
    }
}

@media (max-width: 1200px) and (min-width: 991px) {
    #staticBackdrop {
        padding-right: 0px !important;
    }


    .popupcomments {
        margin: 35px 0px;
    }

    .popupcommentscontaint {
        width: 100%;
    }

    .popupDescription {
        font-size: 22px;
    }

    .popupprofile {
        font-size: 36px;
    }

    .commentusername {
        width: 100%;
        font-size: 19px;
    }

    .usercomment {
        width: 100%;
        height: auto;
        font-size: 23px;
    }
}


@media (max-width:1024px) {
    .replycomment button {
        font-size: 18px !important;
    }

    .replycomment button img {
        width: 27px !important;
    }
}


@media (max-width:991px) {
    .location_icon {
        display: none;
    }

    .model_w1000 {
        padding-right: 0px !important;
    }

    .popupDescription {
        padding: 13px 27px;
        font-size: 20px;
    }

    .profilelogoimage {
        margin-right: 22px;
    }

    .popupprofile {
        height: 60px;
        font-size: 22px;
    }

    .popupcommentscontaint {
        width: 95%;
        height: 451px;
        margin-top: 22px;
    }

    .popularContaintpopup {
        margin-top: -48px;
    }

    .popupcomments {
        width: 95%;
        height: auto;
        margin-top: 20px;
        text-align: center;
        margin-left: 20px;
    }

    .popupprofile-tp {
        margin-bottom: 55px;
    }

    .post_location {
        font-size: 1.9em !important;
    }
    .locationpointer{
        width: 45px;
    }

    .comment-submit {
        bottom: 20px
    }

    .comment-submit {
        right: 47px;
        /* height: 25.602px; */
        width: 42px;
    }

    .commentusername {
        width: 100%;
        font-size: 24px;
    }

    .usercomment {
        width: 100%;
        height: auto;
        font-size: 20px;
        padding: 15px 10px;
    }
    .popuprowcomments{
        padding-top: 12px;
    }
    
    .popuprowcomments img{
        width: 40px !important;
        height: 40px !important;
    }
    .popuprowcomments span{
        font-size: 24px !important;
    }
    .popupcomments form{
        font-size:29px ;
    }
}



@media (max-width:500px) {
    .commentusername {
        font-size: 41px;
        line-height: 72px;
        width: 100%;
    }

    .popupprofile {
        height: 101px;
        font-size: 50px;
        margin-bottom: 12px;
    }
    .popupDescription {
        padding: 13px 27px;
        font-size: 39px;
        height: 165px;
    }
    .popupDescription span{
        font-size: 39px;
    }
    .profilelogoimage {
        width: 15vh;
        height: 15vh;
    }
    .popupcard-img-top-container-2 div{
        font-size: 38px !important;
        width: fit-content;
        padding: 0px 20px;
    }
    .comment-profile-img {
        display: flex;
        align-items: center;
    }
    .comment-profile-img  img{
        width: 92px !important;
        height: 92px !important;
    }
    .comment-row{
        padding-top: 15px;
    }
    .usercomment {
        font-size: 40px;
    }
    
    .popupcomments input[type="text"] {
        width: 1230px;
   
    
    }

    .replycomment button {
        font-size: 35px !important;
        padding-bottom: 12px;
    }
    .popuprowcomments img{
        width: 55px !important;
        height: 55px !important;
    }
    .popuprowcomments span{
        font-size: 37px !important;
    }
    .popupcommentscontaint {
        height: 505px ;

    }
    .popupcard-img-top {
        width: 100%;
        margin-top: 139px !important;
        height: 692px;
    }
    .description-popup-content{
        height: 130vh !important;
        margin: 84px 10px 10px !important;
    }
    .popupcomments {
        font-size: 42px;
        margin-top: 42px;
        height: 132px;
        border-radius: 100px;
    }
    .comment-submit {
        right: 47px;
        height: 91.602px;
        width: 92px;
    }
    .popular_popup_closebutton {
        padding: 20px 36px !important;
        font-size: 46px !important;
    }
    .popularContaintpopup {
        margin-top: 16px;
    }
   
    .popularContaintpopup {
        height: 90%;
        margin-top: 16px;
        bottom: 21.5pc;
        position: absolute;
    }
    .popupprofile-tp {
        margin-bottom: 0px !important;
        background: #272727 !important;
        padding-bottom: 50px !important;
    }

    .hm-back-tab-kd .d-flex{
        padding-bottom: 22px;
    }   
    .hm-back-tab-kd span img{
        width: 30px;
    }
    .hm-back-tab-kd h2{
        font-size: 85px;
    }
    .popupcomments form {
        font-size: 44px;
    }
    .reply-buttons button{
        padding: 15px 92px;
        font-size: 45px;
        border-radius: 55px;
    }
    .reply-input{
        padding:25px 25PX 10px ;
        font-size: 45px;
        border-radius: 100px;
        box-sizing: border-box; /* Ensures padding is included in the element's total width and height */
        /* text-align: center; Center align the text */
        align-items: center;
        display: flex;
        /* justify-content: center; */
      
    }
    .reply-input::placeholder {
        /* text-align: center; Center align the placeholder text */
      }
    .reply-input input[type="text"]{
       
    }
    .commenttimestamp{
        font-size: 25px;
        padding-left: 5%;
    }
    .replycomment button img {
        width: 46px !important;
    }
    .comment_likes {
        color: #FFF;
        font-size: 35px;
        margin-left: 15.4px !important;
        margin-right: 12.4px !important;
    }

    .reply-user-profile{
        width: 45px;
        height: 45px;
    }

    .reply-username{
        font-size: 45px;
    }

    .commemts_profile_logo{
        width: 55px !important;
        height: 55px !important;
        border-radius: 50% !important;
    }

    .reply-text {
        font-size: 38px !important;
        margin-left: 45px !important;
        padding: 35px !important;
        font-family: Inter;
    }
   

   
}


@media (max-width:424px){
    .popularContaintpopup{
      bottom: 21.5pc;  
    } 
}

@media (max-width:431px) {
    .popupcommentscontaint {
        height: 830px !important;
    }

    
}

@media (max-width:375px){
    .popularContaintpopup {
        bottom: 22pc !important;
    }
    .popularContaintpopup {
        height: 86.5% !important;
    }
    .popupcard-img-top {
     height: 680px !important;   
    }
    /* .popupcommentscontaint {
        height: 800px !important;
    } */

    .popupcard-img-top {
        height: 802px !important;
    }
    .popupcommentscontaint {
        height: 530px !important;
    }

}

    
@media (max-width: 460px) and (min-width: 424px){
    .popupcommentscontaint {
        /* height: 1200px; */
    }
    .popularContaintpopup {
        /* height: 86.5%; */
    }
    .popupcard-img-top {
        /* height: 1110px; */
    }
    .popupcomments {
        padding: 30px 20px;
    }
    .comment-submit {
        height: 84.604px;
    }
}
@media (max-width:500px){
    .popupcommentscontaint {
        height: 729px !important;
    }
    .popupcard-img-top {
        height: 750px !important;
    }
    
}


/* @media (max-width: 375px) {
    .popupcard-img-top {
        height: 802px !important;
    }
} */