.postFilePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1050;
  width: 950px;
  height: 600px;
  border-radius: 15px;
  border: 1px solid #3A3A3A;
  background: #272727;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;
}

.postImagecard {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 450px;
  background-color: #000;
}


.left-column,
.right-column {
  padding: 20px;
  flex: 1;
  /* Each column takes equal width */
}



.profile-section,
.current_Location {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.profile-logo,
.current_Location img {
  width: 40px;
  /* Adjust size as necessary */
  height: 40px;
  border-radius: 20px;
  /* Circular profile image */
  margin-right: 10px;
}

.postImagecard {
  display: flex;
  position: relative;
  /* This will be the anchor for the absolutely positioned button */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 450px;
  border-radius: 15px;
  background: #131313;
}

.profile-name {
  color: #fff;
}

.file-input-label {
  cursor: pointer;
  background-color: hsla(0, 0%, 0%, 0.595);
  /* Example button color */
  color: white;
  padding: 3px 9px;
  border-radius: 5px;
  position: absolute;
  /* Position absolutely inside the .postImagecard */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Offset the button by half of its width/height */
  /* The rest of your styles */
}

.selected-popup-image {
  width: 500px;
  height: 450px;
  object-fit: cover;
  /* No changes needed here */
}

.post_input_button {
  width: 344px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 34px;
  background: #3D3D3D;
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding: 9px 20px;
}


.tag {
  display: inline-block;
  background: #f0f0f0;
  /* Tag background */
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

/* .current_Location {
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  border: 1px solid rgb(91, 90, 90);
  width: fit-content;
  padding: 0px 10px;
  border-radius: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
} */

.current_Location img {
  padding: 10px 10px;
}

.remove-tag-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.hash_tag_input {
  border-radius: 64px;
  border: 1px solid #3A3A3A;
  background: #131313;
  color: #B2B2B2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;

}

.vertical-line {
  height: 100%;
  /* Match the height to your content or container */
  width: 1px;
  background-color: rgb(255, 255, 255);
}

.close-popup {
  margin-right: -5px;
}

.board-list {
  margin: 15px;
}
/* .current_Location {
  display: flex;
 
  
} */





.current_Location  {
  display: flex;
  width: 20%;
  padding-left: 25px;
  position: relative;
  padding-bottom: 25px;
  flex-direction: column;
  color: #FFF;
  font-family: Inter;
  font-size: 12px; 
  border: 1px solid rgb(91, 90, 90);
  /* width: 10px;  */
  height: 40px; 
 
  border-radius: 20px; 

  margin-right: 20px;
}
.current_Location1  {
  display: flex;
  width: 90%;
  
  position: relative;
  padding-bottom: 25px;
  flex-direction: column;
  color: #FFF;
  font-family: Inter;
  font-size: 12px; 
  border: 1px solid rgb(91, 90, 90);
  /* width: 10px;  */
  height: 40px; 
 
  border-radius: 20px; 

  margin-right: 20px;
  margin-bottom: 5px;
}

.location_input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  
  outline: none;
  align-items: baseline;
  font-size: 12px; 
  width: fit-content;
  
}
.location_input2{

flex-grow: 1;
  background-color: transparent;
  border: none;
padding: 8px 10px;
  outline: none;
  align-items: baseline;
  font-size: 15px; /* Reduced font size */
  color: #FFF;
}
.location_input3{
  flex-grow: 1;
  background-color: transparent;
  border: none;
padding: 8px 10px;
  outline: none;
  align-items: baseline;
  font-size: 15px; /* Reduced font size */
  color: #FFF;

}

.location_icon img {
  margin-right: -500px; 
  width: 25px; /* Smaller icon */
  height: 25px; 
}
.location_icon img{
  padding: 5px;
}
.location_suggestions {
position: absolute;
top: 100%;
left: 0;
right: 0;
background-color: #fff; /* Dark theme background */
border-radius: 4px;
overflow-y: auto; /* Allows vertical scrolling */
overflow-x: hidden; /* Hides horizontal scrollbar */
max-height: 150px; /* Limit the height and enable scrolling */
box-shadow: 0 2px 5px rgba(0,0,0,0.2);
z-index: 10;
/* padding: 4px; */
font-size: 15px;
}

/* Custom scrollbar styles */
.location_suggestions::-webkit-scrollbar {
width: 4px; /* Scrollbar width */
}

.location_suggestions::-webkit-scrollbar-track {
background: #2b2b2b; /* Scrollbar track color */
border-radius: 2px;
}

.location_suggestions::-webkit-scrollbar-thumb {
background: #6b6b6b; /* Scrollbar handle color */
border-radius: 2px;
}

.location_suggestions::-webkit-scrollbar-thumb:hover {
background: #959595; /* Scrollbar handle hover color */
}
.createnewboardactions {
  cursor: pointer;
  background-color: #aca9a9;
  padding: 5px 9px;
  margin: 15px;
  /* margin-left: 15px; */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 25px;
}

.createnewboardactions button {
  color: hsl(0, 0%, 94%);
  background-color: #aca9a9;
  /* Change background color to match other buttons */
  border: none;
  /* Remove the border */
}

.boardmanagementpopup {

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  position: fixed;
  /* Positioning relative to the viewport */
  top: 58%;
  /* left: ; */
  margin-left: 95px;
  width: 300px;
  height: 200px;
  background-color: #3A3A3A;
  /* display: flex;      */
  justify-content: center;
  color: #fff;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
  margin-top: -30px;
}

.hash_tag_input::placeholder {
  color: #B2B2B2;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
}

.buttons {
  margin-top: auto;
}

.button {
  margin-right: 10px;
  border: none;
  background-color: #272727;
  background-color: none;
  color: #6A6A6A;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.button:hover {
  color: #FFF;
}

.Bucketlist_button {
  border-radius: 55px;
  background: #3D3D3D;
  color: #6A6A6A;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 69px;
  border: none;
  width: 203px;
  height: 43px;
  flex-shrink: 0;
}

.tag {
  border-radius: 64px;
  background: #714BDC;
  padding: 3px 5px;
  flex-shrink: 0;
}


.horizontal-line {
  height: 2px;
  /* Height of the line to make it visible */
  background-color: #fff;
  /* Color of the line */
  width: 100%;
  /* Full width */
  /* position: absolute; 
  bottom: 0; /* At the bottom of the left column */
}

/* ... existing styles ... */

.description-input {
  /* width: 100%; */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-radius: 34px;
  background: #3D3D3D;
  border: none;
  color: #FFF;
}

.tag-input-container {
  /* display: flex;
width:350px;
height:150px; 
background-color: #131313;
overflow-y: scroll;
scrollbar-width: thin;
scrollbar-color: transparent transparent;
border-radius: 15px;
padding-left: 15px; */

  display: flex;
  flex-direction: column;
  /* This ensures that the children are laid out in a column, one below the other */
  width: 362px;
  height: 150px;
  background-color: #424141;
  overflow-y: auto;
  /* 'auto' will only show scrollbar when needed */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /* Consider making this visible to users for usability */
  border-radius: 15px;
  padding: 3px 15px;
  box-sizing: border-box;
  margin-bottom: 5px;

}


.input-field {
  /* Assuming you have a separate class for the input field */
  /* Your input field styling goes here */
  width: 100%;
  /* Make input field full width */
  margin-bottom: 10px;
  /* Add space below the input field for tags to display */
}

.tag-container {
  /* display: flex;
flex-wrap: wrap;
margin-bottom: 10px; */

  /* display: flex;
flex-wrap: wrap;
gap: 5px; 
overflow-x: hidden;  */

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 5px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #714BDC #131313;
  padding-bottom: 5px;

}

.tag {


  background: #714BDC;
  color: white;
  border-radius: 64px;
  padding: 5px 10px;
  height: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.remove-tag-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
}

.description {
  background: #e1e1e1;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 4px;
}

.description_container {
  width: 360px;
  height: 400px;
  background-color: #2c2c2c;
  border-radius: 15px;
  color: #FFF;
  padding: 10px;
  overflow-y: auto;
  /* Enables vertical scrolling */
  scrollbar-width: thin;
  margin-bottom: 15px;

}





/* Assuming previous styles are already defined, here's the addition for the description display */
.description-display {
  background-color: #f0f0f0;
  /* Light grey background */
  border-radius: 4px;
  /* Rounded corners */
  padding: 8px 12px;
  /* Some padding */
  margin-top: 8px;
  /* Space from the top element */
  font-size: 14px;
  /* Font size */
  color: #fff;
}

.Bucketlist_button {
  border-radius: 55px;
  background:  #131313;
  color: var(--icon-color, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Highlighted button style */
.Bucketlist_button.highlighted {
  border-radius: 55px;
  background: #714BDC;
  color: var(--icon-color, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*  CSS */



@media (max-width:1200px) {
  .postFilePopup {
    height: auto;
  }

  .postFilePopup {
    top: 47%;
  }

  .current_Location img {
    margin-right: 0px;
  }
  .description_container {
    width: 360px;
    height: 180px;
  }
  .tag-input-container {
    height: 105px;
  }
  .current_Location img {
    width: 33px;
    height: 34px;
  }

}

@media (max-width:991px) {

  .file-input-label,
  .profile-name {
    font-size: 20px;
  }

  .profile-section {
    padding-bottom: 15px;
  }

  .current_Location {
    margin-bottom: 15px;
  }

  .postFilePopup {
    width: 92%;
  }

  .postFilePopup {
    top: 50%;
  }

  .postImagecard {
    width: 100%;
  }

  .description_container {
    width: 100%;
    height: 245px;
  }

  .tag-input-container {
    width: 100%;
  }

  .Bucketlist_button {
    color: #fff;
    font-size: 24px;
  }

  .current_Location {
    font-size: 18px;
  }

  .profile-name {
    font-size: 36px;
  }
  .postFilePopup {
    height: 61%;
}
.Bucketlist_button {
  margin-left: 0px;
  width: 100%;
  height: 54px;
}
.Bucketlist_button.highlighted {
  font-size: 24px;
}

}

@media (max-width:500px) {
  .postFilePopup {
    display: grid !important;
}
.description_container {
  width: 100%;
  height: 125px;
  font-size: 41px;
  padding: 12px 12px 12px 30px;
}

.tag-input-container {

    height: 200px;

}

  .file-input-label {
    font-size: 30px;
  }

  .newNotePopupActions button {
    font-size: 39px;
  }

  .newNotePopupActions button img {
    width: 44px;
  }

  .profile-name {
    font-size: 55px;
  }

  .profile-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

    .current_Location {
      font-size: 40px;
      padding: 0px 10px;
      border-radius: 43px;
    }
  
    .current_Location img {
      width: 47px;
      height: 47px;
    }
  
    .postImagecard {
      height: 850px;
      background: #131313;
    }
  
    .postFilePopup {
      height: 85%;
      width: 97%;
    }
.selected-popup-image {
  width: 1440px;
  height: 835px;
}

.current_Location  {
  
  width: 22%;
 
  height: 120px; 
 
 
}

.close-popup {
  /* top: 50px; */
  /* right: 50px; */
  padding: 7px 22px;
  font-size: 51px;
}

.description-input {
  font-size: 52px;
}

.hash_tag_input {
  font-size: 43px !important;
}

  .Bucketlist_button {
    color: #fff;
    font-size: 48px;
    width: auto;
    height: 98px;
    margin: 15px 0px;
}

.location_input2{

  flex-grow: 1;
    background-color: transparent;
    border: none;
  padding: 8px 10px ;

    outline: none;
    align-items: baseline;
    font-size: 51px; /* Reduced font size */
    color: #FFF;
    height: 140px;
  }
  .current_Location1  {
    display: flex;
    width: 90%;
    
    position: relative;
    padding-bottom: 25px;
    flex-direction: column;
    color: #FFF;
    font-family: Inter;
    font-size: 12px; 
    border: 1px solid rgb(91, 90, 90);
    /* width: 10px;  */
    height:130px;
   
    border-radius: 20px; 
  
    margin-right: 20px;
    margin-bottom: 5px;
  }


}