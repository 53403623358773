.email-heading {
    margin: 50px 0px 25px 50px;
    width: 289px;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.toggle-button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: #007bff;
  }

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .form-control.nameinput {
    width: calc(100% - 50px); /* Adjust the width as needed */
  }
  
  .toggle-button {
    /* position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #FFF;
    padding: 24px; */

    position: absolute;
    right: 35px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #FFF;
    padding: 19px;
  }
  
  
  .form-control.nameinput:focus {
    background-color: #333; /* Ensure background color does not change on focus */
    color: #fff; /* Ensure text color does not change on focus */
    border: 1px solid #007bff; /* Optional: change border color on focus */
    outline: none; /* Remove default focus outline */
  }
  .input-container button{
    color: #FFF;
  }

  .toggle-button .fa-eye,
.toggle-button .fa-eye-slash {
  color: #fff; /* Ensure the icon color matches your desired color */
}




/*  CSS */

@media (max-width:500px) {
    .email-heading {
        width: 1000%;
        font-size: 50px;
    }

    .toggle-button {
      
        padding: 68px;
        font-size: 54px;
      }

  
      
}

/* Responsive Styles */
@media (max-width: 768px) {
    .input-container {
      width: 100%;
    }
  
    .form-control.input-width {
      width: calc(100% - 40px); /* Adjust the width as needed */
    }
  
    .toggle-button {
      right: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .input-container {
      width: 100%;
    }
  
    .form-control.input-width {
      width: calc(100% - 30px); /* Adjust the width as needed */
    }
  
    .toggle-button {
      right: 5px;
    }
  }