.PopularTrips-container {
  display: grid;
  grid-template-columns: repeat(3, 275px); /* 3 columns with fixed width */
  grid-auto-rows:  160px; /* 3 rows with fixed height */

  gap: 15px;
  /* justify-content: center; Center the grid within the container */
}
 
.boxbody {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  /* position: relative; */
}
 
.boxbody img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
 
.see-more-button {
  width: 100%;
  height: 100%;
  background-color: #272c31;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.see-more-button:hover {
  background-color: #3a3a3c;
}

.boxbody .image-title {
    position:relative;
    bottom: 35px;
    left: 10px;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 50px;
    color: #272727;
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px; /* Adjust as necessary */
   
    text-align: left;
    display: inline-block;
    cursor: pointer;
  }

    .comment-row {
      border-radius: 15px;
      background: #3D3D3D;
      padding: 5px;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      margin-bottom: 5px; /* Add this line */
     
    }

.popupcommentscontaint{
  margin-bottom: 5px;
}






/*  CSS */

@media (max-width:991px){
  .boxbody .image-title {
    bottom: 46px;
    font-size: 19px;
  }
}

@media (max-width:500px){
  .boxbody .image-title {
    bottom: 77px;
    font-size: 36px;
}
}



@media (min-width: 375px) and (max-width: 424px) {
  .boxbody, .boxbody img {
    border-radius: 5px; /* Even smaller border radius */
  }

  .image-title {
    font-size: 8px; /* Even smaller font size */
    padding: 3px 6px; /* Even smaller padding */
    bottom: 10px; /* Adjust position even closer to the bottom */
  }

  .see-more-button {
    font-size: 45px; /* Smaller font size for button text */
  }

 
    .PopularTrips-container {
      display: grid;
      border-radius: 25px;
  grid-template-columns: repeat(3, 410px); /* 3 columns with fixed width */
  grid-auto-rows:  450px; /* 3 rows with fixed height */

  gap: 15px;
    
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .boxbody, .boxbody img {
    border-radius: 5px; /* Even smaller border radius */
  }

  .image-title {
    font-size: 8px; /* Even smaller font size */
    padding: 3px 6px; /* Even smaller padding */
    bottom: 10px; /* Adjust position even closer to the bottom */
  }

  .see-more-button {
    font-size: 45px; /* Smaller font size for button text */
  }

 
  .PopularTrips-container {
    display: grid;
    border-radius: 25px;
    grid-template-columns: repeat(3, 400px) !important;
    grid-auto-rows: 350px;
    gap: 15px;
  }
}