/* Popup.css */
.edit_popup_container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_popup {
  width: 250px;
  height: auto;
  background-color: rgb(0, 0, 0, .75);
  backdrop-filter: blur(5px) brightness(60%) contrast(60%);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 15px;
  position: relative;
}

.edit_popup div {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
}

.edit_popup div img {
  height: 20px;
  width: auto;
}

.edit_popup div:hover {
  background: #272727;
}

/*  CSS */

@media (max-width:991px) {
  .edit_popup div {
    font-size: 20px;
  }

  .edit_popup {
    width: 290px;

  }
  .popup-body {
    width: 290px !important;
  }
}

@media (max-width:500px) {
  .edit_popup {
    width: 380px;
  }

  .edit_popup div span {
    font-size: 32px;
  }
 
}