.centercontaint {
  color: white;
}

.rightnav {
  background-color: rgb(0, 0, 0);
}

.filter-tag {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.filter-text {
  font-weight: bold;
  color: white;
}

.filter-query-container {
  background-color: #4f0edb;
  /* Background color for the container */
  border-radius: 20px;
  /* Rounded corners for the container */
  display: inline-flex;
  align-items: center;
  padding-right: 5px;
  /* Padding on the right of the container */

}

.filter-query {
  padding: 5px 30px;
  /* Padding inside the query span */
  border-radius: 20px 0 0 20px;
  /* Rounded corners on the left side */
  color: white;
  font-weight: bold;
}

.clear-filter-button {
  border: none;
  background-color: #4f0edb;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  /* Padding inside the button */
  border-radius: 0 20px 20px 0;
  /* Rounded corners on the right side */
  margin-left: -1px;
  /* Negative margin to overlap the borders */
}

.Moreoptions_overlay {
  position: absolute;
  top: 0;
  right: 6;
  padding-left: 240px;
  bottom: 10;
  left: 1;
}

.MoreOptions {
  width: 30px;
  height: auto;
  cursor: pointer;
  padding-bottom: 20%;
  position: absolute;
  top: 8px;
  right: 8px;

}

.usernamelayer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 157.464px;
  height: 30px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 7px;
  margin-left: 5px;
}

.tittle {
  color: var(--icon-color, #FFF);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Rimg {
  border-radius: 15px;
  background: #272727;
  width: 205px;
  height: 103px;
  flex-shrink: 0;

}

.See_more {
  color: 'white';
  font-weight: 600;
  padding-top: 35px;
  padding-left: 75px;

}

.Rimage-container {
  width: 275px;
  height: 153px;
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 30.39%), lightgray 50% / cover no-repeat;
}

.myfile_img {
  height: '700';
  background-color: black;
  margin: 2px;
  padding-left: 10px
}

.myfile_img {
  width: calc(360px - 0px);
  margin: 60px;
  outline: none;
}

.myfile_img img {
  width: 100%;
  height: auto;
}

.Rimg_size {
  width: 100%;
  height: auto
}

.bucket_tittles {
  width: 117px;
  height: 34px;
  border-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.bucket_tittles.btn-dark:hover {
  background-color: #714BDC;
  border-color: #714BDC;

}

.position-relative {
  position: relative;
  padding-bottom: 20px;
}

.heddername {
  color: #FFF;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  display: block;
}

.publicBoardWrapper,
.myFilesWrapper {
  margin-top: 100px;
}



/*  CSS */

@media (max-width:991px) {
  .bucket_tittles {
    width: 133px;
    height: 44px;
    font-size: 20px;
  }

  .heddername {
    font-size: 37px;
  }
}

@media (max-width:500px) {
  .bucket_tittles {
    width: auto;
    height: 78px;
    font-size: 33px;
    padding: 41px 46px;
  }

  .tittle {
    font-size: 48px;
  }
  .heddername {
    font-size: 58px;
}
}