.profile_heading {
    margin: 50px 0px 25px 50px;
    width: 289px;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
 
.selectedimage{
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
 
 
}
 
.uploadImg {
  margin:5px;
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  fill: #272727;
  stroke-width: 4px;
  stroke: #FFF;
  border: white solid 4px;
}
 
.nameinput {
    margin: 0px 0px 0px 50px;
    width: 543px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 51px;
    background: #272727;
    margin-top: 15px;
    color: #FFF;
    font-weight: 400;
    font-family: Inter;
    font-size: 16px;
    border: none;
}
 
.lablenames {
    margin: 10px 0px 0px 60px;
    width: 297px;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
 
.nameinput::placeholder {
    color: rgb(123, 124, 124);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
 
.savebutton {
    width: 151px;
    height: 49px;
    flex-shrink: 0;
    border-radius: 49px;
    background: #714BDC;
    color: #FFF;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 109px;
    border: none;
    margin-left: 255px;
    margin-top: 15px;
   
}
 
 
.test {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 50px;
    width: 543px;
    /* height: 1px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: #272727;
    margin-top: 15px;
    color: #a6a4a4;
    border: none;
  }
   
  .Profile {
    margin: 10px;
    /* padding-left: 20px; */
    size: 20px;
  }
  .profile-container{
    background: none;
  }
   
  .change-profile-container {
    /* margin-left: auto; */
    margin-top: 20px;
    margin-right: 10px;
    cursor: pointer;
   
    color: white;
    text-align: center;
    background-color: #714BDC;
    width: 250px;
    height: 42px;
    border-radius: 30px;
    border: none;
    padding: 7px 0px 0px 0px;
 
  }
  .change_profile_label{
    cursor: pointer;
  }
   


  /*  CSS */

  @media (max-width:500px){
    .profile_heading {
      width: 1000%;
      font-size: 50px;
  }
  .test {
    width: 100%;
  }
  .change-profile-container {
    width: 75%;
    font-size: 42px;
    height: 73px;
    margin-bottom: 20px;
  }
  .selectedimage {
    width: 85px;
    height: 85px;
  }
  .uploadImg {
    width: 95px;
    height: 95px;
  }
  .savebutton {
    width: 100%;
    font-size: 50px;
    height: 118px;
    padding: 12px 0px;
    margin-left: 49px;
    margin-top: 44px;
  }
  .rightnav {
    border-left: none;
  }
  .nameinput {
    font-size: 32px;
  }
  .nameinput::placeholder {
   font-size: 32px;
  }
  }
 