.loading-spinner {
  position: fixed;
  /* Fixed positioning to keep it in view */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 25%;
  /* margin-left: -25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensures the spinner is above other content */
}

.spinner {
  border: 4px solid #272727;
  /* Light grey/white, semi-transparent */
  border-top: 4px solid #714BDC;
  /* Solid white top */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.filtere_serach {
  display: flex;
  flex-direction: row;
  padding: 10px;
  /* width: fitcontent; */
  /* width: "500px", */
}

.filter_search_diaplay {
  padding: 5px 30px;
  background-color: #714BDC;
  color: #FFF;
  border-radius: 25px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  /* min-width: 100px; */
  width: fit-content;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*  CSS */
.sech-main-d-flex {
  display: flex;
}
.search-x-buttom{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%); /* Center the button vertically */
  border: none;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  padding: 2px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; /* Specify height and width to make it a circle */
  width: 20px;
  font-size: 16px;

}

@media (max-width:991px) {
  .filter_search_diaplay button {
    padding: 15px 19px 23px 21px !important;
    font-size: 25px !important;
    font-size: 40px !important;
    top: 50% !important;
    right: 8px !important;

  }
  .search-pro-img-tp-landmark{
    font-size: 25px !important;
  }

  .filter_search_diaplay {
    padding: 5px 59px 5px 20px;
    width: auto;

  }
  .search-row-comecnt-box img{
    width: 35px;
  }
  .search-row-comecnt-box span{
      font-size: 21px;
  }
}

@media(max-width:500px) {

  .loading-spinner {
  
  
    margin: 5% 0 45% 0 ;
    bottom: 1;
  
  }
  
  .spinner {
    border: 15px solid #272727;
  /* Light grey/white, semi-transparent */
  border-top: 15px solid #714BDC;
   
    width:200px;
    height: 200px;
    animation: spin 1s linear infinite;
  }
  
  .filter_search_diaplay {
    font-size: 51px;
    margin-bottom: 30px;
  }

  .sech-main-d-flex {
    display: block;
  }

  .search-post-mbl-rep {
    width: 100% !important;
  }
  .search-pro-img-tp-landmark {
    font-size: 35px !important;
}
.search-row-comecnt-box img {
  width: 52px;
  height: 50px;
}
.search-row-comecnt-box span {
  font-size: 35px;
  margin-left: 25px !important;
}

.search-x-buttom{


  align-items: center;
  height: 50px; /* Specify height and width to make it a circle */
  width: 50px;
  font-size: 36px;

}

}