.popupoverlay {
    position: fixed;
    top: 65%;
    left: 55.8%;
    transform: translate(-60%, -60%);
    background-color: rgb(86, 86, 87);
    display: flex;
    z-index: 1000;
    border-radius: 15px;
    width: 889px;
    height: 600px;
    z-index: 999;
}

.popupcontainer {
    background-color: rgb(86, 86, 87);
    padding: 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.popup_hash_tags {
    margin-left: 10px;
    white-space: nowrap;
    cursor: pointer;
    color: #D2C2FF;

}


.locationtext {
    position: absolute;
    bottom:78px;
    left: 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 15px;
    font-family: Inter;
    font-size: 12px;
    cursor: pointer;
}

.popupimage,
.popupvideo,
.popuptext {



    width: 480px;
    height: 530px;
    padding: 8px;
    border-radius: 6px;
    color: #fff;
    padding: 5px;
    font-family: Inter;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border: 1px solid #fff;

}

.popuptext-2 {
    width: 480px;
    height: 530px;
    padding: 8px;
    border-radius: 5px;
    color: #fff;
    padding: 5px;
    font-family: Inter;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

}
.popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #FFF
}

.popup-user-info {
    display: flex;
    margin-bottom: 5px;
}

.popup_disc {
    max-height: 50px;
    width: 350px;
    overflow-y: auto;
    word-wrap: break-word;
    scrollbar-width: thin;
    scrollbar-color: #272727 #000000;
}
.my-file-disc{
    max-height: none;
}

.popup_disc a {
    color: #e0dfec;
    text-decoration: underline;
}

.popup_disc{
    /* height: 350px; */
}

.popup_disc a:hover {
    text-decoration: none;
}



.popup_disc>span {

    white-space: pre-wrap;
}


.popup-user-logo {
    width: 30px;

    height: 30px;

    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    /* padding-left: 5px */
}

.popup-username {
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    padding: 5px;
}

.action-icons {
    display: flex;
    margin-top: 20px;

}

.action-icon {
    width: 20px;

    height: 20px;

    margin: 0 10px;

    cursor: pointer;
    transition: transform 0.2s;

}

.action-icon:hover {
    transform: scale(1.1);

}

.commentinput {
    width: 362px;
    /* height: 43px; */
    flex-shrink: 0;
    border-radius: 34px;
    background: #3D3D3D;
    margin: 10px 10px 20px;
    border: none;
    color: #ffffff;
    border-radius: none;
    padding: 10px;


}

.popup-image-2 {
    width: 489px;
    /* width: 100%; */
    height: 532px;
    object-fit: cover;
    margin-left: 0px;
    bottom: 0;
    right: 0;
    padding: 10px;
    border-radius: 25px;

}

.toggle-visibility-button{
  
        position: absolute;
        bottom: 15px;
        left: 325px;
        background-color: #714BDC;
        color: white;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
      
}
.comments_like span{
color: #fff;
cursor: pointer;
}
.replies{
    color: #FFF;
    font-family: Inter;

}

.commentinput input[type="text"] {
    width: 280px;
    height: 30.602px;
    flex-shrink: 0;
    color: #FFFFFF;
    border: none;
    background: none;
    color: white;
    outline: none;
    padding-left: 50px;
    font-family: Inter;
    flex-grow: 1;
    /* margin-right: -40px; */
}

.commentbox textarea[type="text"] {
    width: 160px;
    height: 30.602px;
    flex-shrink: 0;
    color: #FFFFFF;
    /* border: none; */
    background: none;
    color: white;
    outline: none;
    padding-left: 22px;
    font-family: Inter;
    flex-grow: 1;
    display: flex;
    /* margin-right: -40px; */
}

.commentbox {
    width: 350px;
    max-height: 390px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;


    scrollbar-width: 1px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #3D3D3D;
    margin-left: 10px;
    margin-top: 5px;
}

.commentbox p {

    color: #ffffff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
    padding: 5px
}

.commentbox h6 {
    color: #B2B2B2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
}

.commentbox img {
    /* width: 25px; */
    /* height: 25px; */
    /* flex-shrink: 0; */
    /* stroke-width: 1px; */
    /* stroke: #B2B2B2; */

}






/*  CSS */
@media (max-width:1200px) and (min-width:991px){
    .popupoverlay {
        top: 60%;
    }
    .popup_disc {
        /* max-height: 300px; */
    }
    .popupimage{
        width: 100%;
        height: auto;
    }

 
}

@media (max-width:991px) {
    .popupoverlay {
        position: fixed;
        top: 52%;
        left: 59.5%;
        transform: translate(-60%, -60%);
        background-color: rgb(86, 86, 87);
        display: flex;
        border-radius: 15px;
        width: 70%;
        /* height: 760px; */
        z-index: 999;
    }

    .commentinput {
        width: 100%;
        display: inline;
        margin: 10px 10px 20px;
    }


    .action-icon {
        width: 45px;
        height: 46px;
        margin: 0 17px;
    }

    .action-icons span {
        font-size: 25px;
        padding-right: 12px;

    }

    .profilename span {
        margin-left: 5px;
        font-size: 32px;
    }

    .popup-user-logo {
        width: 48px;
        height: 46px;
    }

    .popup_disc>span {
        font-size: 32px;
    }

    .popup_disc {
        /* max-height: none; */
        word-wrap: normal;
    }

    .commentbox {
        width: 100%;
    }
    .commentinput {
        width: 362px;
        /* height: 43px; */
        flex-shrink: 0;
        border-radius: 34px;
        background: #3D3D3D;
        margin: 10px 10px 20px;
        border: none;
        color: #ffffff;
        border-radius: none;
        padding: px;
    }

    .commentbox p {
        font-size: 27px;
    }

    .commentbox h6 {
        font-size: 18px;
    }
    .popupimage{
        width: 100%;
        height: auto;
    }
    .bucklst-text-card p{
        font-size: 35px;
      }
}


@media (max-width:500px) {
    .popupoverlay {
        position: fixed;
        top: auto;
        bottom: -63pc;
        left: 60%;
        transform: translate(-60%, -60%);
        background-color: rgb(86, 86, 87);
        display: block !important;
        border-radius: 15px;
        width: 100%;
        height: auto;
        z-index: 998;
    }

    .comments_like span{
        font-size: 35px;
    }
    .comments_like  button img{
        width: 45px !important;
        height: 45px;
    }
    .replies{
        font-size: 35px !important;
        color: #FFF !important;
        font-family: Inter !important;
    }

    .popup-image-2 {
        /* width: 489px; */
        width: 100%;
        height: 870px;
        object-fit: cover;
        margin-left: 0px;
        bottom: 0;
        right: 0;
        padding: 10px;
        border-radius: 25px;
    }
    .toggle-visibility-button {
       
        bottom: 890px;
        left: 1004px;
       
        font-size: 51px;
    }
    .popup-note {
        /* width: 489px; */
        width: 99% !important;
        height: 945px !important;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid white;
        background-color: #2b2a2a;
        color: #fff;
        padding: 5px;
        font-family: Inter;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
    }
    .popup-note span{
        font-size: 45px;
    }

    .profilelogo {
        width: 45px;
        height: 45px;
       
    }
   
    .postscontainer .popupoverlay  .popupimage{
        height: 800px !important;
    }

    .popupimage{
        width: 100% !important;
        height: 750px !important;
        margin-top: 40px;
    }

    .popuptext-2{
        width: 100% !important;
        font-size: 45px !important;       
        height: 893px;
    
      

    }
    .popuptitle{
        font-size: 55px !important;
    }
    .popup-image {
        width: 100% !important;
        height: 772px !important;
        margin-top: 110px;
    }

    .action-icon {
      
            width: 77px;
            height: 79px;
            margin: 0 17px;
      
    }

    .action-icons span {
        font-size: 51px;
        padding-right: 12px;

    }

    .profilename span {
        margin-left: 5px;
        font-size: 49px;
    }

    .commentbox {
        width: 98%;
        max-height: none;
        height: 550px;
    }
    .commentbox h6 {
        font-size: 30px;
        padding: 24px 10px 10px;
    }
    .commentbox p {
        font-size: 45px;
    }
    .popup-user-logo {
       
            width: 130px;
            height: 130px;
        
    
    }

    .popup_disc>span {
        font-size: 41px;
    }

    .popup_disc {
        max-height: none;
        word-wrap: normal;
    }
    .popup_disc {
        max-height: none;
        width: 100% !important;
    }

    .commentinput {
        width: 97%;
        display: inline;
        font-size: 50px;
        margin: 50px 10px 20px;
        height: 125px !important;
        border-radius: 100px;
    }

    .locationtext {
        bottom: 60%;
        font-size: 34px;
    }

    .locationtext img{
        width: 53px;
    }
    .bucklst-text-card p{
        font-size: 40px;
      }
      .locationtext {
          bottom: 62%;
          font-size: 38px;
      }

   
}


  @media (max-width:375.95px){
    .popupoverlay {
        bottom: -68pc;
    }
    .commentbox {
        height: 620px !important;
    }
    .close-popup {
        font-size: 70px;
    }
    .locationtext {
        bottom: 56%;
        font-size: 38px;
    }
    .locationtext img{
        width: 62px;
    }
    .popup-image-2 {
        width: 100%;
        height: 972px;
        object-fit: cover;
        margin-left: 0px;
        bottom: 0;
        right: 0;
        padding: 10px;
        border-radius: 25px;
    }

  

  }