.rightcard {
    background-color: #131313;
    color: white;
    width: 386px;
    border-radius: 15px;
    border: 1px solid #3A3A3A;

}

.centercard {
    padding: 30px;
    background-color: #272727;
    border-radius: 15px;
    border: 0.5px solid #3A3A3A;

    background: #272727;
}

.popular_cards {
    display: flex;
    background-color: rgb(104, 104, 105);
    border-radius: 1rem;
    margin-bottom: 20px;
    width: 100%;
    height: 130px;
    overflow: hidden;
}

.tvscreens {
    width: 325px;
    height: 209px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 1rem;
}

.cards {
    border-radius: 15px;
    border: 0.5px solid #3A3A3A;
    background: #3A3A3A;
    margin-bottom: 21px;
    width: 100%;
    height: 209px;
    
}


.pop_comment_trending {

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.centerimg {
    width: 140px;
    height: 130px;
    padding: 10px;
    border-radius: 1rem;
    object-fit: cover;
}


.tvimgfull{
    width: 385px;
    height: 209px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 1rem;
}

.hashtags {
    width: 211px;
    height: 168px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    text-decoration-line: underline;
    color: #FFF;
    font-family: Inter;
    padding: 15px;
    cursor: pointer;
}

.cardvideo {
    width: 386px;
    height: 482px;
    flex-shrink: 0;
    border-radius: 15px;
    margin-top: 70px;
}

.card_Video_popular {
    width: 386px;
    height: 482px;
    flex-shrink: 0;
    border-radius: 15px;
    object-fit: cover;
}

.cards {
    /* position: relative; */
    border-radius: 15px;
    border: 0.5px solid #3A3A3A;
    background: #3A3A3A;
    margin-bottom: 21px;
    width: 100%;
    height: 209px;
}

.centerimgfull {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.pop_board_name {
   
    position:relative;
    bottom: 35px;
    left: 10px;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 50px;
    color: #272727;
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px; /* Adjust as necessary */
   
    text-align: left;
    display: inline-block;
}


.Vogyatv {
    color: #FFF;
    font-family: Inter;
    font-size: 21px;
    margin-top: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/*  CSS */
@media (max-width:1200px){
    .hashtags {
        font-size: 21px;
    }
    .hashtags div{
        padding-bottom: 10px;
    }
    .pop_comment_trending {
        font-size: 24px;
      }
      .pop_board_name {
        font-size: 16.5px;
        bottom: 46px;
    }
    .centercard {
        padding: 19px 10px;
    }
    .centerimg {
        padding: 10px 0px;
        width: 150px;
    }
}

@media (max-width:991px){
    .pop_board_name {
        bottom: 47px;
        font-size: 18px;
    }
    .hashtags {
        font-size: 22px;

    }
    .hashtags div{
        padding-bottom: 12px;
    }
}