.popup-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-body {
  width: 250px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px) brightness(60%) contrast(60%);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 15px;
  position: relative;
}
.popup-container.popup-above {
  bottom: 10px;
  top: auto;
  transform: translateX(-50%);
}

.option-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
}

.option-item img {
  height: 20px;
  width: auto;
}

.option-item:hover {
  background: #272727;
}

