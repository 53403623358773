.edit-post-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-post-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.edit-post-popup-close-btn {
  float: right;
  cursor: pointer;
}

.edit-post-popup-image {
  width: 500px;
  height: 550px;
  margin-bottom: 10px;
}
.youtube_popup{
  width: 100% !important;
}

.edit-post-popup-form {
  text-align: center;
}



.edit-post-popup-image-container {
  position: relative;
  /* Set the image container to relative positioning */
  text-align: center;
  /* Center the image and the button */
  width: 500px;
  /* Set the width to match the image */
  height: 500px;
  /* Set the height to match the image */
  margin: auto;
  /* Center the container */
}

.edit-post-popup-image {
  width: 500px;
  height: 575px !important;
  object-fit: cover;
  border-radius: 15px;
}

.pointer-image {
  right: 0;
}

.edit-popup-container {
  background-color: rgb(86, 86, 87);
  padding: 10px;
  border-radius: 5px;
  /* display: flex; */
  flex-direction: column;
}

.edit-action-icon {
  width: 20px;

  height: 20px;

  margin: 0 30px;

  cursor: pointer;


}

.edit-description-input {
  width: 320px;
  height: 95px !important;
  border: none;
  background-color: #424141;
  color: #fff;
  padding: 8px;
  border-radius: 8px;

}

.location-text {
  position: absolute;
  bottom: -63px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 25px;
  font-family: Inter;

  font-size: 14px;
}

.edit-location-input {
  background-color: transparent;
  border: none;
  border-radius: 25px;
  padding: 5px 15px;
  color: white;
  flex-grow: 1;
}

.edit-location-input:focus {
  outline: none;
  /* Removes the default focus outline */
}
.location_suggestions {
  position: absolute;
  cursor: pointer;
  top: 100%;
  left: 0;
  right: 0;
  color: #131313;
  background-color: #FFF; /* Dark theme background */
  border-radius: 4px;
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrollbar */
  max-height: 150px; /* Limit the height and enable scrolling */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 10;
  font-size: 20px;
}

/* Custom scrollbar styles */
.location_suggestions::-webkit-scrollbar {
  width: 4px; /* Scrollbar width */
}

.location_suggestions::-webkit-scrollbar-track {
  background: #2b2b2b; /* Scrollbar track color */
  border-radius: 2px;
}

.location_suggestions::-webkit-scrollbar-thumb {
  background: #6b6b6b; /* Scrollbar handle color */
  border-radius: 2px;
}

.location_suggestions::-webkit-scrollbar-thumb:hover {
  background: #959595; /* Scrollbar handle hover color */
}
.edit-post-popup-select-image {
  align-items: center;
  position: absolute;
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 10px 20px;
  background: none;
  /* background-color: #ffffffc0;  */
  color: #fff;
  border-radius: 25px;
  transition: background-color 0.3s ease;

}





.edit-note-container {
  width: 100%;
  height: 580px;
  margin: auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  text-align: left;
  font-family: Inter;
}

.edit-note-description-input {
  width: 450px;
  /* Take up the full width of its container */
  height: 500px;
  /* Fill the container height */
  padding: 0;
  /* No padding needed, container has it */
  border: none;
  /* No border */
  outline: none;
  /* No outline on focus */
  background-color: transparent;
  /* Transparent background */
  resize: none;
  /* Disable resize handle */
  font-size: 16px;
  /* Readable font size */
  line-height: 1.5;
  font-family: Inter;
}


.edit-post-popup-save-button-1 {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background: #714BDC;
  color: white;
  border: none;
  margin-left: 95px;
  margin-top: 450px;
  bottom: 1;
  width: auto;
  align-self: center;
  flex-direction: column;
  margin-bottom: -10px;
}
.edit-post-popup-save-button-2 {
  /* cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background: #714BDC;
  color: white;
  border: none;
  margin-left: 95px;
  margin-top: 200px;
  bottom: 1;
  width: auto;
  align-self: center;
  flex-direction: column;
  margin-bottom: -10px; */

  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background: #714BDC;
  color: white;
  border: none;
  position: absolute; 
  bottom: 20px; 
  left: 72%;
  /* transform: translateX(10%);  */


}
.post_save-button{
  margin-top: 400px !important;
}

/* .edit-post-popup-save-button {
      display: block;
      border-radius: 25px;
      width: calc(100% - 40px);
      margin-top: 20px;
      padding: 10px 20px;
    } */

.ep-hashtags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  margin-top: 10px;

}

.hash_tag_input {
  border-radius: 64px;
  border: 1px solid #3A3A3A;
  background: #131313;
  color: #B2B2B2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;

}

.ep-hashtag {
  display: flex;
  align-items: center;
  background-color: #714BDC;
  border-radius: 15px;
  padding: 3px 7px;
  font-size: 14px;
}

.ep-hashtag-remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  color: #FFF;
}

.ep-hashtag-input {
  border-radius: 64px;
  border: 1px solid #3A3A3A;
  border: none;
  background: #131313;
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  padding: 5px 15px;
  margin-top: 5px;
  
}

.edit-post-hashtag {
  height: 95px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; 
  background-color: #424141;
  margin-right: 25px;
  margin-top: 10px;
  border-radius: 8px;

}


/*  CSS */

@media (max-width:1200px) {
  .hash_tag_input {
    font-size: 21px;
  }
}



@media (max-width:991px) {
  .edit-post-popup-image-container {
    width: 700px;
  }

  .edit-post-popup-image {
    width: 100%;
  }

  .edit-description-input {
    width: 100%;
    height: 142px;
    padding: 10px 15px;
    font-size: 21px;
  }

  .ep-hashtag-input {
    font-size: 23px;
  }

  .edit-post-hashtag {
    height: 126px;
    width: 100%;
  }

  .edit-post-popup-save-button {
    font-size: 24px;
  }

  .edit-post-popup-image-container {
    margin: 30px auto;
  }

  .edit-action-icon {
    width: 30px;
    height: 30px;
  }
}


@media (max-width:500px) {
  .edit-post-popup-image-container {
    width: 100%;
    height: 991px !important;
  }


.location_suggestions {
  
    font-size: 51px;
    /* height: 90px; */
    max-height: 250px;
  }

  .edit-post-popup-image {
    height: 100% !important;
    padding-top: 72px;
    
  }

  .edit-post-popup-save-button {
    font-size: 48px;
    margin-top: 40px;
    margin-bottom: 53px;
  }

  .ep-hashtag-input {
    font-size: 52px;
    margin: 20px 0px;
    padding: 31px 51px;
  }



  .edit-description-input {
    width: 100%;
    height: 375px !important;
    padding: 23px 15px;
    font-size: 51px;
    line-height: normal;
  }

  .edit-post-hashtag {
    height: 215px;
    width: 72%;
  }

  .edit-post-popup-select-image {
    font-size: 31px;
  }
  .location-text {
    width: auto;
    font-size: 34px;
    bottom: 26px;
  }
  
.ep-hashtag {
  
  padding: 5px 10px;
  font-size: 51px;
}
.ep-hashtag-remove-btn {
  margin-left: 15px;
  font-size: 40px;
}

  .disc {
    max-width: none !important;
  }
  .edit-popup-container span{
    font-size: 44px;
  }
  .edit-popup-container .edit-action-icon {
    width: 50px;
    height: 50px;
}
    .edit-post-popup-save-button-2 {
      cursor: pointer;
      padding: 10px 20px;
      font-size: 57px;
      border: none;
      font-weight: 500;
      bottom: 32px;
      left: 72%;
  
    }

  .edit-note-description-input {
    width: 100%;
    height: 1200px !important;
    padding: 45px;

    font-size: 57px;
   
}

.edit-note-container {
  height: 1300px;

}
}

@media(max-width:350px){
  .edit-note-description-input {
   

    font-size: 57px;
   
}

.edit-post-popup-save-button-2 {
  
  font-size: 57px;

  bottom: 35px;
  left: 65%;

}
}