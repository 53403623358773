.grid-container {
    width: 100%;
    height: auto;
    max-width: 1520px;
    display: grid;
    grid-template-columns: repeat(5, 285px);
    grid-auto-rows: 405px 285px 285px;
    grid-gap: 15px;
    padding: 15px;
    /* margin: 10px; */
    background-image: url('./../../../Assets/Images/Trackmarker.png');
    background-repeat: no-repeat;
    /* background-color: #272727; */
}

.grid-wrapper {
    display: flex;
    justify-content: center;
    /* Center the grid container */
    background-color: #272727;
    /* Apply background color here */
}

.location-popup-text {
    /* position: absolute; */
    bottom: 15px;
    left: 10px;
    color: white;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* padding: 5px 10px; */
    border-radius: 25px;
    font-family: Inter;

    font-size: 14px;
}

.grid-item {
    position: relative;
    overflow: hidden;
    border-radius: 15px;

}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-info-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* display: none; */

}

.image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image-overlay .top-left,
.image-overlay .top-right,
.image-overlay .bottom-left,
.image-overlay .bottom-right {
    position: absolute;
    padding: 10px;
}

.image-overlay .top-left span {
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.image-overlay .top-left {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    /* Space between elements */

}

.image-overlay .top-left .profile-and-name {

    display: flex;

    align-items: center;

}

.image-overlay .top-left .profile-logo-container img {

    border-radius: 50%;

    width: 40px;
    /* Adjust as needed */

    height: 40px;
    /* Adjust as needed */

    object-fit: cover;

    margin-right: 5px;
    /* Space between profile logo and username */

}

.image-overlay .top-left .location-container {

    display: flex;
    align-items: center;
    background: rgba(58, 58, 58, 0.70);
    border-radius: 15px;
    padding: 5px 9px;
    color: white;
    font-size: 10px;
    gap: 5px;
    /* Space between icon and location text */

}

.image-overlay .top-left .location-container img {

    width: 10px;
    /* Adjust the size of the location icon */

    height: 12px;

}

.image-overlay .top-left .location-container span {
    font-size: 12px;
    font-weight: 300;

}

.image-overlay .top-right {
    top: 0;
    right: 0;
    display: flex;
    align-items: start;
    padding-right: 10px;
    gap: 20px;
    padding-top: 10px;
}

.image-overlay .top-right img:first-of-type {
    margin-right: 20px;
}


.image-overlay .top-right .icon-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.image-overlay .top-right .icon-container img {
    width: 21px;
    height: 19px;
    display: block;
    margin: 0 auto 5px;
}

.image-overlay .top-right .icon-container span {
    color: white;
    text-align: center;
    display: block;
    width: 100%;
}

.image-overlay .top-right img {
    width: 10px;
    height: 10px;
    margin-bottom: 5px;
}

.image-overlay .bottom-right img {
    width: 20px;
    height: 19px;
    display: block;
    margin: 0 auto 5px;

}



.image-overlay .bottom-left span {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    width: 287px;
    height: 44px;
    flex-shrink: 0;
}

.image-overlay .bottom-right span {
    border-radius: 50px;
    background: rgba(58, 58, 58, 0.70);
    backdrop-filter: blur(2px);
    width: auto;
    padding: 5px 9px;
    height: 20px;
    flex-shrink: 0;
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



.profile-logo-container img {
    border-radius: 50%;
    /* background-color: rgb(0, 0, 0); */
    padding: 5px;
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
    /* Adjust size as needed */
    object-fit: cover;
    margin-right: 5px;
}

.image-overlay .bottom-left {
    bottom: 0;
    left: 0;
    text-align: left;
}

.image-overlay .bottom-right {
    bottom: 0;
    right: 0;
    text-align: right;
}

.error-message {
    color: red;
    padding-left: 80px;
}

.grid-background {
    background-image: url('./../../../Assets/Images/Trackmarker.png');
    background-repeat: no-repeat;
    background-color: #272727;
}

.empty-grid-content h1 {
    width: 655px;
    height: 171px;
    flex-shrink: 0;
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 56px;
    font-weight: 600;
    line-height: normal;
}


.empty-grid-content p {
    width: 536px;
    color: var(--icon-color, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0;
    /* Add some spacing around the paragraph */
}


.empty-grid-content button {
    border-radius: 49px;
    background: #714BDC;
    width: 197px;
    height: 55px;
    flex-shrink: 0;
    color: white;
    font-family: Inter;
    font-size: 16px;
    /* Adjust as needed */
    font-weight: 600;
    /* Adjust as needed */
    line-height: normal;
    border: none;
    /* Remove default button border */
    cursor: pointer;
    /* Change cursor on hover */
}

/* Add a container to center-align the content */
.empty-grid-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* Take full height of the grid cell */
}

.popup-overlay {
    position: fixed;
    top: 65%;
    left: 55.8%;
    transform: translate(-60%, -60%);
    background-color: rgb(86, 86, 87);
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 1000;
    border-radius: 15px;
    width: 900px;
    height: 600px
}

.whiteCircle {
    background-color: white;
    border-radius: 50%;
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
    /* Adjust size as needed */
    display: inline-block;
}


.popup-image {
    width: 505px;
    height: 506px;
    object-fit: cover;
    margin-left: 0px;
    bottom: 0;
    right: 0;
    padding: 10px;

}

.profile-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.profilename {
    align-self: flex-start;
    display: flex;
    align-items: center;
    color: #FFF;
    padding: 15px;
    font-family: Inter;
    font-weight: 600;
}

.close_image_popup {

    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    background-color: #3D3D3D;
    border-radius: 50%;
    background: #d8d5d5;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in-out;


}

.profilename span {
    margin-left: 5px;
}

.time {
    margin-left: 155px;
    color: #B2B2B2;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bottom-content {
    align-self: flex-end;
    text-align: left;
    /* Aligns the text and icons to the left */
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adjust spacing between icons and text */
}

.social-icons img {
    width: 24px;
    /* Adjust size as necessary */
    height: 24px;
}

.popupcount {
    display: flex;
    padding: 3px;
}

.popupcount img {
    width: 20px;
    height: 20px;
    /* padding: 3px; */
    margin-left: 25px;
    font-weight: 600;
}

.popupcount span {
    color: #FFF;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500;
}

.disc {
    /* color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    max-width: 290px;
    height: 44px;
    flex-shrink: 0;
    margin-left: 25px;
    padding-bottom:15px;
    margin-top: 5px; */
    margin-left: 25px;
    padding-bottom: 15px;
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    max-width: 390px;
    /* height: 90px; */
    /* max-height: 90px; */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* scrollbar-color: #714BDC #272727;  */
}

.vertical-line {
    height: 100%;
    /* Match the height to your content or container */
    width: 1px;
    background-color: rgb(164, 164, 164);
}

.horizonta-line {
    height: 1px;
    /* Match the height to your content or container */
    width: 100%;
    background-color: rgb(164, 164, 164);
}

.image-overlay-location {

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    margin-top: 35px;
    margin-left: 15px;
    align-items: center;
    font-size: 12px;
    padding: 5px 9px;
    /* Adjust based on your design */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for readability */
    color: white;
    /* Adjust color for visibility */
    border-radius: 25px;
    /* Example of rounded bottom-right corner */
}

.location-icon {
    width: 10px;
    /* Adjust as necessary */
    height: 14px;
    /* Adjust as necessary */
    margin-right: 15px;
    /* Space between icon and text */
}

.popup-text-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Aligns children with space between */
    height: 100%;
    /* Adjust based on your design */
}

.comment-input {
    width: 374px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 34px 34px 14px 34px;
    /* background: #3D3D3D; */
    margin: 10px;
    border: none;
    color: #ffffff;
    border-radius: none;
    padding: 15px;
}
.comment-input::placeholder {
    color: #FFF; /* Change this to your desired color */
  }

.comment_box {
    width: 352px;
    max-height: 400px;
    overflow-y: auto;
    /* Enables vertical scrolling */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    flex-shrink: 0;
    border-radius: 15px;
    /* background: #3D3D3D; */
    margin-left: 10px;
    margin-top: 5px;
}

.profilelogo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.comment_box p {
    color: #ffffff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
    padding: 5px
}

.comment_box h6 {
    color: #B2B2B2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
}

.comment_box img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    stroke-width: 1px;
    stroke: #B2B2B2;
    /* margin-left: 20px; */
}

.comments_like {
    display: flex;
}

.comments_like img {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    stroke-width: 1px;
    stroke: #B2B2B2;
    margin-left: 15px;
}

@media (min-width: 320px) and (max-width: 376px) {


    .grid-container {

        width: 100%;
        height: 632px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 282px);
        grid-auto-rows: 842px 360px 438px;
        grid-gap: 15px;
        /* padding-right: 35px; */
        height: 1623px;
        margin-right: auto;


    }

    .disc {

        margin-left: 25px;
        padding-bottom: 15px;
        color: var(--icon-color, #FFF);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        max-width: 390px;
        /* height: 90px; */
        max-height: 90px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        /* scrollbar-color: #714BDC #272727;  */
    }

    .image-overlay .bottom-left span {
        color: #FFF;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        width: 287px;
        height: 44px;
        flex-shrink: 0;
    }

    .image-overlay .top-left .profile-logo-container img {

        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;


    }

    .image-overlay .top-left .profile-and-name span {
        font-size: 38px;
        font-weight: 300;
    }

    .image-overlay .top-left .location-container span {
        font-size: 32px;
        font-weight: 300;

    }

    .image-overlay .top-right .icon-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .image-overlay .top-right .icon-container img {
        width: 41px;
        height: 39px;
        display: block;
        margin: 0 auto 5px;
    }

    .image-overlay .top-right .icon-container span {
        color: white;
        text-align: center;
        display: block;
        width: 100%;
    }

    .image-overlay .top-right img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .empty-grid-content h1 {
        margin-top: 205px;
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 100% !important;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
        /* Add some spacing around the paragraph */
    }


    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        /* Adjust as needed */
        font-weight: 600;
        /* Adjust as needed */
        margin-top: 15px;
        line-height: normal;
        border: none;
        /* Remove default button border */
        cursor: pointer;
        /* Change cursor on hover */
    }

    /* Add a container to center-align the content */
    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60%;
        /* margin-top: 45px; */
        /* Take full height of the grid cell */
    }

    .grid-container {

        background-image: none !important;
        background-repeat: no-repeat;

    }
}

@media(min-width:369.25px) and (max-width:374.98px){

}

@media  (max-width:767px) {
    .grid-container {

        width: 100%;
        height: 632px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 282px);
        grid-auto-rows: 842px 360px 438px;
        grid-gap: 15px;
        /* padding-right: 35px; */
        height: 1623px;
        margin-right: auto;


    }

    .disc {

        margin-left: 25px;
        padding-bottom: 15px;
        color: var(--icon-color, #FFF);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        max-width: 390px;
        /* height: 90px; */
        max-height: 90px;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        /* scrollbar-color: #714BDC #272727;  */
    }

    .image-overlay .bottom-left span {
        color: #FFF;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        width: 287px;
        height: 44px;
        flex-shrink: 0;
    }

    .image-overlay .top-left .profile-logo-container img {

        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;


    }

    .image-overlay .top-left .profile-and-name span {
        font-size: 38px;
        font-weight: 300;
    }

    .image-overlay .top-left .location-container span {
        font-size: 32px;
        font-weight: 300;

    }

    .image-overlay .top-right .icon-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .image-overlay .top-right .icon-container img {
        width: 41px;
        height: 39px;
        display: block;
        margin: 0 auto 5px;
    }

    .image-overlay .top-right .icon-container span {
        color: white;
        text-align: center;
        display: block;
        width: 100%;
    }

    .image-overlay .top-right img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .empty-grid-content h1 {
        margin-top: 205px;
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 100% !important;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
        /* Add some spacing around the paragraph */
    }


    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        /* Adjust as needed */
        font-weight: 600;
        /* Adjust as needed */
        margin-top: 15px;
        line-height: normal;
        border: none;
        /* Remove default button border */
        cursor: pointer;
        /* Change cursor on hover */
    }

    /* Add a container to center-align the content */
    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60%;
        /* margin-top: 45px; */
        /* Take full height of the grid cell */
    }

    .grid-container {

        background-image: none !important;
        background-repeat: no-repeat;

    }
}



@media (min-width: 425px) and (max-width: 575px) {
    .grid-container {
        width: 100%;
        height: 632px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 282px);
        grid-auto-rows: 842px 360px 438px;
        grid-gap: 15px;
        /* padding-right: 35px; */
        height: 1623px;
        margin-right: auto;
    }

    .disc {
        margin-left: 25px;
        padding-bottom: 15px;
        color: var(--icon-color, #FFF);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        max-width: 390px;
        /* height: 90px; */
        max-height: 90px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        /* scrollbar-color: #714BDC #272727;  */
    }

    .image-overlay .bottom-left span {
        color: #FFF;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        width: 287px;
        height: 44px;
        flex-shrink: 0;
    }

    .image-overlay .top-left .profile-logo-container img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;
    }

    .image-overlay .top-left .profile-and-name span {
        font-size: 38px;
        font-weight: 300;
    }

    .image-overlay .top-left .location-container span {
        font-size: 32px;
        font-weight: 300;
    }

    .image-overlay .top-right .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .image-overlay .top-right .icon-container img {
        width: 41px;
        height: 39px;
        display: block;
        margin: 0 auto 5px;
    }

    .image-overlay .top-right .icon-container span {
        color: white;
        text-align: center;
        display: block;
        width: 100%;
    }

    .image-overlay .top-right img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .profile-logo-container img {
        border-radius: 50%;
        /* background-color: rgb(0, 0, 0); */
        padding: 5px;
        width: 150px;
        /* Adjust size as needed */
        height: 150px;
        /* Adjust size as needed */
        object-fit: cover;
        margin-right: 5px;
    }

    .empty-grid-content h1 {
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 736px;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
        /* Add some spacing around the paragraph */
    }


    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        /* Adjust as needed */
        font-weight: 600;
        /* Adjust as needed */
        margin-top: 15px;
        line-height: normal;
        border: none;
        /* Remove default button border */
        cursor: pointer;
        /* Change cursor on hover */
    }

    /* Add a container to center-align the content */
    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .grid-container {
        background-image: none !important;
        background-repeat: no-repeat;
    }


}

@media (min-width: 375px) and (max-width: 424px) {


    /* .grid-container {
        width: 100%;
        height: 632px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 282px);
        grid-auto-rows: 842px 360px 438px;
        grid-gap: 15px;
        padding-right: 35px;
        height: 1623px;
        margin-right: auto;
    } */

    .disc {
        margin-left: 25px;
        padding-bottom: 15px;
        color: var(--icon-color, #FFF);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        max-width: 390px;
        max-height: 90px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
       
    }

    .image-overlay .bottom-left span {
        color: #FFF;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        width: 287px;
        height: 44px;
        flex-shrink: 0;
    }

    .image-overlay .top-left .profile-logo-container img {

        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;


    }

    .image-overlay .top-left .profile-and-name span {
        font-size: 38px;
        font-weight: 300;
    }

    .image-overlay .top-left .location-container span {
        font-size: 32px;
        font-weight: 300;

    }

    .image-overlay .top-right .icon-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .image-overlay .top-right .icon-container img {
        width: 41px;
        height: 39px;
        display: block;
        margin: 0 auto 5px;
    }

    .image-overlay .top-right .icon-container span {
        color: white;
        text-align: center;
        display: block;
        width: 100%;
    }

    .image-overlay .top-right img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .empty-grid-content h1 {
        margin-top: 205px;
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 736px;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
    }


    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        margin-top: 15px;
        line-height: normal;
        border: none;
        cursor: pointer;
    }

    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60%;
        /* margin-top: 45px; */
        /* Take full height of the grid cell */
    }

    .grid-container {

        background-image: none !important;
        background-repeat: no-repeat;

    }
}


@media (min-width: 425px) and (max-width: 575px) {

    /* .grid-container {
        width: 100%;
        height: 632px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 382px);
        grid-auto-rows: 942px 360px 438px;
        grid-gap: 15px;
        height: 1623px;
        margin-right: auto;


    } */

    .disc {

        margin-left: 25px;
        padding-bottom: 15px;
        color: var(--icon-color, #FFF);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        max-width: 390px;
        /* height: 90px; */
        max-height: 90px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        /* scrollbar-color: #714BDC #272727;  */
    }

    .image-overlay .bottom-left span {
        color: #FFF;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        width: 287px;
        height: 44px;
        flex-shrink: 0;
    }

    .image-overlay .top-left .profile-logo-container img {

        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;


    }

    .image-overlay .top-left .profile-and-name span {
        font-size: 38px;
        font-weight: 300;
    }

    .image-overlay .top-left .location-container span {
        font-size: 32px;
        font-weight: 300;

    }

    .image-overlay .top-right .icon-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .image-overlay .top-right .icon-container img {
        width: 41px;
        height: 39px;
        display: block;
        margin: 0 auto 5px;
    }

    .image-overlay .top-right .icon-container span {
        color: white;
        text-align: center;
        display: block;
        width: 100%;
    }

    .image-overlay .top-right img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .profile-logo-container img {
        border-radius: 50%;
        padding: 5px;
        width: 150px;
        height: 150px;
        object-fit: cover;
        margin-right: 5px;
    }

    .empty-grid-content h1 {
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 736px;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
    }


    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        margin-top: 15px;
        line-height: normal;
        border: none;
        cursor: pointer;
    }

    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

    }

    .grid-container {

        background-image: none !important;
        background-repeat: no-repeat;

    }


}


@media (min-width: 425px) and (max-width: 575px) {
    /* .grid-container {
        width: 100%;
        height: 600px;
        max-width: 2020px;
        display: grid;
        grid-template-columns: repeat(5, 285px);
        grid-auto-rows: 905px 285px 285px;
        grid-gap: 15px;
        padding: 15px;
        height: 1520px;
    } */

    .empty-grid-content h1 {

        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }

    .empty-grid-content p {
        /* width: 736px;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0; */
        width: 100% !important;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
    }

    .empty-grid-content button {
        border-radius: 100px;
        background: #714BDC;
        width: fit-content;
        height: 125px;
        flex-shrink: 0;
        color: white;
        padding: 5px 75px;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        margin-top: 15px;
        line-height: normal;
        border: none;
        cursor: pointer;
    }

    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60%;
        margin-top: 45px;
    }

    .grid-container {
        background-image: none !important;
        background-repeat: no-repeat;

    }

}



/* For screens between 576px and 767.98px (Small devices) */
@media (min-width: 576px) and (max-width: 767.98px) {


    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* grid-auto-rows: minmax(600px, 10px); */
        grid-gap: 15px;
        padding: 15px;
    }

    .grid-container {
        padding: 15px;
        margin-right: 5px;
    }


    .empty-grid-content h1 {
        margin-top: 205px;
        width: 755px;
        height: 251px;
        flex-shrink: 0;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 66px;
        font-weight: 600;
        line-height: normal;
    }


    .empty-grid-content p {
        width: 736px;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
    }


    .empty-grid-content button {      
        height: 55px;
        flex-shrink: 0;
        color: white;
        padding: 5px 25px;
        font-family: Inter;
        font-size: 26px;     
        font-weight: 600;       
        margin-top: 15px;

    }

    .empty-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    
}

/* For screens between 768px and 991.98px (Medium devices) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .grid-container {
        /* max-width: 1800px; */

    }

}



/* For screens larger than 1200px (Extra large devices) */
@media (max-width: 1200px) {
    .grid-background {
        background-image: url('./../../../Assets/Images/Trackmarker.png');
        background-repeat: no-repeat;
        background-color: #272727;
    }
}


@media (min-width:991px) and (max-width:1040px) {
    /* .grid-container {
        grid-template-columns: repeat(5, 185px);
    } */
}
@media (min-width:1024px) and (max-width:1200px) {
    .grid-container {
        grid-template-columns: repeat(5, 285px);
    }

    .dashboard-width {
        width: 1540px;
        left: 0;
        right: 0;
    }

    .grid-wrapper {
        display: flex;
        justify-content: center;
        background-color: #272727;
        width: 1540px !important;
        /* width: 1612px  !important; */
}}
@media (min-width:1440.1px) and (max-width:1505px) {
    .grid-container {
        grid-template-columns: repeat(5, 285px);
        padding-right: 25px;
    }

    .dashboard-width {
        width: 1540px;
        left: 0;
        right: 0;
    }

    .grid-wrapper {
        display: flex;
        justify-content: center;
        background-color: #272727;
        width: 1540px !important;
}}


@media(max-width:991px) {
    .grid-container {
        grid-template-columns: repeat(5, 285px);
    }


}


@media (max-width:500px) {

    .grid-container {
        padding: 15px;
        margin-right: 5px;
    }
}

@media (min-width:500.10px)and (max-width:767.95px){
    .grid-wrapper {
        
        width: 100%;
}

.empty-grid-content button {
    border-radius: 150px;
    background: #714BDC;
   
    height: 125px;
   
    padding: 5px 75px;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
  
}

.empty-grid-content h1 {
    margin-top: 15px;
}

.grid-container {
    padding: 15px;
    margin-right: 5px;
}





}

@media (max-width:576px)and (max-width:767.95px){
    .empty-grid-content h1 {
        margin-top: 205px;
    }
}