.createbtn {
    background-color: rgb(126, 38, 228);
    height: 45px;
    border-radius: 2rem;
    width: 850px;
    text-align: center;
    padding-top: 5px;
    font-size: 20px;
    border: none;
    color: aliceblue;
}

.postprofileimg {
    width: 40px;
    height: 40px;
    border: solid 2px white;
    border-radius: 40px;
    border: 2px solid #FFF;
}

.postUserName {
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.textarea_comments {
    outline: none;
    background-color: #3D3D3D;
    border: none;
    color: white;
    width: 514px;
    padding: 20px 15px;

}
.location_suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #FFF; /* Dark theme background */
    border-radius: 4px;
    overflow-y: auto; /* Allows vertical scrolling */
    overflow-x: hidden; /* Hides horizontal scrollbar */
    max-height: 150px; /* Limit the height and enable scrolling */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 10;
    font-size: 20px;
  }
  
  /* Custom scrollbar styles */
  .location_suggestions::-webkit-scrollbar {
    width: 4px; /* Scrollbar width */
  }
  
  .location_suggestions::-webkit-scrollbar-track {
    background: #2b2b2b; /* Scrollbar track color */
    border-radius: 2px;
  }
  
  .location_suggestions::-webkit-scrollbar-thumb {
    background: #6b6b6b; /* Scrollbar handle color */
    border-radius: 2px;
  }
  
  .location_suggestions::-webkit-scrollbar-thumb:hover {
    background: #959595; /* Scrollbar handle hover color */
  }
.containtpopup {
    width: 565px;
    max-height: auto;
    flex-shrink: 0;
    border: 1px solid #3A3A3A;
    background: #272727;
}

.popuptitle {
    color: white;
   font-size: 18px; font-weight: 600; padding: 10px 10px 10px 0px
}

.postcontaint {
    width: 530px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #3D3D3D;
}

.postcomment {
    width: auto;
    min-height: 91px;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.image_container {
    position: relative;
    width: 40px;
    /* min-height: 40px; */
    margin-left: 10px;
    /* margin-top: -85px; */

}

.background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.foreground-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.location_input,
.hash_tag_input {
    width: 103px;
    height: 24px;
    border-radius: 64px;
    border: none;
    background: #272727;
    margin-top: 10px;
    color: white;
    font-size: 13px;
    right: 40px;
    padding-left: 12px;
}


.sendimg {
    float: inline-end;
}

.submitbutton {

    background-color: #3D3D3D;
    border: none;
}

.foreground-image {
    cursor: pointer;
}

.hidden {
    display: none;
}

#rowcontaint {
    position: relative;
    margin-top: 5%;
    z-index: 20;
    margin-left: 1px;
}

.btn_cls_create{
  
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50px;
        background-color: #FFF;
        color: #fff;
   
}
.selectedImage {
    width: 100%;
    max-height: fit-content;
    flex-shrink: 0;
    border-radius: 5px;
}

.location_container {
    position: relative;
}

.location_icon {
    position: absolute;
    top: 60%;
    right: 90px;
    /* Adjust this value to position the icon */
    transform: translateY(-50%);
}

.Location_placeholder {
    padding-left: 30px;
}

.tag {
    display: inline-block;
    background-color: rgb(126, 38, 228);
    color: #fff;
    padding: 3px 8px;
    margin: 5px;
    border-radius: 25px;
    font-size: 14px;
    /* filter: drop-shadow(1px 1px 2px #161515 ); */

}

.remove-tag-button {
    background: none;
    border: none;
    outline: none;
}

.sending_btn {
    float: right;
    padding-left: 26%;
}

.location_container {
    position: relative;
}


.Location_placeholder {
    padding-left: 30px;
}


.tag {
    display: inline-block;
    background-color: rgb(126, 38, 228);
    color: #fff;
    padding: 3px 8px;
    margin-right: 5px;
    border-radius: 25px;
    font-size: 14px;
}

.sending_btn {
    float: right;
    padding-left: 26%;
}

@media (min-width: 320px) and (max-width: 374px) {
    .createbtn {
        height: 74px;          
        width: 1132px !important;
        font-size: 28px;
      
}

    .adventure {
        font-size: 98px;
    }

    .postprofileimg {
        width: 80px;
        height: 80px;
        border-radius: 20px;
    }

    .postUserName {
        font-size: 18px;
    }

    .textarea_comments {
        width: 100%;
    }

    .containtpopup {
        width: 565px;
        max-height: auto;
        flex-shrink: 0;
        border: 1px solid #3A3A3A;
        background: #272727;
    }

    .contentprofile {
        display: flex;
    }

    .postcontaint {
        width: 100%;
    }


}

@media (min-width: 375px) and (max-width: 424px) {
    /* .createbtn {
        background-color: rgb(126, 38, 228);
        height: 115px;
        border-radius: 50px;
        width: 1200px;
        text-align: center;
        padding-top: 5px;
        font-size: 70px;
        border: none;
        font-family: Inter;
        color: aliceblue;
    } */

    .adventure {
        font-size: 98px;
    }

    .postprofileimg {
        width: 80px;
        height: 80px;
        border-radius: 20px;
    }

    .postUserName {
        font-size: 40px;
    }

    .textarea_comments {
        width: 100%;
    }

    .containtpopup {
        width: 565px;
        max-height: auto;
        flex-shrink: 0;
        border: 1px solid #3A3A3A;
        background: #272727;
    }

    .contentprofile {
        display: flex;
    }

    .postcontaint {
        width: 100%;
    }


}

@media (min-width: 425px) and (max-width: 767px) {
    /* .createbtn {
        background-color: rgb(126, 38, 228);
        height: 115px;
        border-radius: 50px;
        width: 1200px;
        text-align: center;
        padding-top: 5px;
        font-size: 70px;
        border: none;
        font-family: Inter;
        color: aliceblue;
    } */

    .adventure {
        font-size: 98px;
    }

    .postprofileimg {
        width: 80px;
        height: 80px;
        border-radius: 20px;
    }

    .postUserName {
        font-size: 40px;
    }

    .textarea_comments {
        width: 100%;
    }

    .containtpopup {
        width: 565px;
        max-height: auto;
        flex-shrink: 0;
        border: 1px solid #3A3A3A;
        background: #272727;
    }

    .contentprofile {
        display: flex;
    }

    .postcontaint {
        width: 100%;
    }


}




/*  CSS */

@media(max-width:991px){
    .createbtn {
            height: 74px;          
            width: 1332px !important;
            font-size: 28px;
            border-radius: 100px;
    }
    
}
@media (max-width:500px){
    .selectedImage {
        width: 100%;
        height: 1400px !important;
        flex-shrink: 0;
        border-radius: 5px;
    }
    .btn_cls_create{
  
        position: absolute;
        top: 10px;
        right: 20px;
        border-radius: 50px;
        background-color: #FFF;
        color: #fff;
        width: 65px;
        height: 65px;
        font-size: 45px;
   
}
.createbtn {
    height: 123px;
    width: 1456px !important;
    font-size: 57px;
}
}
@media(max-width:320px){
    .createbtn {
        height: 123px;
        width: 1236px !important;
        font-size: 57px;
       
    }
}
@media (max-width:991px) and (min-width:785px) {
    .modal-dialog {
        position: relative;
        width: 100%;
        height: 95%;
        pointer-events: none;
        justify-content: center;
        display: flex;
        margin: 2% auto;
        max-width: none;
    }
    .dashbd-modal-dialog-tp .model-dialog{
        height: auto !important;
    }

    .postprofileimg {
        width: 45%;
        height: 101px;
        border-radius: 20px;
    }

    .postUserName {
        font-size: 51px;
        padding: 9px 0px 12px;
    }
  

}

@media (max-width:1440px) and (min-width:1200px){

    #root {
        width: 1615px;
    }
    .landing-pg-bgs .row{
        margin-right: 0px !important;
    }
    
    .textarea_comments {
        width: 100%;
    }
}
@media (max-width:1200px) and (min-width:991px){

    
    .containtpopup {
        width: 95%;
        height: auto;
    }
    .modal-dialog {
        top: 68px;
        height: 95%;
    }
    .dashbd-modal-dialog-tp .model-dialog{
        height: auto !important;
    }
    .postcontaint {
        width: 100%;
        /* height: 100%; */
    }
    .postcomment {
        width: auto;
        /* height: 100%; */
        min-height: auto;
    }
    .location_input, .hash_tag_input {
        width: 100%;
        height: 47px;
        font-size: 21px;
    }
    .location_icon{
        right: 282px;
    }
    .textarea_comments {
        font-size: 21px;
        padding: 20px 15px;
        width: 100%;
    }
}



@media (max-width:991px) {
    .modal-dialog {
        position: relative;
        width: 93%;
        height: 62%;
        pointer-events: none;
        justify-content: center;
        display: flex;
        margin: 13% auto;
        max-width: none;
    }

    .dashbd-modal-dialog-tp .model-dialog{
        height: auto !important;
    }
    .popupcard-img-top-container {
        display: flow !important;
    }

    .popupcard-img-top {
        width: 100%;
        margin-top: 57px;

    }

    .postcontaint {
        width: 100%;
    }

    .createpostPopup .modal {
        padding-right: 0px !important;
    }

    .containtpopup {
        width: 90%;
    }

    .postcomment {
        width: 100%;
        min-height: 50px;
    }

    .postcomment .textarea_comments {
        width: 96%;
        min-height: 50px;
        font-size: 40px;
        padding: 26px 17px;
    }
    .tag img{
        width: 17px;
    }
    .tag {
        height: 35px;
        font-size: 21px;
        padding: 0px 8px;
    }

    .sending_btn {
        float: right;
        padding-left: 26%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image_container {
        
            width: 100px;
            height: 100px;
            margin: 20px 17px;
    }

    .sendimg {
        float: inline-end;
        width: 95px;
    }

    .location_input,
    .hash_tag_input {
        width: -webkit-fill-available;
        font-size: 29px;
        padding: 27px 20px;
        margin-right: 12px;

    }

    .hash_tag_input {
        font-size: 22px !important;
    }
}

/* 
@media (max-width:500px) {
    .modal-dialog {
        height: 40%;
    }
    .dashbd-modal-dialog-tp .model-dialog{
        height: auto !important;
    }

    .proft-logo-text-center {
        text-align: center;
    }

    #rowcontaint {
        padding-bottom: 32px;
    }
    .hash_tag_input {
        font-size: 37px !important;
        height: 105px !important;
      }
      .location_input{
        font-size: 37px !important;
        height: 105px !important;
      }

} */

@media (max-width: 500px) {
    .modal-dialog {
        height: 100%;
        width: 100%;
        position: fixed;
    }
    .model-dialog-creat-post{
        height: 40%;
        width: 100%;
    }

    .dashbd-modal-dialog-tp .model-dialog {
        height: auto !important;
    }
    .dashbd-modal-dialog-tp .model-dialog-creat-post {
        height: auto !important;
    }

    .proft-logo-text-center {
        text-align: center;
    }

    #rowcontaint {
        padding-bottom: 32px;
    }

    .image_container, .location_container {
        display: flex;
        align-items: center;
    }

    .image_container {
        margin-right: 8px; /* Add some space between image and input */
    }

    .location_input {
       
            height: 100px;
            flex: 1 1;
            font-size: 50px;
    }

    .hash_tag_input {
        font-size: 50px !important;
        height: 100px;
        width: auto !important;
    }

    .submitbutton2{
        margin-left: 800px;
       
    }

    .sending_btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tag {
        height: 85px;
        font-size: 51px;
        padding: 0px 28px;
    }
    .textarea_comments{
        font-size: 51px !important;
    }

    /* .location_suggestions {
        position: absolute;
        background-color: white;
        border: 1px solid #ccc;
        width: 100%;
        z-index: 1000;
        font-size: 145px;
    } */

    


   
}


@media (max-width:375px){
    /* .location_suggestions {
      
        font-size: 100px;
      } */
}