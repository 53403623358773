.post_popup_container5 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 100; /* Make sure this is above all other content */
  }
  
  .post_popup5 {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .post_popup_image5 {
    max-width: 100%;
    max-height: 100%;
    display: block; /* Remove extra space below the image */
  }
  

  .disc1 {
    /* color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    max-width: 290px;
    height: 44px;
    flex-shrink: 0;
    margin-left: 25px;
    padding-bottom:15px;
    margin-top: 5px; */
    margin-left: 25px;
    padding-bottom: 15px;
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    width: 90%;
    height: 70px;
    max-height: 250px;
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: thin;
    /* scrollbar-color: transparent transparent; */
    /* scrollbar-color: #714BDC #272727;  */
}
.disc1 span {
  /* width:150px */
}
  .popup-note {
    /* background-color: #2b2a2a;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
    height : 520px;
    width : 489px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent; */

    width: 489px;
    height: 520px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: #2b2a2a;
    color: #fff;
    padding: 5px;
    font-family: Inter;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;


  }
  .popup_close_btn5 {
    position: absolute;
    top: -10px;
    right: -10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .popup_close_btn5 img {
    width: 24px;
  }
  
  @media(max-width:500px){
    .disc1 {
      font-size: 51px;
    }
    .profilelogo1{
      
        width: 100px;
        height: 100px;
    
    }

  }