.myFilesTitle {
  margin-bottom: 35px;
}

.AddIcon {
    position: relative;
    margin-left: 2.5rem;
}

.myFilesAddItem {
  width: 322px;
}

.main-layout {
  display: flex;
  align-items: start; /* Align items at the start of the container */
  gap: 20px; /* Space between the button and UserPost component */
}
.board_openpopup {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
 
.dropdown-content {
    position: absolute;
    background-color: #3F3F3F;
    width: 180px;
    flex-shrink: 0;
    left: 15rem; /* Position from the left */
    top: -110px; 
    border-radius: 5px;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.dropdown-item,
.dropdown-item label {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
  }
  .dropdown-item label {
    padding: 0px !important;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
  }
  .dropdown-item:hover {
      border-radius: 4px;
      background: #272727;
      flex-shrink: 0;
      cursor: pointer;
  }

  .dropdown-item img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    
  }
 
  .dropdown-item span,
  .dropdown-item label span {
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   
  }

  /* ... existing styles ... */


  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;

  }

  .popup-content {
    width: 100%; /* Use 100% to fill the popup */
    display: flex;
    padding-top: 0;

    flex-direction: column;
    align-items: start;
    justify-content: space-around; /* Adjust based on your design */
  }
  .horizontal-line {
    width: 100%; /* This should match the popup's content area */
    border-top: 1px solid #3A3A3A; /* Creates the horizontal line */
    margin-bottom: 20px; 
    margin-left: 0px;
  }

  .url-input {
    width: 539px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 35px;
    background: #3D3D3D;
    border: none; /* Remove default border */
    padding: 0 20px; /* Add some padding inside the input */
    color: white; /* Set the text color */
    margin: 20px 0; /* Add some margin around the input */
   
  }
  
  .url-input::placeholder {
    color: #C7C7C7; /* Placeholder text color */
  }
  .close-button{
    width: 32px;
    height: 32px;
    background: #adabab;
    color: #000;
    position: absolute;
    top: 2px;
    right: 6px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in-out;
  }
  .close-button:hover{
    color: #ccc;
  }

  /*  CSS */
  @media (max-width:1200px){
    .main-layout {
      display: block;
    }
    .addpostopen_popup, .postcard{
      width: 100% !important;
    }
    .postcard {
      max-height: 350px !important; 
    }
    .addpostopen_popup {
      max-height: 350px;
    }
    .card-image {
      width: 120% !important;
    }
  }