



/* 2134567890-=0987654323134567890-= */

.heading {
    width: 100%;
    height: 72px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 50px;

}


.back-button {
    padding-top: 45px;
    left: 10px;
    font-size: 46px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: none;
}



.inputfield {
    width: 515px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 51px;
    background: #272727;
    color: white;
    padding: 30px;
    border: none;

}
.image_backButton{
    width: 30;
    height: 60px;
}
.sifnup_fixedNav {
    position: fixed;
    background-color: #131313;
    width: 97%;
    height: 122px;
    border-bottom: 1px solid #3A3A3A;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.profile_Upload {
    background: none;
    border: none;
}


.hidden {
    display: none;
}

.profile_Upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.signupbutton {
    width: 296px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 49px;
    background: #714BDC;
    border: none;
    color: #FFF;
    font-weight: 600;
}

.image_selcet_option {
    height: 100%;
}



.upload_Img {
    width: 160px;
    height: 160px;
    flex-shrink: 0;
    fill: #272727;
    stroke-width: 4px;
    stroke: #FFF;
    border: white solid 4px;
}

.background-image {
    position: absolute;
    top: 230px;
    left: 680px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selected_image {
    width: 152px;
    height: 152px;
    object-fit: cover;

}

.signupprocess {
    background-color: black;
    /* width: 1520px; */

}

.plus-symbol {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 48px;
    font-weight: bold;
    pointer-events: none;
}

.Signup_padding {
    padding: 85px;
    padding-top: 85px;
}

.Signup_error_msg {
    color: red;
    font-size: 12px;
}

.UsereName_padding {
    padding: 175px;
}

.image_selcet_option {
    padding: 170px;
}
@media(max-width:1220px){
    .signupprocess {
        
        width: 1520px;
    
    }
}

@media (max-width:991px) {

    .sifnup_fixedNav {

        height: 200px;

    }
    .signupprocess .heading{
        margin-bottom: 20px;
    }


    .signupprocess {
        width: 1520px;
    }

    .Signup_footer {
        width: 100%;
    }

    .Signup_padding,
    .UsereName_padding,
    .image_selcet_option {
        padding: 350px;
    }

    .inputfield {
        width: 583px;
        height: 90px;
        font-size: 45px;
    }

    .inputfield::placeholder {
        font-size: 45px;
    }

    .input[type="text"] {
        font-size: 45px;
    }

    .heading {

        height: 72px;
        margin-top: 95px;
        font-size: 55px;
    }

    .signupbutton {
        width: 330px;
        height: 90px;
        font-size: 45px;
        margin-top: 25px;
    }

    .Signup_error_msg {
        font-size: 22px;
    }

    .sifnup_fixedNav img {
        height: 100px;
    }




}

@media(max-width:500px) {

    .Signup_padding,
    .UsereName_padding,
    .image_selcet_option {
        padding: 350px;
    }
    .signupprocess .heading{
        margin-bottom: 65px;
        width: 100% !important;
    }

    .sifnup_fixedNav {

        height: 200px;

    }

    .UsereName_padding{
        padding: 294px !important;
            }

    .sifnup_fixedNav img {

        height: 100px;



    }

    .selected_image {
        width: 300px;
        height: 300px;
        object-fit: cover;
    
    }
    /* .rounded-circle{
        width: 300px;
        height: 300px;
    } */


  
  

 
    

}

@media(max-width:320px) {

    .Signup_padding,
    .UsereName_padding,
    .image_selcet_option {
        padding: 350px;
        left: 0;
        bottom: 0;
    }

    .sifnup_fixedNav {

        height: 200px;

    }

    .sifnup_fixedNav img {

        height: 100px;

    }

}










