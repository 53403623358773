    /* .Additem {
        width: 275px;
        height: 291.058px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #272727;
        justify-content: center;
    } */

    .AddIcon {
        color: #B2B2B2;
        font-family: "Inter", sans-serif;
        font-size: 22px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: initial;

    }



    .container-1 {
        width: auto;
        margin: 20px auto;
        columns: 4;
        column-gap: 20px;

        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        padding: 15px;
    }


    .modalbody {
        width: 376px;
        height: auto;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #3A3A3A;
        background: #272727;

    }


    .modelcard {
        width: 337px;
        height: 337px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #3A3A3A;
        align-items: center;
    }


    .cancelbutton {
        width: 112px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 55px;
        background: #3A3A3A;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        margin-top: 7px;

    }

    .createbutton {
        width: 193px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 55px;
        background: #714BDC;
        color: #FFF;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        margin-top: 7px;

    }


    .Additem {
        width: 318px;
        height: 350px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #272727;
    }

    .new_board_Name {
        border: none;
        outline: none;
        color: #B2B2B2;
        font-family: "Inter", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #272727;
        margin: 5px;

    }

    .board_containtpopup {
        width: 376px;
        height: 478px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #3A3A3A;
        background: #272727;
    }

    .board_openpopup {
        background-color: #272727;
        border: none;
        margin-top: 125px;
        color: white;
    }

    .board_postcontaint {
        width: 337px;
        height: 337px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #3A3A3A;
    }


    .board_uploadimg {
        color: #B2B2B2;
        /* font-family: Inter; */
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        /* margin-top: 40%; */

    }

    .board_uploadcoverimg {
        transform: translate(-50%, -50%);
        z-index: 1;
        position: absolute;
        top: 45%;
        /* Adjust position as needed */
        left: 50%;
        color: white;
    }

    .board_cancelbtn {
        width: 122px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 55px;
        background: #3A3A3A;
        color: white;
        text-align: center;
        margin-right: 10px;
    }

    .board_createbtn {
        width: 203px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 55px;
        background: #714BDC;
    }

    .boardselectedimage {
        width: 337px;
        height: 337px;
        flex-shrink: 0;
        border-radius: 15px;
        border: none;
        object-fit: cover;
        background: center;
        background-size: cover;
        display: block;

    }

    @media (min-width: 1400px) {
        .container {
            max-width: 2100px;
        }
            #staticBackdrop1 {
                padding-right: 0px !important;
            }
    }

    /*  CSS */

    @media (max-width:1024px) {
        #staticBackdrop1 {
            padding-right: 0px !important;
        }

        .board_containtpopup {
            width: 100%;
            height: 600px;
        }

        .modal-dialog {
            max-width: 84%;
            margin: 0 auto;
        }

        .board_postcontaint {
            width: 100%;
            height: 85%;
        }

        .cancelbutton,
        .createbutton {
            margin-top: 25px;
        }
    }

    @media (max-width:991px) {


        .board_containtpopup {
            width: 100%;
            height: 85%;
        }

        .board_postcontaint {
            width: 100%;
            height: 90%;
        }

        .cancelbutton {
            width: 123px;
            height: 48px;
            margin-top: 20px;
            font-size: 21px;
        }

        .createbutton {
            width: 195px;
            height: 48px;
            margin-top: 20px;
            font-size: 21px;
        }

        .board_uploadcoverimg {
            font-size: 22px;
        }

        .new_board_Name {
            font-size: 32px;
        }
    }


    @media (max-width:500px) {
        .new_board_Name {
            font-size: 42px;
        }
 
        .board_uploadcoverimg {
            font-size: 33px;
        }
 
        .cancelbutton {
            width: 253px;
            height: 120px;
            margin-top: 35px;
            font-size: 53px;
            letter-spacing: 3px;
        }
 
        .createbutton {
            width: 231px;
            height: 120px;
            margin-top: 35px;
            font-size: 53px;
            letter-spacing: 3px;
        }
 
        .board_postcontaint {
            width: 100%;
            height: 87%;
        }
 
        .board_containtpopup {
            width: 100%;
            height: 1400px;
        }
 
        .boardselectedimage {
            width: 100%;
            height: 170vh;
            z-index: 99;
            position: relative;
            overflow: hidden;
            object-fit: cover;
 
        }
 
    }


    /* @media (max-width:475px) {} */