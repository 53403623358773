.editPopup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.editPopup_overlayText {
  position: absolute;

  left: 0;
  width: 100%;
  padding: 10px 0;
  
  /* background: rgba(0, 0, 0, 0.5); Semi-transparent overlay */
  color: white;
  text-align: center;
  font-size: 16px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
 
.editPopup_uploadText {
  color: white;
  text-align: center;
  font-size: 16px;
}
.editPopup_content {
  background-color: #272727; /* This color will be the background of your modal */
  border-radius: 15px; /* Added for rounded corners on the modal */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* This adds a subtle shadow to your modal */
  max-width: 500px;
  width: auto; /* Changed to auto to allow the content to dictate the width */
  border: 1px solid #3A3A3A;
}
 
.editPopup_modalHeader {
  margin-bottom: 20px;
}
 
.editPopup_input {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  border:none;
  font-size: 16px;
 background-color: #272727;
  color: #B2B2B2;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
 
.editPopup_imageContainer {


  position: relative; /* Anchor for absolute positioning */
  width: 337px; /* Fixed width */
  height: 337px; /* Fixed height */
  border-radius: 15px;
  border: 1px solid #fff8f7;
  background: #272727;
  margin-bottom: 20px; /* Space below container */
  overflow: hidden; /* Hide overflow */
  cursor: pointer; /* Indicates this is clickable */
  display: flex; /* Use flex to center content */
  align-items: center; /* Center content vertically */
  justify-content: center;
}
 
.editPopup_selectedImage {
  width: 100%;
  width: 337px;
  height: 337px;
 
}
 
.editPopup_hidden {
  display: none;
}
 
.editPopup_uploadLabel {
  cursor: pointer;
  background-color: #ddd;
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  width: 337px;
  height: 337px;
  border-radius: 15px;
background: #3A3A3A;
  flex-shrink: 0;
}
 
.editPopup_buttons {
  display: flex;
  justify-content: space-between;
}
 
.editPopup_cancelButton,
.editPopup_createButton {
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
 
.editPopup_cancelButton {
  width: 122px;
  height: 37px;
  flex-shrink: 0;
  border-radius: 55px;
background: #3A3A3A;
color: #B2B2B2;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
 

 
.editPopup_createButton {
  border-radius: 55px;
  background: #714BDC;
  color: var(--icon-color, #FFF);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  width: 203px;
height: 37px;
margin-left: 15px;
flex-shrink: 0;
}


/*  CSS */
@media (max-width:1200px) and (min-width:991px){
  .editPopup_content {
    width: 27% ;
    max-width: none;
  }
  .editPopup_imageContainer {
    width: 100%;
  }
  .editPopup_imageContainer .editPopup_selectedImage{
    width: 100% !important;
    height: 100% !important;
  }
  
}

@media (max-width:991px){

  .editPopup_content {
    width: 35%;
    height: 769px;
    max-width:none;
  }
  .editPopup_overlay {
    top: -30%;
    bottom: 14px;
  }
  .editPopup_imageContainer {
    width: 95%;
    height: 450px;
  }
  .editPopup_input {
    font-size:44px;
  }
  .editPopup_uploadText {
    font-size: 38px;
  }
  .editPopup_cancelButton{
    padding: 13px 33px 20px;
        font-size: 33px;
        width: auto;
        height: 68px;
  }
  .editPopup_createButton{
    padding: 13px 45px 20px;
    font-size: 33px;
    width: auto;
    height: 68px;
  }


}

@media (max-width:500px){
  .editPopup_content {
    width: 95%;
    height: 88%;
    max-width: none;
  }
  .editPopup_overlay {
    top: 8%;
    bottom: 14px;
    display: block;
    left: 40px;
    width: 100%;
  }
  .editPopup_imageContainer {
    width: 100%;
    height: 247vh;
}
  .editPopup_input {
    font-size: 68px;
    height: 100px;
  }
  .editPopup_uploadText {
    font-size: 45px;
  }
  .editPopup_cancelButton{
    padding: 13px 45px 20px;
    font-size: 51px;
    width: auto;
    height: 125px;
    margin-right: 37px;
    border-radius: 100px;

  }
  .editPopup_createButton{
    padding: 13px 45px 20px;
    font-size: 51px;
    width: auto;
    border-radius: 100px;
    height: 125px;
  }
  .editPopup_imageContainer .editPopup_selectedImage{
    width: 100% !important;
    height: 100% !important;
  }
}

@media(max-width:400px){
  .editPopup_content {
   
    height: 82%;
   
  }
}

@media (max-width:380px){
  /* .editPopup_imageContainer {
  width: 98%;
  height: 550px;
} */

.editPopup_imageContainer .editPopup_selectedImage{
  width: 100% !important;
  height: 100% !important;
}
.editPopup_content {
  width: 95%;
  height: 80%;
  max-width: none;
}
}
