.popup-backdrop {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 142%;
    background-color: transparent;
    z-index: 100;
  
  }
  .popup-icon{
    width: 25px;    
    padding: 5px;
    margin-left: -20px;
    
  }
  .popup-content-image {
    max-height: 150px;
    overflow-y: auto;
    background-color: #2B2B2B;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 8px;
    position: absolute;
    top: auto;
    bottom: 1;
    /* left: 50%; */
    transform: translateX(-50%) translateY(-100%);
    width: fit-content;
    min-width: 200px;
    font-size: 16px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* margin-bottom: -85px; */
    /* margin-left: -30px; */
  }
   
  .popup-content::-webkit-scrollbar {
    display: none;
  }
   
  .popup-item {
    padding: 8px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Inter;
    color: #FFFFFF;
    color: var(--icon-color, #FFF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
    
  }
   
  .popup-bucketlist-icon {
    width: 16px;
    height: auto;
    margin-right: 8px;
  }
   
  /* .popup-board-circle {
    margin-left: -20px;
    width: 15px;
    height: 15px;
    background-color: #6f42c1;
    border-radius: 50%;
    margin-right: 8px;
  }
   
  .popup-item-checkmark {
    color: white;
    font-size: 14px;
  }
   
  .popup-item-checkmark {
    font-size: 16px;
  } */
   
  .popup-board-circle {
    width: 17px; /* Adjust as needed */
    height: 17px; /* Adjust as needed */
    background-color: #6f42c1; /* Purple background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: -15px;
  }
  .add-to-new-board {
    display: flex;
    align-items: center;
  }
 
  .add-to-new-board-btn {
    width: 14px; /* Adjust as needed */
    height: 14px; /* Adjust as needed */
    background-color: #424344;
    color: #ffffff;
    /* border: none; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -14px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px; /* Add some spacing between the button and text */
    border-color: #fff;
    border: 3px solid white;
  }
   
  .popup-board-circle.selected .popup-item-checkmark {
    display: block; /* Show the checkmark when selected */
  }
   
  .popup-item-checkmark {
    display: none; /* Hide by default */
    color: white;
    /* Adjust the size as needed or according to the FontAwesomeIcon size prop */
  }
   
 

  .your-popup-class {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
  
    background-color: white; /* Background color */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  
    display: block; /* Show the popup */
  }

  @media (max-width:500px){
    .popup-item span{
      font-size: 30px;
    }
    .popup-content-image {
      min-width: 450px;
      max-height: 224px;
      padding: 20px 0px 20px 15px;
    }
    .add-to-new-board span{
      font-size: 33px !important;
    }
  }
 
  