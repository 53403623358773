.d-flex.flexWrapItems {
    flex-wrap: wrap;
    padding: 0;
}

.d-flex.flexWrapItems>div {
    width: 310px;
    height: 350px;
    flex-shrink: 0;
    position: relative;
    margin: 5px;
}

.image-container {
    position: relative;
    margin-top: 0;
    overflow: hidden;
    border-radius: 15px;
}

.bimage {
    width: 340px;
    height: 350px;
    /* Adjust based on your requirement */
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top-bar {
    display: flex;
    align-items: center;
    padding: 10px 15px 0 15px;
}

.overlay .top-bar img {
    margin: 5px;
    width: 20px;
    height: 20px;
}

.top-left {
    display: flex;
    align-items: center;
    /* This ensures vertical alignment is centered */
}

.top-right {
    display: flex;
    align-items: center;
    /* Ensures items in this div are also vertically centered */
}

.top-bar .like-btn,
.top-bar .bucket-list-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-name {
    width: 120px;
    border-radius: 50%;
    margin-right: 10px;
    flex: 1;
}

.user-logo {
    width: 50px;

    border-radius: 50%;
    margin-right: 10px;
}




.profile-details {
    display: flex;
    align-items: start;
    /* Aligns items to the top */
}





.location-info {
    font-size: 12px;
    margin-bottom: auto;
    margin-left: 15px;
    border-radius: 50px;
    background: rgba(50, 50, 50, 1);
    backdrop-filter: blur(2px);
    padding: 2px 10px;
    /* display: inline-block; */
    width: fit-content;
}

.public_board_title {
    font-size: .8rem;
    display: inline-block;
    width: fit-content;
    /* Adjusts the width based on the content */
    margin-left: 15px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.675);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    padding: 2px 10px;
    margin-bottom: 15px;
    color: #272727;
    font-family: Inter;
    font-weight: 600;
    line-height: normal;
}

.top-right {
    display: flex;
    align-items: center;
    /* Ensures vertical center alignment with the left side */
    margin-left: auto;
    /* Pushes to the right */
}

.like-btn,
.bucket-list-btn {
    margin-left: 10px;
    /* Space between buttons */
    font-weight: bold;
}

.blank-card {
    width: 322px;
    /* Same width as other cards */
    height: 350px;
    /* Same height as other cards */
    background-color: #272727;
    /* Background color of the card */
    border-radius: 15px;
    /* Border radius of the card */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.blank-card p {
    color: #B2B2B2;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #272727;
    border: none;
}


/*  CSS */


@media (max-width:1024px) and (min-width:991px){
    .landing-pg-bgs {
        width: 1717px;
    }
}

@media (max-width:1024px) {
    .d-flex.flexWrapItems>div {
        width: 47%;
    }

    .bimage {
        width: auto;
    }

    .blank-card {
        width: auto;
    }

    .user-logo {
        width: 50px !important;
        height: 50px !important;
    }

    .overlay .top-bar img {
        margin: 5px;
        width: 30px;
        height: 30px;
    }

    

    .bimage {
        width: 666px;
    }

    .user-name {
        font-size: 23px;
    }

    .public_board_title {
        font-size: 20px;
        padding: 12px 10px;
    }
}



@media (max-width:991px) {

    .public_board_title {
        font-size: 20px;
        padding: 12px 10px;
    }

    .user-name {
        font-size: 30px;
    }
    .d-flex.flexWrapItems>div {
        width: 47%;
        height: 450px;
    }
    .bimage {
        height: 450px;
    }
    .blank-card {
        height: 450px;
    }


}

@media (max-width:500px){
    .user-name {
        font-size: 43px;
    }
    .user-logo {
        width: 85px !important;
        height: 85px !important;
    }
    .blank-card p {
        font-size: 41px;
    }
    .public_board_title {
        font-size: 38px;
    }
    .overlay .top-bar img {
        margin: 5px;
        width: 45px;
        height: 45px;
    }
}


@media (max-width: 375px) {

}