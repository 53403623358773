.custom-toast {
    background-color: #333 !important;
    color: #fff !important;
    text-align: center !important;
    font-size: 1.2rem !important;
    padding: 16px !important;
  }
  
  /* Media query for mobile screens */
  @media(max-width: 500px) {
    .custom-toast {
        font-size: 2.5rem !important;
        width: 100% !important;       
        max-width: fit-content;
        left: 80% !important;
        align-items: center;
        text-align: center;
        transform: translateX(50%) !important;
    }


  }
  