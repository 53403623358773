.newNotePopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  /* Ensure it's above other content */
}

.newNotePopup {
  width: 839px;
  height: 95%;
  border-radius: 15px;
  background: #272727;
  border: 1px solid #3A3A3A;
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1051;
  /* Ensure it's above the overlay */
}


.newNotePopupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.leftGroup {
  display: flex;
  align-items: center;
}

.userProfile {
  display: flex;
  align-items: center;
}

.userProfileImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  padding: 5px;
}

/* Make sure .location is styled to align to the right as needed */
.notelocation {

  color: #FFF;
  background-color: rgb(134, 136, 137);
  border-radius: 15px;
  padding: 3px 9px;
  font-size: 12px;
  font-family: Inter;
  margin-right: 20px;


}

.notelocation img {
  padding: 5px;
}

.noteInput {
  width: 784px;
  height: 481px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #131313;
  border: none;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  font-family: Inter;
}

.newNotePopupActions {
  display: flex;
  justify-content: space-between;
  cursor: pointer;



}

.action-button {
  display: flex;
  flex-direction: column;
  /* Stack icon and text vertically for the delete button */
  align-items: center;
  /* Center the content for the delete button */
  justify-content: center;
  border: none;
  background-color: #272727;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
}




.newNotePopupActions button {
  border: none;
  background-color: #272727;
  font-size: 14px;
  cursor: pointer;
}

.newNotePopupActions button:hover {
  color: #fff;
}


.newNotePopupToolbar button {
  margin-right: 10px;
}

.Save_BucketList {
  border-radius: 55px;
  background: #714BDC;
  padding: 6px 12px;
  width: 203px;
  height: 43px;
  flex-shrink: 0;
  color: #FFF;
  border: none;
}

.noteTitleInput {
  color: var(--icon-color, #ffffff);
  text-align: center;
  padding: 5px 9px;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 25px;
  background: #131313;
  border: none;
}


.Bucketlist_button {
  border-radius: 55px;
  background: #3D3D3D;
  color: #6A6A6A;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 69px;
  border: none;
  width: 203px;
  height: 43px;
  flex-shrink: 0;
  padding: 9px 18px;
}


.newNotePopup {
  position: relative;
  /* ... other styles for the popup */
}

.close-button {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  border: none;
  background: #e1dede;
  border-radius: 15px;
  color: #fff;
  /* Adjust color to match design */
  font-size: 24px;
  /* Adjust size as needed */
  cursor: pointer;
  padding: 5px;
  /* Removes padding from the button if any */
}

/* If using an image for the close button */
.close-button img {
  width: 24px;
  /* Adjust width as needed */
  height: 24px;
  /* Adjust height as needed */
}


.add_Note_Toboard_popup {
  /* background-color:  #3A3A3A; */
  border-radius: 8px;
  padding: 20px;

  width: 300px;
  cursor: pointer;
  left: 0;
  right: 0;
  scrollbar-width: 1px;
}

.addboardoverlay {

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  position: fixed;
  /* Positioning relative to the viewport */
  top: 58%;
  /* left: ; */
  margin-left: 95px;
  max-width: 350px;
  height: 200px;
  background-color: #3A3A3A;
  /* display: flex;      */
  justify-content: center;
  color: #fff;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
}


/*  CSS  */
@media (max-width:1024px) {
  .newNotePopup {
    height: 95%;
  }
}

@media (max-width:1200px) {
  .newNotePopupOverlay {
    width: 100%;
    height: 88vh;
}
.noteInput {
  height: 65%;
}

}

@media (max-width:991px) {
  .newNotePopup {
    height: 95%;
    padding-top: 80px;
    width: 90%;
  }

  .newNotePopupOverlay {
    top: 97px
  }
  .noteTitleInput {
    padding: 22px 9px;
  }
  .noteInput {
    width: 100%;
    height: 450px;
    font-size: 23px;
  }

  .newNotePopupOverlay {
    height: 133vh;
  }

  .newNotePopupActions button {
    font-size: 28px;
  }


}



@media (max-width:500px){
  .newNotePopupOverlay {
    height: 147vh;
}

.newNotePopupHeader {
 
  width: 100%;
}

.userProfileImage {
  
    width: 150px;
    height: 150px;
    border-radius: 50%;

}
.noteTitleInput {
  padding: 22px 9px;
  font-size: 47px;
  width: 75% !important;
  margin: 0px 90px;

}
.newNotePopup {
  margin-top: 100%;
  width: 97%;
}
.newNotePopup {
  height: 2500px;
}
.noteInput {
  width: 100%;
  height: 70%;
  font-size: 51px;
  font-family: Inter;
}
.newNotePopupActions {
  /* display: flex; */
  justify-content: space-between;
  cursor: pointer;
} 
.add_Note_Toboard_popup {
  /* background-color: #3A3A3A; */
  border-radius: 8px;
  padding: 20px;
  width: max-content;
  cursor: pointer;
  left: 0;
  right: 0;
  scrollbar-width: 1px;
}
.close-popup-1{
  top: 50px;
  right: -400PX;
  padding: 7px 22px;
  font-size: 51px;
}

/* .addboardoverlay {
 
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    position: fixed;
    top: 58%;
    margin-left: 190px;
    width: 534px !important;
    height: 18%;
    background-color: #3A3A3A;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    z-index: 1000;
    border-radius: 15px;
    align-items: center;
    cursor: pointer;

} */
.addboardoverlay {
  width: 90%;
  margin-left: 5%;
  height: 18%;
  font-size: 51px;
}
}