body {
    padding: 0px;
    overflow-x: hidden;
    /* overflow-y: scroll !important; */
}
.modal-backdrop.show {
    opacity: 0 !important;
    position: relative !important;
    overflow-y: scroll !important;
    width: 100% !important;
    padding-right: 0px !important;
    height: auto !important;
}
.modal-open{
    padding-right: 0px !important;
    overflow-y: scroll !important;
}
.modal-backdrop{
    overflow-y: scroll !important;
}

.dashboard-width {
    width: 100%;
    left: 0;
    right: 0;

}

.navhamberger {
    margin-right: 65px;
}

.create_font{
    font-weight:900;
    cursor: pointer;
    font-size: 13px;
}

.nav_color {
    background-color: #131313;
    border: 0.5px solid #3A3A3A;
    justify-content: space-between;
    align-items: center;
    height: 122px;
    padding: 30px;
    /* width: 1520px; */
}

.imagesize {
    width: 194px;
    height: 46px;
    margin-top: 8px;
    margin-left: 60px;
}

/* .socialimage {
    margin-top: -5px;
    margin-left: -20px;
} */


.containedWrapper {
    justify-content: space-between;
    align-items: center;
}

.containedWrapper {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 15px;
}

.containedWrapper.d-flex .imagesize {
    margin-top: 0 !important;
    margin-left: 0 !important;
}

.signup {
    background-color: #714BDC;
    width: 93px;
    Height: 38px;
    margin-top: 8px;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 700;
    font-family: Inter;
    color: #ffff;
    border: none;
}

/* .signup{
    margin-top: 8px;
    border-radius: 27px;
    font-family: Inter;
    font-size: 36px;
    color: #fff;
    background-color: #714BDC;
    font-weight: 700;
    padding: 20px 40px;
    text-align: center;
} */

.login {
    width: 93px;
    Height: 38px;
    margin-top: 8px;
    border-radius: 27px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    color: #ffff;
    background-color: #131313;
    border: none;

}

/* .login{
    margin-top: 8px;
    border-radius: 27px;
    font-family: Inter;
    font-size: 36px;
    color: #fff;
    background-color: #131313;
    font-weight: 700;
    padding: 20px 40px;
    text-align: center;

} */

.image-container {
    margin: 0px;
    padding: 0;
    break-inside: avoid-column;
    width: 100%;
    background-color: #272727;
    columns: 4;
    margin-top: -25px;
}

.image-wrapper {
    max-width: 100%;
}

.image-wrapper .full-width {
    width: 100%;
    max-height: auto;
    border-radius: 15px;
    object-fit: contain;
}




.usertags {
    float: inline-start;
    left: 25px;
    top: 55px;
    width: 192px;
    padding: 5px;
    height: 33px;
    flex-shrink: 0;
    color: #FFF;
    /* box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25); */
    /* backdrop-filter: blur(2px); */
    position: relative;
}

.likecount {
    margin-left: 190px;
    display: flex;
    font-weight: 600;
    font-family: "Inter", sans-serif;

}

.imagetag {
    text-decoration: none;
    color: #ffffff;

}

.profile-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: rgb(0, 0, 255);
}

.margin_in_between {
    margin-right: 60px;
}

.backgroung_image {
    background-color: #272727;
}



.background-container {
    position: relative;
    width: 1520px;
    height: 70vh;
    background-image: url('./../../Assets/Images/Trackmarker.png');
    background-color: #272727;

    color: white;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;

}

.left-card {
    width: 254px;
    height: 412px;
    top: 159px;
    border-radius: 15px;
}

.img-width {
    width: 200px;
    height: 412px;
    top: 159px;
}



.plan_text {
    width: 655px;
    height: 171px;
    top: 231px;
    left: 429px;
    font-family: Inter;
    font-size: 56px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: center;
}

.inspiration {
    width: 536px;
    height: 54px;
    top: 386px;
    left: 487px;
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.continue_button {
    background-color: #714BDC;
    width: 364px;
    height: 60px;
    flex-shrink: 0;
    margin-top: 34px;
    border-radius: 49px;
    font-size: 16px;
    font-weight: 700;
    font-family: Inter;
    color: #ffff;
    border: none;
    white-space: nowrap;
}

.explore-heading {
    margin-top: 25px;
    width: 544px;
    height: 54px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.explore-text {
    width: 445px;
    height: 77px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
}

.allcards {
    padding: 50px;
}

.textcolor {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 732px;
    height: 54px;
    flex-shrink: 0;
}

.helptext {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 732px;
    height: 54px;
    flex-shrink: 0;
}

.image-center {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}


.background-login {
    background: #272727 url('./../../Assets/Images/waterfall-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    max-height: 1650px;
    height: 1200px;
    width: 100%;

}

.waterfall {
    margin-left: 60px;
    filter: drop-shadow(5px 4px 100.4px rgba(255, 255, 255, 0.39));
    width: 380px;
    max-height: 400px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #D9D9D9;
}

.imagebackground {
    width: 282px;
    height: 282px;
    flex-shrink: 0;
    border-radius: 282px;
    background: rgba(255, 255, 255, 0.30);
}

.imagebackground-group {
    width: 292px;
    height: 292px;
    flex-shrink: 0;
    border-radius: 282px;
    background: rgba(255, 255, 255, 0.30);
}

.input-width {
    width: 364px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #3A3A3A;
    background: #131313;
    align-items: center;
    color: #D9D9D9;
}
.input-width:focus{
    background: #131313;
    color: #fff;
}

.welcometext {
    color: #ffffff;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.createcontact {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: Inter;
}

.input-width::placeholder {
    color: #D9D9D9;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.input-width[type="date"] {
    color: #D9D9D9;
    
}

.logincard {
    flex-shrink: 0;
    border-radius: 15px;
    border: 0.5px solid #3A3A3A;
    background: #272727;
}

.acknowledge {
    color: #FFF;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: normal;
    margin-top: 5px;
    margin-bottom: 15px;
}

.member {
    color: #FFF;
    font-family: Inter;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 293px;
}

.planning {
    /* width: 50%; */
    flex-shrink: 0;
    /* padding: 95px; */
    color: #FFF;
    align-items: center;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    margin-top: 25px;
    line-height: normal;
    margin-left: 245px;
}

.mobile-menu {
    position: absolute;
    padding: 42px;
    z-index: 999;    
    /* background: #373737; */
    
    
}

.startPlanningWrapper {
    width: 100%;
    max-width: 1220px;
    height: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.startPlanningWrapper .iconWrapper,
.startPlanningWrapper .logincard {
    width: 100%;
    max-width: 450px;
}

.startPlanningWrapper .iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.startPlanningWrapper .iconWrapper h4 {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 40px;
}

.startPlanningWrapper .iconWrapper .icons {
    width: 85%;
    height: 480px;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    filter: brightness(1.75);
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    padding: 20px;
}

.startPlanningWrapper .iconWrapper .icons img {
    height: 20px;
    width: auto;
    margin-right: 15px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 3px black);
}

.error_message {
    color: red;
    font-size: 15px;
    /* margin-top: 25px; */
}


/* Extra small devices (phones) */

/* @media only screen and (max-width: 600px) {

    .signup,
    .login {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .containedWrapper {
        padding: 0 10px;
    }

    .imagesize {
        width: 150px;
        height: 108px;
        margin-top: 8px;
        margin-left: 20px;
    }

    .background-login,
    .background-container {
        background: none !important;
        Ensures no background images
    }
    

   
} */

/*  CSS */
@media (max-width:768px){
    .mobile-menu {
        /* bottom: 420px; */
        padding:1%;
        /* background-color: #3A3A3A; */
    }
    .background-login {
        height: auto;
    }
}
@media (max-width:764px){
    .acknowledge {
        font-size: 19px;
        line-height: normal;
    }

    .create_font{
        font-weight:900;
        font-size: 42px;
    }
}
@media (max-width:600px){
    .acknowledge {
        font-size: 23px;
        line-height: 53px;
    }

   

}

@media (max-width:375px){

    .dashboard-width {
        /* width: 1280px !important; */
    }

    .mobile-menu {
      
        top:205px;
        left: 852px;
       

    }
    .create_font{
        font-weight:900;
        font-size: 42px;
    }
    
}

@media (max-width:370px) {
    .box .rectangle {
        width: 1280px !important;
    }
   

    .nav_color {
        width: 1280px;
    }

    .dashboard-width {
        width: 1280px !important;
    }

    .explore {
        /* margin: 20px !important; */
        max-width: 1280px !important;
    }

    .explorecard,
    .explorecard img {
        width: 94% !important;
    }

    .explorecard p,
    .createcard p,
    .help-card p {
        width: 100% !important;
        margin-left: auto !important;
        padding: 0px 50px;
    }

    .help-card h2 {
        font-size: 86px !important;
        width: 100% !important;
        margin-left: auto !important;
    }

    .createcard img {
        width: 100% !important;
    }

    .createcard {
        width: 94% !important;
    }

    .createcard img {
        width: 94%;
    }

    .help-container {
        width: 94% !important;
    }

    .startPlanningWrapper {
        height: auto !important;
        width: 100% !important;
        max-width: 1280px !important;
    }
    .startPlanningWrapper .logincard {
        margin-left: 0px !important;
    }
    .navhamberger {
        margin-right: 45px !important;
    }
    
    .empty-grid-content p {
        width: 60% !important;
        color: var(--icon-color, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 42px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0;
    }
    .empty-grid-content h1 {
        /* margin-top: 205px; */
      
        width: 90% !important;
        font-size: 86px !important;
    }
        .grid-container {
        height: 600px;
        max-width: 138%;
        display: grid;
        grid-template-columns: none !important;
        grid-auto-rows: 391px 258px 260px;
        grid-gap: 0px;
        padding: 0px;
        height: 800px;
    }
    .empty-grid-content h1 {
        height: auto !important;
        padding-bottom: 20px !important;
    }

}
@media (maxx-width:475px){
    .footer{
        margin-top: 145vh;
    }
}
@media (min-width:2560px)and (max-width:1500px){
    .dashboard-width {
        width: 100%;
    }
}
@media (max-width:991px){
    .dashboard-width {
        width: 1520px;
    }
}

.desktop-image-grid {
    display: block;
  }
  
  .mobile-image-grid {
    display: none;
  }
  
  /* CSS Media query for iPad and mobile sizes */
  @media (max-width: 500px) {
    .desktop-image-grid {
      display: none;
    }
  
    .mobile-image-grid {
        display: flex;
       
        width: 100%;
        
      
        /* padding: 20px;  */
        /* box-sizing: border-box; */
      
    }
  }
  
  @media (min-width: 500.10px) and (max-width:767.95px) {
    .desktop-image-grid {
      display: none;
    }
  
    .mobile-image-grid {
        display: flex;
        width: 100%;
      
        
      
    } 
 

  }

  



/*  CSS End */

@media (min-width: 320px) and (max-width: 374px) {

    .imagesize {
        width: 150px;
        height: 150px;
        margin-top: 0px;
        margin-left: 60px;
    }

    .nav_color {
        background-color: #131313;
        border: 0.5px solid #3A3A3A;
        justify-content: space-between;
        align-items: center;
        height: 225px;
        padding: 30px;
        /* width: 1520px; */
    }

    .navhamberger {
        margin-right: 120px;
        width: 155px;
        height: 95px;
    }

    .hamburger {
        width: 155px;
        height: 85px;
    }
    .startPlanningWrapper {
        height: 800px;
    }

    /* .startPlanningWrapper {
         width: 100%; Adjust to 100% width to use the available space 
        height: 800px;  Adjust height as needed, or use 'auto' 
        display: flex;
        flex-direction: column;  Stack children vertically 
        align-items: center;  Center align children horizontally 
        justify-content: center;  Center align children vertically 
    } */

    .startPlanningWrapper .iconWrapper,
    .startPlanningWrapper .logincard {
        width: 1200px;
        max-width: 1200px;
         /* margin-left: -125px; */
        border-radius: 45px;
        height: auto;
        font-size: 95px;
        /* margin-bottom: 600px; */

    }




    .containedWrapper {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /* max-width: 1200px; */
        margin: 0;
        padding: 0 15px;
        height: 190px;
    }



    .containedWrapper.d-flex .imagesize {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    .startPlanningWrapper {
        width: 100%;
        width: 500px;
        height: 900px;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        /* height: 480px; */
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }

    .startPlanningWrapper .iconWrapper .icons img {
        height: 20px;
        width: auto;
        margin-right: 15px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 3px black);

    }

    .background-login,
    .background-container {
        padding: 50px 0;
        background: none !important;
    }

    .iconWrapper {
        display: none !important;

    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .continue_button {
        background-color: #714BDC;
        width: 664px;
        height: 150px;
        flex-shrink: 0;
        margin-top: 44px;
        border-radius: 100px;
        font-size: 48px;
        font-weight: 700;
        font-family: Inter;
        color: #ffff;
        border: none;
        white-space: nowrap;
        margin-bottom: 35px;
    }

    .input-width {
        width: 964px;
        height: 190px;
        flex-shrink: 0;
        border-radius: 100px;
        border: 1px solid #3A3A3A;
        background: #131313;
        align-items: center;
        color: #D9D9D9;
        font-size: 55px;
        padding-left: 45px;
        margin-bottom: 55px;

    }

    .input-width::placeholder {
        color: #D9D9D9;
        font-family: Inter;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 45px;
    }

    .input-width[type="date"] {
        color: #D9D9D9;
        padding-left: 45px;
        font-size: 45px;
    }

    .welcometext {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 95px;
    }

    .createcontact {
        color: #FFF;
        text-align: center;
        font-size: 42px;
        padding: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: Inter;
    }

    .error_message {
        color: red;
        font-size: 36px;
    }

    .Logo_image {
        width: 90px;
        height: 90px;
    }

    .Logo {
        width: 90px;
        height: 90px;
    }

    .signup {
        background-color: #714BDC;
        width: 313px;
        Height: 115px;
        margin-top:18px;
        border-radius: 27px;
        font-size: 60px;
        font-weight: 700;
        font-family: Inter;
        padding: 12px 24px;
        color: #ffff;
        border-radius: 50px;
        border: none;
    }

    .login {
        width: 313px;
        Height: 115px;
        margin-top: 25px;
        border-radius: 27px;
        font-family: Inter;
        font-size: 60px;
        font-weight: 700;
        padding: 12px 24px;
        color: #ffff;
        background-color: #131313;
        border: none;
        border-radius: 50px;

    }

    .mobile-menu {
        /* bottom: 150px; */
        /* padding:1%; */
        /* background-color: #3A3A3A; */
        top:205px;
        left: 942px;
        /* left:0; */

    }

}

@media (min-width:568.25px) and (max-width:767.98px){
    .background-login,
    .background-container {
        padding: 50px 0;
        background: none !important;
        /* Removes background image */
    }

    .startPlanningWrapper {
        /* width: 100%; Adjust to 100% width to use the available space */
        height: 800px;
        /* Adjust height as needed, or use 'auto' */
        display: flex;
        flex-direction: column;
        /* Stack children vertically */
        align-items: center;
        /* Center align children horizontally */
        justify-content: center;
        /* Center align children vertically */
    }

    .startPlanningWrapper .iconWrapper,
    .startPlanningWrapper .logincard {
        width: 1500px;
        max-width: 1400px;
        margin-left: -15px;
        border-radius: 45px;
        height: auto;
        font-size: 95px;

    }

    .containedWrapper {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0 15px;
    }



    .containedWrapper.d-flex .imagesize {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    .startPlanningWrapper {
        width: 100%;
        width: 500px;
        height: 900px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }



    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }

    .startPlanningWrapper .iconWrapper .icons img {
        height: 20px;
        width: auto;
        margin-right: 15px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 3px black);
    }

    .background-login,
    .background-container {
        padding: 50px 0;
        background: none !important;
    }

    .iconWrapper {
        display: none !important;

    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .continue_button {
        background-color: #714BDC;
        width: 664px;
        height: 150px;
        flex-shrink: 0;
        margin-top: 44px;
        border-radius: 100px;
        font-size: 48px;
        font-weight: 700;
        font-family: Inter;
        color: #ffff;
        border: none;
        white-space: nowrap;
    }

    .input-width {
        width: 964px;
        height: 190px;
        flex-shrink: 0;
        border-radius: 100px;
        border: 1px solid #3A3A3A;
        background: #131313;
        align-items: center;
        color: #D9D9D9;
        font-size: 45px;
        padding-left: 45px;
        margin-bottom: 55px;

    }

    .input-width::placeholder {
        color: #D9D9D9;
        font-family: Inter;
        font-size: 45px;
        font-style: normal; 
        font-weight: 400;
        line-height: normal;
        padding-left: 45px;
    }

    .input-width[type="date"] {
        color: #D9D9D9;
        padding-left: 45px;
        font-size: 45px;
    }

    .createcontact {
        color: #FFF;
        text-align: center;
        font-size: 42px;
        padding: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: Inter;
    }

    .Logo_image {
        width: 90px;
        height: 90px;
    }

    .Logo {
        margin-top: 45px;
        width: 200px;
        height: 200px;
    }
    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .create_font{
        font-weight:900;
        font-size: 42px;
    }

    


}


@media (min-width: 375px) and (max-width: 424px) {

    .imagesize {
        width: 150px;
        height: 150px;
        margin-top: 0px;
        margin-left: 60px;
    }

    .signup {
        background-color: #714BDC;
        width: 313px;
        Height: 115px;
        margin-top:18px;
        border-radius: 27px;
        font-size: 60px;
        font-weight: 700;
        font-family: Inter;
        padding: 12px 24px;
        color: #ffff;
        border-radius: 50px;
        border: none;
    }

    .login {
        width: 313px;
        Height: 115px;
        margin-top: 5px;
        border-radius: 27px;
        font-family: Inter;
        font-size: 60px;
        font-weight: 700;
        padding: 12px 24px;
        color: #ffff;
        background-color: #131313;
        border: none;
        border-radius: 50px;

    }

    .mobile-menu {
        /* bottom: 180px; */
        /* padding:1%; */
        /* background-color: #3A3A3A; */
        top:205px;
        left: 1152px;
        /* left:0; */

    }

    .welcometext {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 95px;
    }

    .nav_color {
        background-color: #131313;
        border: 0.5px solid #3A3A3A;
        justify-content: space-between;
        align-items: center;
        height: 195px;
        padding: 30px;
        width: 1520px;
    }

    .navhamberger {
        margin-right: 105px;
        width: 155px;
        height: 95px;
    }

    .hamburger {
        width: 155px;
        height: 85px;
    }

    .startPlanningWrapper {
        /* width: 100%; Adjust to 100% width to use the available space */
        height: 800px;
        /* Adjust height as needed, or use 'auto' */
        display: flex;
        flex-direction: column;
        /* Stack children vertically */
        align-items: center;
        /* Center align children horizontally */
        justify-content: center;
        /* Center align children vertically */
    }

    .startPlanningWrapper .iconWrapper,
    .startPlanningWrapper .logincard {
        width: 1500px;
        max-width: 1400px;
        margin-left: -15px;
        border-radius: 45px;
        height: auto;
        font-size: 95px;

    }




    .containedWrapper {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /* max-width: 1200px; */
        margin: 0;
        padding: 0 15px;
    }



    .containedWrapper.d-flex .imagesize {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    .startPlanningWrapper {
        width: 100%;
        width: 500px;
        height: 900px;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: space-around;
    }



    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        /* height: 480px; */
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }

    .startPlanningWrapper .iconWrapper .icons img {
        height: 20px;
        width: auto;
        margin-right: 15px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 3px black);
    }

    .background-login,
    .background-container {
        padding: 50px 0;
        background: none !important;
        /* Removes background image */
    }

    .iconWrapper {
        display: none !important;

    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .continue_button {
        background-color: #714BDC;
        width: 664px;
        height: 150px;
        flex-shrink: 0;
        margin-top: 44px;
        border-radius: 100px;
        font-size: 48px;
        font-weight: 700;
        font-family: Inter;
        color: #ffff;
        border: none;
        white-space: nowrap;
    }

    .input-width {
        width: 964px;
        height: 190px;
        flex-shrink: 0;
        border-radius: 100px;
        border: 1px solid #3A3A3A;
        background: #131313;
        align-items: center;
        color: #D9D9D9;
        font-size: 45px;
        padding-left: 45px;
        margin-bottom: 55px;

    }

    .input-width::placeholder {
        color: #D9D9D9;
        font-family: Inter;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 45px;
    }

    .input-width[type="date"] {
        color: #D9D9D9;
        padding-left: 45px;
        font-size: 45px;
    }

    .welcometext {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .createcontact {
        color: #FFF;
        text-align: center;
        font-size: 42px;
        padding: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: Inter;
    }

    .Logo_image {
        width: 90px;
        height: 90px;
    }

    .error_message {
        color: red;
        font-size: 36px;
    }

    .Logo {
        margin-top: 45px;
        width: 200px;
        height: 200px;
    }

}

@media (min-width: 425px) and (max-width: 575px) {

    .welcometext {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 95px;
        /* margin-bottom: 95px; */
    }

    .imagesize {
        width: 150px;
        height: 150px;
        margin-top: 0px;
        margin-left: 60px;
    }

    /* .dashboard-width {
        width: 1520px;     
    } */

    .nav_color {
        background-color: #131313;
        border: 0.5px solid #3A3A3A;
        justify-content: space-between;
        align-items: center;
        height: 225px;
        padding: 30px;
        width: 1520px;
    }

    .signup {
        background-color: #714BDC;
        width: 313px;
        Height: 115px;
        margin-top:18px;
        border-radius: 27px;
        font-size: 60px;
        font-weight: 700;
        font-family: Inter;
        padding: 12px 24px;
        color: #ffff;
        border-radius: 50px;
        border: none;
    }

    .login {
        width: 313px;
        Height: 115px;
        margin-top: 18px;
        border-radius: 27px;
        font-family: Inter;
        font-size: 60px;
        font-weight: 700;
        padding: 12px 24px;
        color: #ffff;
        /* background-color: #131313; */
        border: none;
        border-radius: 50px;

    }

    .mobile-menu {
        /* bottom: 160px; */
        /* padding:1%; */
        /* background-color: #3A3A3A; */
        top:205px;
        left: 1172px;
        /* left:0; */

    }

    .navhamberger {
        margin-right: 45px;
        width: 155px;
        height: 95px;
    }

    .hamburger {
        width: 155px;
        height: 85px;
    }

    .startPlanningWrapper {
        /* width: 100%; Adjust to 100% width to use the available space */
        height: 800px;
        /* Adjust height as needed, or use 'auto' */
        display: flex;
        flex-direction: column;
        /* Stack children vertically */
        align-items: center;
        /* Center align children horizontally */
        justify-content: center;
        /* Center align children vertically */
    }

    .startPlanningWrapper .iconWrapper,
    .startPlanningWrapper .logincard {
        width: 1500px;
        max-width: 1400px;
        margin-left: -15px;
        border-radius: 45px;
        height: auto;
        font-size: 95px;
        padding-bottom: 50px;
    }

    .error_message {
        color: red;
        font-size: 36px;
    }



    .containedWrapper {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0 15px;
    }



    .containedWrapper.d-flex .imagesize {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    .startPlanningWrapper {
        width: 100%;
        width: 500px;
        height: 900px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }



    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }

    .startPlanningWrapper .iconWrapper .icons img {
        height: 20px;
        width: auto;
        margin-right: 15px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 3px black);
    }

    .background-login,
    .background-container {
        padding: 50px 0;
        background: none !important;
    }

    .iconWrapper {
        display: none !important;

    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .continue_button {
        background-color: #714BDC;
        width: 664px;
        height: 150px;
        flex-shrink: 0;
        margin-top: 44px;
        border-radius: 100px;
        font-size: 48px;
        font-weight: 700;
        font-family: Inter;
        color: #ffff;
        border: none;
        white-space: nowrap;
    }

    .input-width {
        width: 964px;
        height: 190px;
        flex-shrink: 0;
        border-radius: 100px;
        border: 1px solid #3A3A3A;
        background: #131313;
        align-items: center;
        color: #D9D9D9;
        font-size: 45px;
        padding-left: 45px;
        margin-bottom: 55px;

    }

    .input-width::placeholder {
        color: #D9D9D9;
        font-family: Inter;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 45px;
    }

    .input-width[type="date"] {
        color: #D9D9D9;
        padding-left: 45px;
        font-size: 45px;
    }

    .welcometext {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .createcontact {
        color: #FFF;
        text-align: center;
        font-size: 42px;
        padding: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: Inter;
    }

    .Logo_image {
        width: 90px;
        height: 90px;
    }

    .Logo {
        margin-top: 45px;
        width: 200px;
        height: 200px;
    }

}


@media (min-width: 425px) and (max-width: 767px) {

    .startPlanningWrapper {
        width: 100%;
        width: 500px;
        height: 800px;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
   
    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        /* height: 480px; */
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }

    .startPlanningWrapper .iconWrapper .icons img {
        height: 20px;
        width: auto;
        margin-right: 15px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 3px black);
    }

    .logincard {
        width: '1400px'      
    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
        margin-bottom: 15px;
        padding-top: 28px;
    }
}
@media (min-width: 574.98px) and (max-width: 767.98px) {
    .imagesize {
        width: 135px !important;
        height: 135px !important;
    }

    .nav_color {
        background-color: #131313;
        border: 0.5px solid #3A3A3A;
        justify-content: space-between;
        align-items: center;
        height: 190px;
        padding: 30px;
        width: 1520px;
    }

    .hamburger {
        width: 105px;
        height: 45px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

   

    .imagesize {
        width: 135px;
        height: 135px;
        margin-top: 5px;
        margin-left: 60px;
    }


    .create_font{
        font-weight:900;      
        font-size: 20px;
    }

    
        .nav_color {
            background-color: #131313;
            border: 0.5px solid #3A3A3A;
            justify-content: space-between;
            align-items: center;
            height: 190px;
            padding: 30px;
            width: 1520px;
        }
    
    .startPlanningWrapper .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .startPlanningWrapper .iconWrapper h4 {
        color: #fff;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .acknowledge {
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        /* font-weight: 400; */
        line-height: normal;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .hamburger {
        width: 105px;
        height: 45px;
    }

    .startPlanningWrapper .iconWrapper .icons {
        width: 85%;
        height: 480px;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        filter: brightness(1.75);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
    }


    .logincard {       
        width: 1400px;       
    }

    .background-login {
        padding: 80px 0;
    }

    .background-container {
        height: 80vh;
    }

    .startPlanningWrapper {
        align-items: center;
        justify-content: space-evenly;
    }

   

}




@media only screen and (min-width: 1200px) {
    .background-login {
        padding: 80px 0;
    }

    .background-container {
        height: 80vh;
    }

    .startPlanningWrapper {
        align-items: center;
        justify-content: space-evenly;
    }
}



@media (max-width:425px){  
    .startPlanningWrapper {
        width: 100% !important;
        height: auto !important;
    }
}



