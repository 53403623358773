.notpadbutton{
    border: none;
    background-color: #272727;
    margin-bottom: 10px;
   
    
}
.notpadbutton img{
   height: 11px;
   width: 10px;
}
.notpadbutton:hover{
    background: #1a1a1a;
}

.notpadbutton.active {
    background-color: #0d0d0d; /* Darker background for active state */
}
.Note_edit_toolboar{
    width: 28px;
    height: 25px;
    border-radius: 3px;
    border: 1px solid #B2B2B2;
}
.minusbutton{
    width: 28px;
flex-shrink: 0;
color: #B2B2B2;
text-align: center;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: normal;
background-color:#272727 ;
border:none;
}
.minusbutton:hover{
    background-color: #0d0d0d;
}

.newNotePopupToolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #272727; 
    padding: 10px;
}

.newNotePopupToolbar button,
.newNotePopupToolbar input {
    flex: 1 1 auto; 
    margin: 5px; 
    text-align: center; 
   
}

/*  CSS */

@media (max-width:1200px){
    .minusbutton {
        font-size: 31px;
    }
    .notpadbutton img {
        height: 16px;
        width: 16px;
    }
    .newNotePopupToolbar{
        line-height: 175px;
    }
    .notelocation{
        font-size: 17px;
    }
}

@media (max-width:991px){
    .notelocation {
        font-size: 20px;
    }
}
@media (max-width:500px){
    .notelocation {
        font-size: 32px;
    }
    .minusbutton {
        font-size: 68px;
    }
    .notpadbutton img {
        width: 50px;
        height: 50px;
        /* padding: 5px; */
        margin-left: 25px 0px 10px 0px;
        
    }
    .newNotePopupToolbar button {
        margin-right: 35px;
    }
    .minusbutton buttomn{
        
    }
    .Note_edit_toolboar{
        width: 22px;
        height: 75px;
        font-size: 51px;
        margin-top: 59px !important;

      
    }
}