.post-margin-container {
  padding-Right: 5px;
  border-Right: 1px solid #3A3A3A;
  margin-left: 0px;
  column-width: 900px;
}

.public-board-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.public-board-container {
  justify-content: center;
  width: 750px;
  height: 550px;
  margin-bottom: 30px;
  /* margin-left: 50px; */
  margin-bottom: 20px;
  z-index: 1;
}


.public-board {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}

.bodymarginPubliccontainer {
  padding-Right: 0px;
  border-Right: 1px solid #3A3A3A;
  margin-left: 0px;
  column-width: 800px;

}

.public-board-image-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  /* cursor: pointer; */
}

.public_board_image {
  width: 100%;

  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.public-board-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}


.public-board-bottom-right,
.public-board-bottom-left {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}


.public-board-top-left {
  display: flex;
  align-items: center;
  top: 20px;
  left: 20px;
}

.public-board-bottom-right {
  bottom: 20px;
  right: 20px;

}

.public-board-bottom-left {
  bottom: 20px;
  left: 20px;
}

.public-board-profile-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.public-board-profile-name {
  color: #ffffff;
  font-size: 14px;
  font-family: Inter;
  padding: 3px 9px;
  font-weight: 600;

}

.public-board-location-info {

  color: #ffffff;
  font-size: 16px;
  background-color: #00000068;
  padding: 3px 6px;
  border-radius: 15px;
  display: inline;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
}

.public-board-like-btn,
.public-board-bucket-list-btn {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.public-board-like-btn img,
.public-board-bucket-list-btn img {
  /* width: 24px; */
  height: 24px;
  cursor: pointer;
}

.public-board-like-btn span,
.public-board-bucket-list-btn span {
  margin-left: 5px;
  color: #ffffff;
}


.public-board-profile-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
}

.public-board-profile-placeholder {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
}

.see-more-wrapper {
  display: flex;
  justify-content: space-between;
}

.boards-wrapper {
  flex: 3;
  margin-right: 90px;
}

.popular-and-trending-wrapper {
  flex: 1;
}

@media (max-width: 768px) {
  .see-more-wrapper {
    flex-direction: column;
  }

  .boards-wrapper,
  .popular-and-trending-wrapper {
    margin-right: 0;
    width: 100%;
  }
}


.popup-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.popup-content2 {
  position: relative;
  padding: 40px;
  background-color: rgb(125, 124, 124);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 200px;
  /* Adjust based on your content needs */
  max-width: 90%;
  /* Prevents the popup from being too wide on large screens */
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #fff
}

.close-btn2 {
  position: absolute;
  top: 5%;
  right: 2%;
  background: none;
  border: none;
  border-radius: 100%;
  background-color: #b3b0b0;
  padding: 2px 9px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  /* Color can be adjusted to fit the theme */
}

.popup-content2 button:hover {
  color: #666;
  /* Slight color change on hover for visual feedback */
}

.rgt-poplar-tread-card-img img{
  width: 100%;
}

/*  CSS */
@media (max-width:1200px) {

  .bodymarginPubliccontainer h2 {
    padding-bottom: 20px;
  }

  .public-board-profile-name {
    font-size: 26px;
  }

  .public-board-profile-logo {
    width: 45px;
    height: 45px;
  }

  .rightcard .centercard h4 {
    padding-bottom: 15px;
  }

  .post-margin-container h2 {
    padding-bottom: 12px;
  }
  .rightcard {
    width: 100%;
  }
  .VikingCruise-img img{
    width: 100%;
  }
  
}


@media (max-width:991px) {
  .popup-content2 {
    padding: 100px 75px;
  }

  .tab-disp-none {
    display: none;
  }

  .public-board-container {
    width: 100%;
    height: 600px;
  }

  .bodymarginPubliccontainer h2 {
    font-size: 34px;
  }

  .public-board-profile-name {
    font-size: 29px;
  }

  .public-board-like-btn img,
  .public-board-bucket-list-btn img {
    /* width: 24px; */
    height: 33px;
    cursor: pointer;
  }
}



@media (max-width:500px){
  .post-margin-container h2 {
    padding-bottom: 12px;
    font-size: 65px;
  }

  .post-margin-container img {
    /* width: ; */
    padding-bottom: 12px;
  }
  .popup-content2 {
    font-size: 62px;
  }
  
  .public-board-profile-name {
    font-size: 50px;
}
.public-board-profile-logo {
  width: 99px;
  height: 99px;
}
.bodymarginPubliccontainer h2 {
  font-size: 55px;
}
.public-board-like-btn img,.public-board-bucket-list-btn img{
  width: 56px;
  height: 56px;
}
.seeMore-back-btn-KD{
  width: 36px;
}
}


@media (max-width: 375px) {
  .public-board-profile-name {
      font-size: 52px;
  }
}