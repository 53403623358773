.board-popup-menu{
margin-top: 355px;
margin-left: 200px;
}

.board-popup-close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #FFF;
    /* Additional styles for the X icon */
}

.board-popup-option{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 10px;
    /* padding: 15px; */

}


 
.board-popup-option:hover {
    border-radius: 4px;
    background: #272727;
    width: 151px;
 
}
 
.board-popup-option img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}
 
.board-popup-option span {
    color: var(--icon-color, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0.6rem;
    margin-left: 15px;
}

